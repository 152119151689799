import httpClient, { httpClientV2 } from '@/services/httpClient';
import { unwrapV2 } from '@/services/api/v2/utils';

export const queryTablesV2 = (data) =>
  unwrapV2((axios) => axios.post(`/tables/query`, data));

export const createTableV2 = ({ name, project }) =>
  unwrapV2((axios) =>
    axios.post(`/tables`, { name, project: project.id ?? project })
  );

export const deleteTableV2 = (table) =>
  unwrapV2((axios) => axios.delete(`/tables/${table.id ?? table}`));

export const getTablesV2 = (project) =>
  unwrapV2((axios) => axios.get(`/projects/${project.id ?? project}/tables`));

export const getTableV2 = (table) =>
  unwrapV2((axios) => axios.get(`/tables/${table.id ?? table}`));

export const getTableIdV2 = (project, name) =>
  getTablesV2(project).then((tables) => tables.find((t) => t.name === name))
    ?.id;

export const ensureTableV2 = async (project, name) => {
  return (
    (await getTablesV2(project)).find((t) => t.name === name) ??
    (await createTableV2({ project, name }))
  );
};

export const ensureVacuumedTableV2 = async (project, name) => {
  let table = (await getTablesV2(project)).find((t) => t.name === name);
  if (table) await deleteTableV2(table);
  return await createTableV2({ project, name });
};
