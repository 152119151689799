import httpClient from '../httpClient';

const getPermissions = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/permissions`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getPermission = (permissionId, projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/permission/${permissionId}`, {
        params: {
          permissionId: permissionId,
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updatePermission = (permissionId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/permission/${permissionId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deletePermission = (permissionId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/permission/${permissionId}`, { data: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createPermission = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/permission`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getPermissions,
  getPermission,
  updatePermission,
  deletePermission,
  createPermission,
};
