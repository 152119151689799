import { unwrapV2 } from '@/services/api/v2/utils';

export const setPermissionV2 = (data) =>
  unwrapV2((axios) => axios.post(`/permissions`, data));

export const deletePermissionV2 = (permission) =>
  unwrapV2((axios) =>
    axios.delete(`/permissions/${permission.id ?? permission}`)
  );

export const getPermissionV2 = (table) =>
  unwrapV2((axios) => axios.get(`/tables/${table.id ?? table}/permissions`));
