import forge from './forge.store';
import AutodeskService from '../../services/forge/autodesk';
import { getModuleData } from '@/services/api/module.api';
import { flattenModel } from '@/components/Modules/Model-Manager/utils/ModelManager+utils';

const state = {
  moduleStatus: '',

  // forge companiesStatus
  forgeToken: undefined,
  forgeCredentials: undefined,

  //Credentials
  moduleData: undefined,

  // forge selections
  forgeSelectedStructureObject: undefined,
  forgeSelectedModelObject: undefined,

  // forge metadata
  forgeUrnMetadata: [],
  forgeGuidMetadata: [],
  forgeGuidMetadataStatus: '',
  forgeGuidProperties: [],

  //forge Bucket
  forgeBucketFiles: [],
  forgeBuckets: [],
  forgeBucketStatus: '',

  //Models
  forgeModels: [],

  forgeUploadStatus: '',
  model: [],
  modelProperties: [],
};

const mutations = {
  module_modelManager_request(state) {
    state.moduleStatus = 'loading';
  },
  module_ModelManager_success(state, data) {
    state.moduleData = data;
    state.moduleData.CFFA_FORGE_MODELS.records.forEach(
      (record) => (record.enabled = false)
    );
    state.moduleStatus = 'success';
  },
  set_forge_token(state, token) {
    state.forgeToken = token;
  },
  request_forge_guid_metadata(state) {
    state.forgeGuidMetadataStatus = 'loading';
  },
  set_forge_guid_metadata(state, metadata) {
    state.forgeGuidMetadata = metadata;
    state.forgeGuidMetadataStatus = 'success';
  },
  set_forge_guid_properties(state, properties) {
    state.forgeGuidProperties = properties;
  },

  forge_bucket_data_request(state) {
    state.forgeBucketStatus = 'loading';
  },

  forge_bucket_data_success(state, files) {
    state.forgeBucketFiles = files;
    state.forgeBucketStatus = 'success';
  },

  forge_bucket_data_error(state) {
    state.forgeBucketStatus = 'error';
  },

  forge_upload_request(state) {
    state.forgeBucketStatus = 'loading';
  },

  forge_upload_success(state, file) {
    state.forgeBucketStatus = 'success';
    state.forgeBucketFiles.push(file);
  },

  forge_bucket_model_delete_success(state, modelKey) {
    state.forgeBucketFiles = [
      ...state.forgeBucketFiles.filter((file) => file.objectKey !== modelKey),
    ];
    state.forgeBucketStatus = 'success';
  },

  forge_convert_request(state) {
    state.forgeBucketStatus = 'loading';
  },

  forge_convert_success(state) {
    state.forgeBucketStatus = 'success';
  },

  forge_bucket_create(state, bucket) {
    state.forgeBuckets.push(bucket);
  },
  setModel(state, model) {
    state.model = model;
  },
  setModelProperties(state, model) {
    state.modelProperties = model;
  },
  saveCredentials(state, credentials) {
    state.forgeCredentials = credentials;
  },
  ant_record_model_create(state, record) {
    state.moduleData.CFFA_FORGE_MODELS.records.push(record);
  },
  ant_record_model_delete(state, deletedRecordId) {
    state.moduleData.CFFA_FORGE_MODELS.records = [
      ...state.moduleData.CFFA_FORGE_MODELS.records.filter(
        (record) => record.id !== deletedRecordId
      ),
    ];
  },
};

const actions = {
  loadModelManagerModuleData({ commit }, { projectId, moduleId, sessionId }) {
    return new Promise((resolve, reject) => {
      commit('module_modelManager_request');
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_ModelManager_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  setForgeToken({ commit }, { token }) {
    commit('set_forge_token', token);
  },
  fetchForgeGuidProperties({ commit, state }, { urn, guid }) {
    return AutodeskService.getGuidProperties(urn, guid, state.forgeToken)
      .then((metadata) => {
        commit('set_forge_guid_properties', metadata);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchBucketFiles({ commit, state }, bucket) {
    commit('forge_bucket_data_request');
    return AutodeskService.getFilesInBucket(bucket, state.forgeToken)
      .then((files) => {
        commit('forge_bucket_data_success', files);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
        commit('forge_bucket_data_error');
      });
  },
  createBucket({ commit, state }, bucket) {
    return AutodeskService.createBucket(
      state.forgeToken,
      bucket,
      state.forgeCredentials.server_region
    )
      .then((bucket) => {
        commit('forge_bucket_create', bucket);
        return Promise.resolve(bucket);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
        return Promise.reject(error)
      });
  },
  uploadForgeFile({ commit, state }, { selectedBucket, file }) {
    commit('forge_upload_request');
    return AutodeskService.uploadForgeFile(selectedBucket, file, state.forgeToken)
      .then((response) => {
        commit('forge_upload_success', response);
        this.commit('showNotification', {
          content: 'Model: ' + file.name + ' succesfully uploaded',
          color: 'success',
        });
        return response;
      })
      .catch((error) => {
        this.commit('showNotification', { content: error, color: 'error' });
      });
  },
  convertModel({ commit, state, dispatch }, urn) {
    commit('forge_convert_request');
    return AutodeskService.convertModel(
      urn,
      state.forgeToken,
      state.moduleData.CFFA_FORGE_CLIENT.records[0].server_region
    )
      .then(async (response) => {
        if (response.result !== 'created') {
          await sleep(5000);
          dispatch('convertModel', urn);
        } else {
          commit('forge_convert_success');
          this.commit('showNotification', {
            content: 'Model parsed',
            color: 'success',
          });
        }
      })
      .catch((error) =>
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        })
      );
  },
  removeModelFromBucket({ commit }, { bucket, model }) {
    commit('forge_convert_request');
    return AutodeskService.deleteObjectFromBucket(
      bucket,
      model,
      state.forgeToken
    ).then(() => {
      commit('forge_bucket_model_delete_success', model);
    });
  },
  async importModel({ commit, getters, state }, { urn, model: m }) {
    const model = await AutodeskService.getModelProperties(
      urn,
      state.forgeToken,
      state.moduleData.CFFA_FORGE_CLIENT.records[0].server_region
    );
    const modelProperties = flattenModel(model.map((e) => e.properties));

    commit('setModel', model);
    commit('setModelProperties', modelProperties);
  },
  saveCredentials({ commit, state }, credentials) {
    commit('saveCredentials', credentials);
  },
};

const getters = {
  modelManagerData: (state) => state.moduleData,
  modelManagerStatus: (state) => state.moduleStatus,
  modelManagerForgeClient: (state) =>
    state.moduleData.CFFA_FORGE_CLIENT.records[0],
  modelManagerForgeClientTable: (state) =>
      state.moduleData.CFFA_FORGE_CLIENT.id,
  modelManagerForgeModelsTable: (state) =>
    state.moduleData.CFFA_FORGE_MODELS.id,
  modelManagerForgeModels: (state) =>
    state.moduleData.CFFA_FORGE_MODELS.records,
  modelManagerClient: (state) =>
      state.moduleData.CFFA_FORGE_CLIENT.records[0],
  forgeToken: (state) => state.forgeToken,
  forgeBucketFiles: (state) => state.forgeBucketFiles,
  forgeBucketStatus: (state) => state.forgeBucketStatus,
  modelManagerModels: (state) => state.model,
  modelProperties: (state) => state.modelProperties,
  forgeCredentials: (state) => state.forgeCredentials,
  forgeBucket: (state) => {
    return state.moduleData.CFFA_FORGE_CLIENT.records[0].bucket_key;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
