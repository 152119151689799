import httpClient from '../httpClient';

const getNews = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/whats-new`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getNewsItem = (itemId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/whats-new/${itemId}`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export { getNews, getNewsItem };
