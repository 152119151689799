import httpClient from '../httpClient';

const getLabels = (filters) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/labels`, {
        params: filters,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createLabel = (label) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/labels`, label)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateLabel = (labelId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/labels/${labelId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteLabel = (labelId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/labels/${labelId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export { getLabels, createLabel, updateLabel, deleteLabel };
