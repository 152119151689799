export const Reducers = {
  awaitAll: () => [
    (result, promise) =>
      result.then((array) => promise.then((value) => [...array, value])),
    Promise.resolve([]),
  ],
  awaitAllSettled: () => [
    (result, promise) =>
      result.then((array) =>
        promise.then(
          (value) => [...array, { status: 'fulfilled', value }],
          (reason) => [...array, { status: 'rejected', reason }]
        )
      ),
    Promise.resolve([]),
  ],
  filterInPlace: (predicate) => [
    (result, element, index, array) =>
      (predicate(element) && array.splice(index, 1).length) || result, // removes the concept in place
    false,
  ],
  take: (count) => [
    (result, element, index) => {
      index < count && result.push(element);
      return result;
    },
    [],
  ],
  max: () => [(a, b) => Math.max(a, b), 0],
  sum: () => [(a, b) => a + b, 0],
};
