import httpClient from '../httpClient';
import LocalStorageService from '@/services/local-storage';
import appConfig from '@/appConfig';

const login = (user) => {
  return new Promise((resolve, reject) => {
    LocalStorageService.removeToken();

    const data = {
      grant_type: 'password',
      client_id: appConfig.VUE_APP_CLIENT_ID,
      client_secret: appConfig.VUE_APP_SECRET,
      ...user,
    };

    httpClient({
      method: 'post',
      url: '/oauth/token',
      baseURL: appConfig.VUE_APP_BASE_URL,
      data: data,
    })
      .then((response) => {
        let data = response.data;
        let accessToken = data.access_token || data.token || null;

        if (accessToken) {
          LocalStorageService.setToken(accessToken);
          LocalStorageService.setTokenExpiresIn(
            Date.now() + data.expires_in * 1000
          );
          resolve(data);
        } else {
          reject();
        }
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const changePassword = ({ email, currentpassword, newpassword }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/user/password/update`, {
        email: email,
        currentpassword: currentpassword,
        newpassword: newpassword,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getUser = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get('/user')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response);
      });
  });
};

const fetchUserAccessToken = (user) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/user/${user}/access`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const fetchAzureLoginUrl = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get('/login/azure/url')
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const authenticateAzureUser = (code) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/login/azure/auth?code=${code}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const generateTwoFactor = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/2fa/generate`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const enableTwoFactor = (twoFactorCode) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/2fa/enable`, {
        code: twoFactorCode,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const disableTwoFactor = (twoFactorCode) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/2fa/disable`, {
        code: twoFactorCode,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const verifyTwoFactor = (twoFactorCode) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/2fa/verify`, {
        code: twoFactorCode,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const inviteUser = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/user/invite`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

const userInviteAccept = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/user/invite/accept`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });
};

export {
  login,
  changePassword,
  getUser,
  fetchAzureLoginUrl,
  authenticateAzureUser,
  generateTwoFactor,
  enableTwoFactor,
  disableTwoFactor,
  verifyTwoFactor,
  fetchUserAccessToken,
  inviteUser,
  userInviteAccept,
};
