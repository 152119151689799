<template>
  <div class="project-card" @click="navigateToProject()">
    <div v-if="hasImage" class="pos-abs full-width full-height">
      <template v-if="!image">
        <v-skeleton-loader type="image" />
      </template>
      <div
        v-else
        class="project-card-image"
        :style="{
          'background-image': image
            ? `url(data:${image.mimeType};base64,${image.file})`
            : '',
        }"
      />
    </div>
    <div
      class="theme-overlay d-flex align-end justify-start"
      :class="{ 'no-image': !image }"
      :style="{ background: getBackgroundThemeGradient() }"
    >
      <ant-favorite-action
        v-if="favorite"
        class="favorite-action py-1"
        :selected="project.is_favorite"
        :loading="favoriteRequest"
        @on-change="onFavoriteChange"
      />
      <div class="project-card-title">
        {{ project.name }}
      </div>
    </div>
    <v-progress-linear
      :active="isLoading"
      :indeterminate="isLoading"
      absolute
      bottom
      color="primary lighten-4"
    />
  </div>
</template>

<script>
import AntFavoriteAction from '@/components/AntFavoriteAction.vue';
import ColorHelper from '@/services/color-helper';

export default {
  name: 'ProjectCard',
  components: { AntFavoriteAction },
  props: {
    project: {
      type: Object,
      required: true,
    },
    favorite: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    image() {
      if (this.project.image && typeof this.project.image === 'object') {
        return {
          mimeType: this.project.image.mimeType,
          file: this.project.image.file,
        };
      }
      return null;
    },
    hasImage() {
      return this.project?.image;
    },
    isLoading() {
      return this.loading || this.favoriteRequest;
    },
    favoriteRequest() {
      return (
        this.$wait.is(`project.favorite.add.${this.project.id}`) ||
        this.$wait.is(`project.favorite.delete.${this.project.id}`)
      );
    },
  },
  watch: {
    project: {
      handler(project) {
        if (typeof project.image === 'string') {
          this.$store.dispatch('loadProjectImage', this.project);
        }
      },
      immediate: true,
    },
  },
  methods: {
    navigateToProject() {
      this.$store.dispatch('setProject', this.project);
      this.$router.push({
        name: 'Project',
        params: { slug: this.project.slug },
      });
    },
    getBackgroundThemeGradient() {
      let theme = this.$vuetify.theme.themes.light.primary;

      let rgb = ColorHelper.hexToRgb(theme);
      let themeRGB100 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.8)`;
      let themeRGB50 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.4)`;
      let themeRGB0 = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0)`;
      return `linear-gradient(
              23deg,
              ${themeRGB100} 10%,
              ${themeRGB50} 30%,
              ${themeRGB0} 45%)`;
    },
    onFavoriteChange(favoriteState) {
      if (!favoriteState) {
        this.$store
          .dispatch('addProjectToFavorites', this.project.id)
          .then(() => {
            this.$emit('favorite-change');
          });
        return;
      }
      this.$store
        .dispatch('deleteProjectFromFavorites', this.project.id)
        .then(() => {
          this.$emit('favorite-change');
        });
    },
  },
};
</script>

<style scoped lang="scss">
/* Tablet Styles */
@media (max-width: 1200px) {
  .project-card {
    .theme-overlay {
      .project-card-title {
        font-size: 1rem !important;
      }
    }
  }
}

.project-card {
  background-color: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(5px);
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  transition: 200ms ease-out;
  cursor: pointer;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;

  &:hover {
    box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }

  .theme-overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--v-primary-base);
    width: 100%;
    height: 100%;
    padding: 10px;

    .favorite-action {
      position: absolute;
      top: 2px;
      right: 2px;
    }

    .project-card-title {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.25rem;
      font-weight: 500;
      letter-spacing: 0.0125em;
      line-height: 2rem;
      word-break: break-word;
      color: white;
    }
  }

  .no-image {
    background: linear-gradient(
      45deg,
      var(--v-primary-base) 10%,
      var(--v-primary-lighten3) 60%,
      var(--v-primary-lighten5) 100%
    ) !important;
  }

  .project-card-image {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
  }
}
</style>
