import { createTask, getTasks } from '@/services/api/task.api';
import TaskHelper from '@/services/task-helper';

export default {
  state: {
    dhmeProjectPlanningTasksStatus: '',
    dhmeProjectPlanningTasks: [],
  },
  mutations: {
    project_planning_tasks_request(state) {
      state.dhmeProjectPlanningTasksStatus = 'loading';
    },
    project_planning_tasks_success(state, tasks) {
      state.dhmeProjectPlanningTasks = TaskHelper.mapDefaultTreeItems(tasks);
      state.dhmeProjectPlanningTasksStatus = 'success';
    },
    project_planning_task_create_success(state, task) {
      state.dhmeProjectPlanningTasks.push(task);
    },
  },
  actions: {
    fetchProjectPlanningTasks({ commit, getters }) {
      commit('project_planning_tasks_request');
      getTasks(
        {
          'filter[license]': getters.selectedLicense.id,
        },
        {
          advanced_filters: [
            {
              column: 'project',
              operator: '=',
              values: [getters.project.id],
            },
            {
              column: 'type',
              operator: '=',
              values: [
                'dhme-wvb-phase',
                'dhme-production-phase',
                'dhme-assembly-phase',
                'dhme-placement-phase',
              ],
            },
          ],
        }
      ).then((tasks) => {
        commit('project_planning_tasks_success', tasks);
      });
    },
    createProjectPlanningTask({ commit }, { body }) {
      return new Promise((resolve, reject) => {
        return createTask(body)
          .then((response) => {
            commit('project_planning_task_create_success', response);
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    },
  },
  getters: {
    dhmeProjectPlanningTasks: (state) => state.dhmeProjectPlanningTasks,
    dhmeProjectPlanningTasksStatus: (state) =>
      state.dhmeProjectPlanningTasksStatus,
  },
};
