import { getModuleData } from '@/services/api/module.api';

const state = {
  moduleData: {
    CFFA_DHME_ASSEMBLY_HALLS: {
      records: [],
      id: undefined,
    },
  },
  moduleStatus: '',
};
const mutations = {
  module_trestle_checker_request(state) {
    state.moduleStatus = 'loading';
  },
  module_trestle_checker_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
};
const actions = {
  fetchDhmeTrestleCheckerData({ commit, getters }, { moduleId, sessionId }) {
    return new Promise((resolve, reject) => {
      commit('module_trestle_checker_request');
      getModuleData(getters.project.id, moduleId, sessionId)
        .then((data) => {
          commit('module_trestle_checker_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
};
const getters = {
  dhmeTrestleCheckerData: (state) => state.moduleData,
  dhmeTrestleCheckerStatus: (state) => state.moduleStatus,
  dhmeTrestleCheckerHalls: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_HALLS.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
