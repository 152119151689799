import axios from 'axios';
import appConfig from '@/appConfig';

export const ACC_ROUTE = 'acc-route';
export const ACC_TOKEN = 'acc-access-forgeAccessToken';
export const ACC_REFRESH_TOKEN = 'acc-refresh-forgeAccessToken';

const fetchAccToken = (code) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams();
    params.append('grant_type', 'authorization_code');
    params.append('code', code);
    params.append('redirect_uri', appConfig.VUE_APP_ACC_CLIENT_CALLBACK_URL);

    const clientIdAndSecret = `${appConfig.VUE_APP_ACC_CLIENT_ID}:${appConfig.VUE_APP_ACC_CLIENT_SECRET}`;
    const encodedClientIdAndSecret =
      Buffer.from(clientIdAndSecret).toString('base64');

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Basic ${encodedClientIdAndSecret}`,
      },
    };
    axios
      .post(
        'https://developer.api.autodesk.com/authentication/v2/token',
        params,
        config
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchHubs = (token) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get('https://developer.api.autodesk.com/project/v1/hubs', config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchHubProjects = (token, hub) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `https://developer.api.autodesk.com/project/v1/hubs/${hub}/projects`,
        config
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchTopFolders = (token, hub, projectId) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `https://developer.api.autodesk.com/project/v1/hubs/${hub}/projects/${projectId}/topFolders`,
        config
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchFolderContents = (token, projectId, folderId) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `https://developer.api.autodesk.com/data/v1/projects/${projectId}/folders/${folderId}/contents`,
        config
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const fetchItemVersions = (token, projectId, itemId) => {
  return new Promise((resolve, reject) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(
        `https://developer.api.autodesk.com/data/v1/projects/${projectId}/items/${itemId}/versions`,
        config
      )
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  fetchAccToken,
  fetchHubs,
  fetchHubProjects,
  fetchTopFolders,
  fetchFolderContents,
  fetchItemVersions,
};
