import httpClient from '../httpClient';

const getTables = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/tables`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getTablesBySBS = (projectId, code) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/tables/sbs`, {
        params: {
          'project[id]': projectId,
          sbs_code: code,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getTable = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/tables/${tableId}`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateTable = (tableId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/table/${tableId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteTable = (tableId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/table/${tableId}`, { data: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createTable = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/table`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getTables,
  getTablesBySBS,
  getTable,
  updateTable,
  deleteTable,
  createTable,
};
