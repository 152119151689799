<template>
  <v-dialog
    v-model="displayed"
    max-width="600"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline"
        >Create task</v-card-title
      >
      <v-divider />
      <v-form ref="create-task-form">
        <div class="px-10 pb-5">
          <ant-input label="Title">
            <template #input-field>
              <div class="d-flex">
                <v-text-field
                  v-model="task.title"
                  filled
                  placeholder="title"
                  :rules="[rules.required]"
                  hide-details
                  dense
                />
                <v-tooltip bottom>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      class="ml-2"
                      @click="isPrivate = !isPrivate"
                      v-on="on"
                    >
                      <v-icon :color="isPrivate ? 'primary' : ''"
                        >{{ isPrivate ? 'mdi-lock' : 'mdi-lock-open-outline' }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{
                    isPrivate ? 'Make task public' : 'Make task private'
                  }}</span>
                </v-tooltip>
              </div>
            </template>
          </ant-input>
          <div class="d-flex">
            <ant-input label="Priority" class="mr-2" style="flex: 1">
              <template #input-field>
                <v-select
                  v-model="task.priority"
                  :items="['low', 'normal', 'high', 'urgent']"
                  filled
                  :rules="[rules.required]"
                  placeholder="normal"
                  hide-details
                  dense
                >
                  <template #selection="{ item, index }">
                    <v-icon :color="getPriorityColor(item)" class="mr-2"
                      >{{ getPriorityIcon(item) }}
                    </v-icon>
                    {{ item }}
                  </template>
                  <template #item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on">
                      <v-list-item-icon>
                        <v-icon :color="getPriorityColor(item)"
                          >{{ getPriorityIcon(item) }}
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item>
                  </template>
                </v-select>
              </template>
            </ant-input>
            <ant-input label="Project" style="flex: 2">
              <template #input-field>
                <v-combobox
                  v-model="task.project"
                  :items="projects"
                  filled
                  clearable
                  :rules="[rules.required]"
                  :disabled="project !== null"
                  placeholder="project"
                  hide-details
                  item-text="name"
                  return-object
                  dense
                />
              </template>
            </ant-input>
          </div>
          <div id="label-wrapper" class="mt-5">
            <v-tooltip
              activator="#label-wrapper"
              :open-on-click="false"
              :open-on-hover="!task?.project"
              bottom
            >
              <span>Choose project first</span>
            </v-tooltip>
            <task-label
              :labels="task.labels"
              :project-id="task?.project?.id"
              :disabled="!task?.project"
              editing
              @on-change="onLabelChange"
            />
          </div>
          <ant-input label="Executed by">
            <template #input-field>
              <v-combobox
                v-model="task.assigned_user"
                :disabled="!task.project"
                placeholder="email"
                single-line
                clearable
                :items="users"
                item-text="email"
                item-value="id"
              />
            </template>
          </ant-input>

          <div class="d-flex">
            <ant-input label="Due date" is-optional>
              <template #input-field>
                <v-menu
                  v-model="menus.due_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="task.due"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      :placeholder="today"
                      filled
                      clearable
                      dense
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="task.due"
                    no-title
                    scrollable
                    @input="menus.due_date = false"
                  />
                </v-menu>
              </template>
            </ant-input>

            <ant-input label="Start date" class="mx-2" is-optional>
              <template #input-field>
                <v-menu
                  v-model="menus.start_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="task.planned_start"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      clearable
                      :placeholder="today"
                      filled
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="task.planned_start"
                    no-title
                    scrollable
                    @input="menus.start_date = false"
                  />
                </v-menu>
              </template>
            </ant-input>
            <ant-input label="End date" is-optional>
              <template #input-field>
                <v-menu
                  v-model="menus.end_date"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field
                      v-model="task.planned_end"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      hide-details
                      dense
                      clearable
                      :placeholder="today"
                      filled
                      v-bind="attrs"
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="task.planned_end"
                    no-title
                    scrollable
                    @input="menus.end_date = false"
                  />
                </v-menu>
              </template>
            </ant-input>
          </div>
          <ant-input v-if="task.project !== null" label="Sbscode" is-optional>
            <template #input-field>
              <v-combobox
                v-model="task.sbscode"
                :items="sbsRecords"
                item-value="code"
                item-text="code"
                placeholder="Sbscode"
                hide-details
                filled
                dense
                :loading="sbsLoading"
                clearable
              >
                <template #item="{ item }">
                  <div class="d-flex">
                    {{ item.label ? item.label : '-' }}
                    <v-divider vertical class="mx-2" />
                    <div class="font-italic fs-12">{{ item.code }}</div>
                  </div>
                </template>
              </v-combobox>
            </template>
          </ant-input>
          <slot name="add-task-options" />
          <ant-input label="Description" is-optional>
            <template #input-field>
              <v-textarea
                v-model="task.description"
                filled
                :rules="[rules.maxLength]"
                placeholder="Please add a task description here"
              />
            </template>
          </ant-input>
          <v-spacer />
        </div>
      </v-form>

      <v-card-actions class="ant-border-top ant-dialog-actions-bg">
        <v-spacer />
        <v-btn color="error" small text @click.stop="closeDialog">
          Cancel
        </v-btn>
        <v-btn color="primary" small elevation="0" @click.stop="saveTask">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import {
  getProjectMembersWithTaskPermissions,
  getProjects,
} from '@/services/api/projects.api';
import { mapGetters } from 'vuex';
import TaskHelper from '@/services/task-helper';
import { getSbsRecords } from '@/services/api/sbs.api';
import AntInput from '@/components/AntInput.vue';
import TaskLabel from '@/components/Tasks/TaskLabel.vue';
import auth from '@/store/auth';

export default {
  name: 'AddTaskDialog',
  components: { TaskLabel, AntInput },
  props: {
    parent: {
      type: String,
      default: null,
    },
    returnCreate: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      isPrivate: false,
      displayed: false,
      today: moment().format('YYYY-MM-DD'),
      task: {
        assigned_user: null,
        labels: [],
      },
      users: [],
      projects: [],
      menus: {
        start_date: false,
        end_date: false,
        due_date: false,
      },
      rules: {
        required: (value) => !!value || 'Required.',
        maxLength: (value) =>
          value ? value.length < 250 || 'Max 250 characters' : true,
      },
      requestStatus: '',
      sbsRecords: [],
      sbsLoading: false,
    };
  },
  computed: {
    ...mapGetters(['selectedLicense', 'authenticatedUser']),
  },
  watch: {
    async displayed(value) {
      if (value) {
        this.projects = await getProjects({
          'filter[license]': this.selectedLicense.id,
          'filter[tasks]': true,
        });
        if (this.project) {
          this.task.project = this.project;
        }

        this.task.assigned_user = this.authenticatedUser;
      } else {
        this.closeDialog();
      }
    },
    async 'task.project'(newValue, oldValue) {
      if (newValue && newValue?.id !== oldValue?.id) {
        this.sbsLoading = true;
        this.sbsRecords = await getSbsRecords(newValue.id, {});
        this.users = await getProjectMembersWithTaskPermissions(newValue.id);
        this.sbsLoading = false;
      }
    },
  },
  methods: {
    closeDialog() {
      if (this.requestStatus !== 'loading') {
        this.task = {
          labels: [],
        };
        this.$refs['create-task-form'].reset();
        this.displayed = false;
        this.requestStatus = '';
        this.$emit('closeDialog');
      }
    },
    async saveTask() {
      if (this.$refs['create-task-form'].validate()) {
        if (!this.task.title) {
          this.$store.commit('showNotification', {
            content: 'Please fill in the tasks title',
            color: 'error',
          });
          return;
        }
        if (!this.task.assigned_user) {
          this.$store.commit('showNotification', {
            content: 'Please add an assigned user',
            color: 'error',
          });
          return;
        }

        let body = {
          parent: this.parent,
          project: this.task.project ? this.task.project.id : null,
          title: this.task.title,
          priority: this.task.priority,
          description: this.task.description,
          assigned_to: this.task.assigned_user.id,
          planned_start: this.task.planned_start,
          planned_end: this.task.planned_end,
          due: this.task.due,
          labels: this.task.labels.map((l) => l.id),
          sbscode: this.task.project
            ? this.task.sbscode
              ? this.task.sbscode.code
              : null
            : null,
          license: this.selectedLicense.id,
          is_private: this.isPrivate,
        };

        if (this.returnCreate) {
          this.$emit('createTask', body);
        } else {
          this.requestStatus = 'loading';

          this.$store
            .dispatch('createTask', { body })
            .then((task) => {
              this.requestStatus = 'success';
              this.$store.commit('showNotification', {
                content: `Successfully created task '${task.title}'`,
                color: 'success',
              });
              this.$emit('success');
              this.closeDialog();
              if (this.parent) {
                this.$store.dispatch('reloadDetailTask', this.parent);
              }
            })
            .catch((error) => {
              this.requestStatus = 'error';
            });
        }
      }
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    onLabelChange(labels) {
      this.task.labels = labels;
    },
  },
};
</script>

<style scoped></style>
