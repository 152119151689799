import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import LocalStorageService from '@/services/local-storage';
import appConfig from '@/appConfig';

export let LaravelEchoClient = null;

if (appConfig.VUE_APP_PUSHER_APP_ENABLED === 'true') {
  LaravelEchoClient = new Echo({
    broadcaster: 'pusher',
    cluster: appConfig.VUE_APP_PUSHER_APP_CLUSTER,
    key: appConfig.VUE_APP_PUSHER_APP_KEY,
    wsHost: appConfig.VUE_APP_PUSHER_HOST,
    wsPort: appConfig.VUE_APP_PUSHER_PORT || 443,
    forceTLS: false,
    disableStats: true,
    Pusher,
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          fetch(appConfig.VUE_APP_BASE_URL + '/api/broadcasting/auth', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${LocalStorageService.getToken()}`,
            },
            body: JSON.stringify({
              socket_id: socketId,
              channel_name: channel.name,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              callback(false, data);
            })
            .catch((error) => {
              callback(true, error);
            });
        },
      };
    },
  });

  // Bind connected and disconnected events once
  LaravelEchoClient.connector.pusher.connection.bind('connected', () => {
    console.info('Connected to websocket server');
  });

  LaravelEchoClient.connector.pusher.connection.bind('disconnected', () => {
    console.error('Disconnected from websocket server');
  });
}
