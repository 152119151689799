import { fetchAccToken } from '@/services/forge/autodesk-construction-cloud';

const state = {
  accAccessToken: undefined,
};
const mutations = {
  acc_access_token_success(state, token) {
    state.accAccessToken = token;
  },
};
const actions = {
  fetchAccAccessToken({ commit }, code) {
    return new Promise((resolve, reject) => {
      fetchAccToken(code)
        .then((response) => {
          commit('acc_access_token_success', response.access_token);
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
};
const getters = {
  accAccessToken: (state) => state.accAccessToken,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
