import {
  createLabel,
  deleteLabel,
  getLabels,
  updateLabel,
} from '@/services/api/labels.api';

const state = {
  labels: [],
  labelsStatus: [],
};
const mutations = {
  labels_request(state, labels) {
    state.labelsStatus = 'loading';
  },
  labels_success(state, labels) {
    state.labels = labels;
    state.labelsStatus = 'success';
  },
  create_label_success(state, label) {
    state.labels.unshift(label);
  },
  update_label_success(state, label) {
    Object.assign(
      state.labels.find((item) => item.id === label.id),
      label
    );
  },
  delete_label_success(state, deletedId) {
    state.labels = state.labels.filter((item) => item.id !== deletedId);
  },
};
const actions = {
  fetchLabels({ commit, getters }, filters) {
    commit('labels_request');
    filters['filter[license]'] = getters.selectedLicense.id;
    return getLabels(filters)
      .then((labels) => {
        commit('labels_success', labels);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  createLabel({ commit, getters }, label) {
    return new Promise((resolve, reject) => {
      return createLabel(label)
        .then((label) => {
          commit('create_label_success', label);
          resolve(label);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  updateLabel({ commit, getters }, { labelId, body }) {
    return new Promise((resolve, reject) => {
      return updateLabel(labelId, body)
        .then((label) => {
          commit('update_label_success', label);
          resolve(label);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  deleteLabel({ commit, getters }, { labelId }) {
    return new Promise((resolve, reject) => {
      return deleteLabel(labelId)
        .then((response) => {
          commit('delete_label_success', labelId);
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
};
const getters = {
  labels: (state) => state.labels,
  labelsStatus: (state) => state.labelsStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
