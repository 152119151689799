import httpClient from '../httpClient';

const getRecords = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/records`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data.records);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getRecord = (projectId, tableId, recordId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/record/${recordId}`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getFilteredRecords = (projectId, tableId, filters, timestamp = null) => {
  let config = {
    params: {
      'project[id]': projectId,
      'table[id]': tableId,
    },
  };
  Object.keys(filters).forEach((key) => {
    config.params[`filter[${key}]`] = filters[key];
  });
  if (timestamp) {
    config.params.timestamp = timestamp;
  }
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/records`, config)
      .then((response) => {
        resolve(response.data.records);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getFilteredRecordsWithMeta = ({
  projectId,
  tableId,
  filters,
  timestamp = null,
  sortBy = null,
}) => {
  let config = {
    params: {
      'project[id]': projectId,
      'table[id]': tableId,
    },
  };
  Object.keys(filters).forEach((key) => {
    config.params[`filter[${key}]`] = filters[key];
  });
  if (timestamp) {
    config.params.timestamp = timestamp;
  }
  if (sortBy?.column) {
    config.params['sort[column]'] = sortBy.column;
    config.params['sort[order]'] = sortBy.order ? 'ASC' : 'DESC';
  }
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/records`, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getRecordsMax = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/records`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
          'filter[limit]': 0,
        },
      })
      .then((response) => {
        resolve(response.data.records);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getRecordsWithDocuments = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/records`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
          'filter[document]': true,
        },
      })
      .then((response) => {
        resolve(response.data.records);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createRecord = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/record`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateRecord = (recordId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/record/${recordId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteRecord = (recordId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/record/${recordId}`, { data: data })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteRecords = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/records`, { data: data })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const clearAllRecords = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/records/clear`, {
        data: {
          project: {
            id: projectId,
          },
          table: {
            id: tableId,
          },
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getRecordDocument = (documentId, projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/record/document/${documentId}`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const importRecords = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/records/import`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const verifyRecords = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/records/verify`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getRecordHistory = (projectId, tableId, recordId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/record/history/${recordId}`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const recordsSearch = (body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/search`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const recordsRelations = ({ record, projectId, tableId }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/record/${record}/relations`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getRecords,
  getRecord,
  clearAllRecords,
  getFilteredRecords,
  getFilteredRecordsWithMeta,
  getRecordsMax,
  getRecordsWithDocuments,
  createRecord,
  updateRecord,
  deleteRecord,
  deleteRecords,
  getRecordDocument,
  importRecords,
  verifyRecords,
  getRecordHistory,
  recordsSearch,
  recordsRelations,
};
