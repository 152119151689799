import { waitForVuex } from '@/utils/vuex';
import { getNews } from '@/services/api/news.api';
import LocalStorageService from '@/services/local-storage';
import moment from 'moment';

const state = {
  news: [],
  newsSidebarShown: false,
  newsSidebarClickOutsideEnabled: false,
  forceUpdatable: 0,
};

const mutations = {
  news_success(state, news) {
    state.news = news;
  },
  toggle_news_sidebar(state) {
    state.newsSidebarShown = !state.newsSidebarShown;
    if (!state.newsSidebarShown) {
      state.newsSidebarClickOutsideEnabled = false;
    }
    state.forceUpdatable++;
  },
  open_news_sidebar(state) {
    state.newsSidebarShown = true;
  },
  close_news_sidebar(state) {
    state.newsSidebarShown = false;
  },
  enable_news_sidebar_click_outside() {
    state.newsSidebarClickOutsideEnabled = true;
  },
  disable_news_sidebar_click_outside() {
    state.newsSidebarClickOutsideEnabled = false;
  },
};

const actions = {
  fetchNewsUpdates: waitForVuex('news.get', async ({ commit }) => {
    try {
      const { data: news } = await getNews();
      if (news) {
        commit('news_success', news);
      }
      return news;
    } catch (error) {
      commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  }),
  markAllNewsAsRead() {
    LocalStorageService.setNewsReadAt(Date.now());
  },
};

const getters = {
  newsSidebarShown: (state) => state.newsSidebarShown,
  news: (state) => state.news,
  newsSidebarClickOutsideEnabled: (state) =>
    state.newsSidebarClickOutsideEnabled,
  newsSidebarHasNewsUpdates: (state) => {
    // Mark as force updatable
    state.forceUpdatable;
    const lastReadAt = moment(LocalStorageService.getNewsReadAt(), 'x');
    return state.news.some((n) => moment(n.updated_at).isAfter(lastReadAt));
  },
  newsSidebarHasCriticalUpdates: (state) => {
    // Mark as force updatable
    state.forceUpdatable;
    const lastReadAt = moment(LocalStorageService.getNewsReadAt(), 'x');
    return state.news.some(
      (n) =>
        [1, true].some((value) => value === n.auto_open) &&
        moment(n.updated_at).isAfter(lastReadAt)
    );
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
