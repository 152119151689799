import Vue from 'vue';
import {
  getTableQueryGraph,
  runTableQueryGraphExport,
} from '@/services/api/table-query.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  selectedTables: {},
  tableColumns: {},
  tablesRecords: [],
  query: {
    // tableId: {
    //   as: '',
    //   columns: [
    //     { name: 'a', as: '', conditions: [{ operator: '', value: '', boolean: 'and' }] },
    //     { name: 'b', as: '', conditions: [{ operator: '', value: '', boolean: 'or' }] },
    //   ],
    // },
  },
};
const mutations = {
  select_table: (state, table) => {
    Vue.set(state.selectedTables, table.id, table);
    Vue.set(state.query, table.id, {
      as: table.id,
      columns: [],
    });
  },
  unselect_table: (state, table) => {
    Vue.delete(state.selectedTables, table.id);
    Vue.delete(state.query, table.id);
  },
  clear: (state) => {
    state.selectedTables = {};
    state.tableColumns = {};
    state.tablesRecords = [];
    state.query = {};
  },
  table_columns_cache: (state, { tableId, columns }) => {
    Vue.set(state.tableColumns, tableId, columns);
  },
  query_table_alias: (state, { tableId, alias }) => {
    if (!state.query?.[tableId]) return;
    Vue.set(state.query[tableId], 'as', alias);
  },
  query_columns_remove: (state, { table, index }) => {
    const tableId = table.id;
    if (!state.query?.[tableId] || !state.query?.[tableId].columns?.length) {
      return;
    }
    Vue.delete(state.query[tableId].columns, index);
  },
  query_columns_condition_add: (state, { table, index }) => {
    const tableId = table.id;
    if (!state.query?.[tableId]?.columns?.[index]) {
      return;
    }
    const conditions = state.query[tableId].columns[index].conditions || [];
    conditions.push({ operator: '', value: '', boolean: 'and' });
    Vue.set(state.query[tableId].columns[index], 'conditions', conditions);
  },
  query_columns_condition_remove: (state, { table, index, conditionIndex }) => {
    const tableId = table.id;
    if (
      !state.query?.[tableId]?.columns?.[index]?.conditions?.[conditionIndex]
    ) {
      return;
    }
    Vue.delete(state.query[tableId].columns[index].conditions, conditionIndex);
  },
  query_columns_add: (state, table) => {
    const tableId = table.id;
    if (!state.query?.[tableId]) {
      Vue.set(state.query, tableId, {
        as: table.id,
        columns: [],
      });
    }
    state.query?.[tableId].columns.push({
      active: true,
      name: '',
      as: '',
      conditions: [{ operator: '', value: '', boolean: 'and' }],
    });
  },
  set_latest_data: (state, data) => {
    state.tablesRecords = data;
  },
};
const actions = {
  toggleTable({ state, commit, dispatch, rootGetters }, table) {
    if (state.selectedTables[table.id]) {
      commit('unselect_table', table);
    } else {
      dispatch(
        'fetchTableInfo',
        { projectId: rootGetters.project.id, tableId: table.id },
        { root: true }
      ).then((data) => {
        commit('table_columns_cache', { tableId: table.id, columns: data });
      });

      commit('select_table', table);
    }
  },
  setTableAlias({ state, commit }, { tableId, alias }) {
    commit('query_table_alias', { tableId, alias });
  },
  addSearchColumn({ state, commit }, table) {
    commit('query_columns_add', table);
  },
  removeSearchColumn({ state, commit }, { table, index }) {
    commit('query_columns_remove', { table, index });
  },
  fetchTableQueryRecords: waitForVuex(
    'tables.queryGraph.get',
    ({ commit, dispatch }, { projectId, body }) => {
      return getTableQueryGraph({ projectId, body })
        .then((data) => {
          commit('set_latest_data', data);
        })
        .catch((error) => {
          commit(
            'showNotification',
            {
              content: error.message,
              color: 'error',
            },
            { root: true }
          );
        });
    }
  ),
  runExportTableQueryRecords: waitForVuex(
    'tables.queryGraph.export',
    ({ commit, dispatch }, { projectId, body }) => {
      return runTableQueryGraphExport({ projectId, body })
        .then(() => {
          commit(
            'showNotification',
            {
              content: `Export jobs are started. You will find result files on downloads page.`,
              color: 'success',
            },
            { root: true }
          );
        })
        .catch((error) => {
          commit(
            'showNotification',
            {
              content: error.message,
              color: 'error',
            },
            { root: true }
          );
        });
    }
  ),
};
const getters = {
  selectedTables: (state) => state.selectedTables,
  selectedTablesArray: (state) => Object.values(state.selectedTables),
  tableColumns: (state) => state.tableColumns,
  tablesRecords: (state) => state.tablesRecords,
  tablesRecordsTables: (state) => Object.keys(state.tablesRecords),
  getTableQuery: (state) => (tableId) => state.query?.[tableId],
  queryObject: (state) => {
    return Object.keys(state.query).reduce((query, tableId) => {
      const tableConfig = state.query[tableId];
      if (!tableConfig.columns?.some((c) => c.active && c.name)) return query;

      const request = {
        tableId,
        as: tableConfig.as,
        columns: tableConfig.columns
          .filter((c) => c.name && c.active)
          .map((c) => ({
            name: c.name,
            as: c.as || c.name,
            ...(c.conditions?.length > 0 &&
            c.conditions.some((c) => c.operator && c.value)
              ? {
                  conditions: c.conditions
                    .filter((c) => c.operator && c.value)
                    .map((c) => ({
                      operator: c.operator,
                      value: c.value,
                      boolean: c.boolean,
                    })),
                }
              : {}),
          })),
      };
      if (!query.tables) {
        query.tables = [];
      }
      query.tables.push(request);
      return query;
    }, {});
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
