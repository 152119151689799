import { ability, updateAbilities } from '@/services/casl';
import store from '@/store';

export const abilityMiddleware =
  (action, subject, onError) => (to, from, next) => {
    updateAbilities(store);
    if (ability.can(action, subject)) {
      return next();
    }
    if (onError && typeof onError === 'function') {
      return onError(to, from, next);
    }
    return next({ name: 'Dashboard' });
  };
