import { unwrapV2 } from '@/services/api/v2/utils';

export const getMembersV2 = (projectId) =>
  unwrapV2((axios) => axios.get(`/projects/${projectId}/members`));

export const addRoleToUserV2 = (projectId, roleId, email) =>
  unwrapV2((axios) =>
    axios.post(`/projects/${projectId}/members`, { role: roleId, user: email })
  );

export const removeRoleFromUserV2 = (projectId, roleId, email) =>
  unwrapV2((axios) =>
    axios.delete(`/projects/${projectId}/members`, {
      data: {
        role: roleId,
        user: email,
      },
    })
  );
