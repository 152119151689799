import { getModuleData } from '@/services/api/module.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import { createRecord } from '@/services/api/record.api';
import { createUUID } from '@/services/uuid-helper';
import { waitForVuex } from '@/utils/vuex';
import { getTasks } from '@/services/api/task.api';
import {
  createRecordV2,
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';

const state = {
  moduleStatus: '',
  fitGapItems: [],
  fitGapItemsTableId: [],
  tasks: {},
};

const mutations = {
  module_fit_gap_request(state) {
    state.moduleStatus = 'loading';
  },
  module_fit_gap_success(state, items) {
    state.fitGapItems = items.records;
    state.fitGapItemsTableId = items.id;
    state.moduleStatus = 'success';
  },
  module_fit_gap_create_success(state, record) {
    state.fitGapItems.push(record);
  },
  module_fit_gap_update_success(state, record) {
    Object.assign(
      state.fitGapItems.find((r) => r.id === record.id),
      record
    );
  },
  module_fit_gap_delete_success(state, recordId) {
    state.fitGapItems = state.fitGapItems.filter((r) => r.id !== recordId);
  },
  module_fit_gap_create_task_success(state, { fitGapItemId, task }) {
    state.tasks = {
      ...state.tasks,
      [fitGapItemId]: [...state.tasks?.[fitGapItemId], task],
    };
  },
  module_fit_gap_update_task_success(state, { fitGapItemId, task }) {
    state.tasks = {
      ...state.tasks,
      [fitGapItemId]: [
        ...state.tasks?.[fitGapItemId]?.map((mapTask) => {
          if (mapTask.id !== task.id) {
            return task;
          }
          return mapTask;
        }),
      ],
    };
  },
  module_fit_gap_get_tasks_success(state, { id, tasks }) {
    state.tasks = {
      ...state.tasks,
      [id]: tasks,
    };
  },
};

const actions = {
  loadModuleData: waitForVuex(
    'module.fit-gap.data.get',
    async ({ commit }, { projectId, moduleId, sessionId }) => {
      commit('module_fit_gap_request');

      const { items } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_FIT_GAP_ITEMS',
            project: projectId,
            as: 'items',
            columns: [
              'friendly_id',
              'category',
              'desired_situation',
              'current_situation',
              'gap_description',
              'gap_priority',
              'fit',
            ].map((name) => ({ name })),
          },
        ],
      });

      commit('module_fit_gap_success', items);
    }
  ),

  createFitGap: waitForVuex(
    'module.fit-gap.item.create',
    async (
      { state, commit, rootGetters },
      {
        category,
        desired_situation,
        current_situation,
        gap_description,
        gap_priority,
        fit,
      }
    ) => {
      const record = await createRecordV2(state.fitGapItemsTableId, {
        friendly_id:
          Math.max(
            ...state.fitGapItems
              .filter((r) => r.category === category)
              .map((r) => r.friendly_id)
          ) ?? 1,
        category,
        desired_situation,
        current_situation,
        gap_description,
        gap_priority,
        fit,
      });
      commit('module_fit_gap_create_success', record);
      return record;
    }
  ),

  updateFitGap: waitForVuex(
    'module.fit-gap.item.update',
    async ({ state, commit, rootGetters }, { recordId, column, value }) => {
      const record = await updateRecordV2(state.fitGapItemsTableId, recordId, {
        [column]: value,
      });
      commit('module_fit_gap_update_success', record);
      return record;
    }
  ),

  deleteFitGap: waitForVuex(
    'module.fit-gap.item.delete',
    async ({ state, commit, rootGetters }, { recordId }) => {
      await deleteRecordV2(state.fitGapItemsTableId, ...[recordId]);
      commit('module_fit_gap_delete_success', recordId);
    }
  ),

  fetchTasks: waitForVuex(
    'module.fit-gap.tasks.get',
    async ({ commit, rootGetters }, id) => {
      const tasks = await getTasks(
        {
          'filter[license]': rootGetters.selectedLicense.id,
        },
        {
          advanced_filters: [
            {
              column: 'project',
              operator: '=',
              values: [rootGetters.project.id],
            },
            {
              column: 'type',
              operator: '=',
              values: ['fit-gap'],
            },
            {
              column: 'fit_gap_item_id',
              operator: '=',
              values: [id],
            },
          ],
        }
      );
      commit('module_fit_gap_get_tasks_success', { id, tasks });
      return tasks;
    }
  ),
};

const getters = {
  status: (state) => state.moduleStatus,
  records: (state) => state.fitGapItems || [],
  tasks: (state) => state.tasks,
  recordsByCategories: (state) =>
    (state.fitGapItems || []).reduce(
      (acc, record) => ({
        ...acc,
        [record.category]: [...(acc[record.category] || []), record],
      }),
      {}
    ),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
