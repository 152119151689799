import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  getRecordDocument,
  updateRecord,
} from '@/services/api/record.api';
import FileHandlerService from '@/services/file-handler';
import { waitForVuex } from '@/utils/vuex';

const state = {
  moduleData: {
    CFFA_SA_SCENARIOS: {
      id: null,
      records: [],
    },
    CFFA_SA_SCENARIOS_SWOT: {
      id: null,
      records: [],
    },
  },
  moduleStatus: '',
  selectedScenario: null,
};
const mutations = {
  module_swot_scenarios_toggle_scenario(state, scenario) {
    if (scenario === state.selectedScenario) {
      state.selectedScenario = null;
    } else {
      state.selectedScenario = scenario;
    }
  },
  module_swot_scenarios_request(state) {
    state.moduleStatus = 'loading';
  },
  module_swot_scenarios_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_swot_scenarios_create_success(state, record) {
    state.moduleData.CFFA_SA_SCENARIOS.records.push(record);
  },
  module_swot_scenarios_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_SA_SCENARIOS.records.find(
        (module) => module.id === record.id
      ),
      record
    );
    if (state.selectedScenario.id === record.id) {
      state.selectedScenario = record;
    }
  },
  module_swot_scenarios_delete_success(state, deletedId) {
    state.moduleData.CFFA_SA_SCENARIOS.records =
      state.moduleData.CFFA_SA_SCENARIOS.records.filter(
        (record) => record.id !== deletedId
      );
  },
  module_swot_scenarios_link_create_success(state, record) {
    state.moduleData.CFFA_SA_SCENARIOS_SWOT.records.push(record);
  },
  module_swot_scenarios_link_delete_success(state, deletedId) {
    state.moduleData.CFFA_SA_SCENARIOS_SWOT.records =
      state.moduleData.CFFA_SA_SCENARIOS_SWOT.records.filter(
        (record) => record.id !== deletedId
      );
  },
};
const actions = {
  loadModuleData({ commit, rootGetters }, { projectId, moduleId, sessionId }) {
    commit('module_swot_scenarios_request');
    commit('gisViewer/clear_data', null, { root: true });
    getModuleData(projectId, moduleId, sessionId)
      .then(async (data) => {
        commit(
          'module_stake_holder_analysis_success',
          {
            CFFA_SA_STAKEHOLDERS: data.CFFA_SA_STAKEHOLDERS,
            CFFA_SA_SWOT_INPUT: data.CFFA_SA_SWOT_INPUT,
          },
          { root: true }
        );

        commit(
          'gisViewer/set_data',
          {
            properties:
              data.CFFA_GIS_PROPERTIES.records.length &&
              data.CFFA_GIS_PROPERTIES.records[0]
                ? {
                    ...data.CFFA_GIS_PROPERTIES.records[0],
                    center: JSON.parse(
                      data.CFFA_GIS_PROPERTIES.records[0].center
                    ),
                    basemaps: JSON.parse(
                      data.CFFA_GIS_PROPERTIES.records[0].basemaps || '[]'
                    ),
                  }
                : {},
            layers: {
              CFFA_GIS_GROUP_LAYERS: { records: [] },
              CFFA_GIS_FEATURE_LAYERS: { records: [] },
              CFFA_GIS_IMAGERY_LAYERS: { records: [] },
              CFFA_GIS_MAP_IMAGE_LAYERS: { records: [] },
              CFFA_GIS_TILE_LAYERS: { records: [] },
              CFFA_GIS_VECTOR_TILE_LAYERS: { records: [] },
              CFFA_GIS_WMS_LAYERS: { records: [] },
              CFFA_GIS_SUB_LAYERS: { records: [] },
              CFFA_GIS_SCENE_LAYERS: { records: [] },
              CFFA_GIS_BUILDING_SCENE_LAYERS: { records: [] },
              CFFA_GIS_PC_LAYERS: { records: [] },
              CFFA_GIS_VOXEL_LAYERS: { records: [] },
            },
          },
          { root: true }
        );
        commit('module_swot_scenarios_success', {
          CFFA_SA_SCENARIOS: data.CFFA_SA_SCENARIOS,
          CFFA_SA_SCENARIOS_SWOT: data.CFFA_SA_SCENARIOS_SWOT,
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  createScenario({ commit, getters, rootGetters }, name) {
    return new Promise(async (resolve, reject) => {
      let record = await createRecord({
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: getters.data.CFFA_SA_SCENARIOS.id,
        },
        record: {
          name: name,
        },
      });
      commit('module_swot_scenarios_create_success', record);
      resolve(record);
    });
  },
  updateScenario: waitForVuex(
    (_, { recordId }) => `updateScenario.${recordId}`,
    async ({ commit, getters, rootGetters }, { recordId, data }) => {
      // const data = btoa(unescape(encodeURIComponent(json)));
      // const json = decodeURIComponent(escape(atob(data)));
      return new Promise(async (resolve, reject) => {
        let record = await updateRecord(recordId, {
          project: {
            id: rootGetters.project.id,
          },
          table: {
            id: getters.data.CFFA_SA_SCENARIOS.id,
          },
          record: data,
        });
        if (!record.drawing.file && record.drawing?.id) {
          record.drawing = await getRecordDocument(
            record.drawing.id,
            rootGetters.project.id,
            getters.data.CFFA_SA_SCENARIOS.id
          );
        }
        commit('module_swot_scenarios_update_success', record);
        resolve(record);
      });
    }
  ),
  deleteScenario({ commit, getters, rootGetters }, recordId) {
    return new Promise(async (resolve, reject) => {
      let record = await deleteRecord(recordId, {
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: getters.data.CFFA_SA_SCENARIOS.id,
        },
      });
      commit('module_swot_scenarios_delete_success', recordId);
      resolve(record);
    });
  },

  async linkSwotToScenario(
    { commit, dispatch, getters, rootGetters, state },
    swotId
  ) {
    let scenarioId = state.selectedScenario?.id;
    if (!scenarioId) {
      commit(
        'showNotification',
        {
          content: 'Select the scenario first',
          color: 'error',
        },
        { root: true }
      );
      return;
    }

    const key = `swotToScenario-${swotId}-${scenarioId}`;
    dispatch('wait/start', key, { root: true });

    try {
      let record = await createRecord({
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: getters.data.CFFA_SA_SCENARIOS_SWOT.id,
        },
        record: {
          swot_input_id: swotId,
          scenario_id: scenarioId,
        },
      });
      commit('module_swot_scenarios_link_create_success', record);
      return record;
    } finally {
      dispatch('wait/end', key, { root: true });
    }
  },

  async unlinkSwotToScenario(
    { commit, dispatch, getters, rootGetters, state },
    swotId
  ) {
    let scenarioId = state.selectedScenario?.id;
    if (!scenarioId) {
      commit(
        'showNotification',
        {
          content: 'Select the scenario first',
          color: 'error',
        },
        { root: true }
      );
      return;
    }

    const record = getters.selectedScenarioSwots.find(
      (record) =>
        record.swot_input_id === swotId && record.scenario_id === scenarioId
    );
    const recordId = record?.id;
    if (!recordId) {
      commit(
        'showNotification',
        {
          content: 'Issue on SWOT input unlink. Record id not found',
          color: 'error',
        },
        { root: true }
      );
      return;
    }

    const key = `swotToScenario-${swotId}-${scenarioId}`;
    dispatch('wait/start', key, { root: true });

    try {
      let record = await deleteRecord(recordId, {
        project: {
          id: rootGetters.project.id,
        },
        table: {
          id: getters.data.CFFA_SA_SCENARIOS_SWOT.id,
        },
      });
      commit('module_swot_scenarios_link_delete_success', recordId);
      return record;
    } finally {
      dispatch('wait/end', key, { root: true });
    }
  },
};

const getters = {
  data: (state) => state.moduleData,
  status: (state) => state.moduleStatus,
  scenarios: (state) => state.moduleData.CFFA_SA_SCENARIOS?.records,
  selectedScenarioSwots: (state) => {
    const scenarioId = state.selectedScenario?.id;
    if (!scenarioId) return [];
    return state.moduleData.CFFA_SA_SCENARIOS_SWOT?.records.filter(
      (record) => record.scenario_id === scenarioId
    );
  },
  selectedScenarioSwotIds: (state, getters) => {
    return getters.selectedScenarioSwots.map((record) => record.swot_input_id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
