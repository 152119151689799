import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { bookOfObjects } from '@/services/bookOf';
import record from '@/store/record';
import { getTasks } from '@/services/api/task.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  orderColumn: undefined,
  selectedPhase: undefined,
  wvbPhases: [],

  highestOrder: 0,
  highlightedModule: undefined,
};
const mutations = {
  wvb_placement_tasks_success(state, tasks) {
    // map tasks to phases structure
    state.wvbPhases = tasks.map((task) => {
      return {
        id: task.id,
        name: task.title,
        number: parseInt(task.task_type.custom_1),
        color: `#${((Math.random() * 0xffffff) << 0)
          .toString(16)
          .padStart(6, '0')}`,
      };
    });
  },
  module_wvb_request(state) {
    state.moduleStatus = 'loading';
  },
  module_wvb_success(state, data) {
    state.moduleData = data;
    let models = state.moduleData.CFFA_FORGE_MODELS.records;
    if (models.length > 0) {
      models[0].enabled = true;
    }
    state.moduleStatus = 'success';
  },
  update_wvb_record(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_MODULES.records.find(
        (module) => module.id === record.id
      ),
      record
    );
  },
  update_non_module_wvb_record(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_NON_MODULES.records.find(
        (module) => module.id === record.id
      ),
      record
    );
  },
  wvb_import_success(state, records) {
    records.forEach((record) => {
      if (record.id !== undefined && record.id !== null) {
        Object.assign(
          state.moduleData.CFFA_DHME_MODULES.records.find(
            (x) => x.id === record.id
          ),
          record
        );
      } else {
        state.moduleData.CFFA_DHME_MODULES.records.push(record);
      }
    });
  },
  wvb_non_module_import_success(state, records) {
    records.forEach((record) => {
      if (record.id !== undefined && record.id !== null) {
        Object.assign(
          state.moduleData.CFFA_DHME_NON_MODULES.records.find(
            (x) => x.id === record.id
          ),
          record
        );
      } else {
        state.moduleData.CFFA_DHME_NON_MODULES.records.push(record);
      }
    });
  },
  set_highest_sequence(state, order) {
    state.highestOrder = order;
  },
  set_highlighted_module_id(state, moduleId) {
    state.highlightedModule = moduleId;
  },
  reset_highlighted_module_id(state) {
    state.highlightedModule = undefined;
  },
};
const actions = {
  fetchPlacementPhases({ commit, getters }) {
    getTasks(
      {
        'filter[license]': getters.selectedLicense.id,
      },
      {
        advanced_filters: [
          {
            column: 'project',
            operator: '=',
            values: [getters.project.id],
          },
          {
            column: 'type',
            operator: '=',
            values: ['dhme-placement-phase'],
          },
        ],
      }
    ).then((tasks) => {
      commit('wvb_placement_tasks_success', tasks);
    });
  },
  fetchWVBModuleData({ commit }, { projectId, moduleId, sessionId }) {
    return new Promise((resolve, reject) => {
      commit('module_wvb_request');
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_wvb_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  setPhase({ state }, phase) {
    state.selectedPhase = phase;
  },
  resetPhase({ state }) {
    state.selectedPhase = undefined;
  },

  setOrderColumn({ state }, column) {
    state.orderColumn = column;
  },

  resetOrderColumn({ state }) {
    state.orderColumn = undefined;
  },

  addModuleToPhase({ commit, getters, state }, { moduleId }) {
    if (!moduleId.startsWith('NonModuleObject;')) {
      let records = state.moduleData.CFFA_DHME_MODULES.records;

      let record = records.find((record) => record.module_id === moduleId);

      let body = {
        project: {
          id: getters.project.id,
        },
        table: {
          id: state.moduleData.CFFA_DHME_MODULES.id,
        },
        record: {
          module_id: moduleId,
          phase: state.selectedPhase.id,
          placement_sequence: null,
          assembly_sequence: null,
        },
      };

      if (record !== undefined) {
        // update record with existing phase
        return updateRecord(record.id, body)
          .then((record) => {
            commit('update_wvb_record', record);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
          });
      } else {
        this.commit('showNotification', {
          content: `module not found with id: ${moduleId}`,
          color: 'error',
        });
      }
    } else {
      let records = state.moduleData.CFFA_DHME_NON_MODULES.records;

      let record = records.find((record) => record.non_module_id === moduleId);

      let body = {
        project: {
          id: getters.project.id,
        },
        table: {
          id: state.moduleData.CFFA_DHME_NON_MODULES.id,
        },
        record: {
          non_module_id: moduleId,
          phase: state.selectedPhase.name,
          phase_number: state.selectedPhase.number,
        },
      };

      if (record !== undefined) {
        // update record with existing phase
        return updateRecord(record.id, body)
          .then((record) => {
            commit('update_non_module_wvb_record', record);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
          });
      } else {
        this.commit('showNotification', {
          content: `non module not found with id: ${moduleId}`,
          color: 'error',
        });
      }
    }
  },

  updateModuleSequence({ commit, getters, state }, { moduleId }) {
    let records = state.moduleData.CFFA_DHME_MODULES.records;

    let record = undefined;
    if (state.orderColumn === 'placement_sequence') {
      record = records.find(
        (record) =>
          record.module_id === moduleId &&
          record.phase === state.selectedPhase.id
      );
    } else if (state.orderColumn === 'assembly_sequence') {
      record = records.find((record) => record.module_id === moduleId);
    }

    if (record !== undefined) {
      let body = {
        project: {
          id: getters.project,
        },
        table: {
          id: state.moduleData.CFFA_DHME_MODULES.id,
        },
        record: {},
      };

      state.highestOrder += 1;
      body.record[state.orderColumn] = state.highestOrder;

      if (
        record[state.orderColumn] === null ||
        record[state.orderColumn] === undefined
      ) {
        // update record with existing column order
        return updateRecord(record.id, body)
          .then((record) => {
            commit('update_wvb_record', record);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
          });
      } else {
        this.commit('showNotification', {
          content: `${record.module_id} already has ${
            record[state.orderColumn]
          } as ${state.orderColumn} value`,
          color: 'error',
        });
      }
    } else {
      this.commit('showNotification', {
        content: `Module not found within a phase`,
        color: 'error',
      });
    }
  },
  updateWVBSequence(
    { commit, getters, state },
    { recordId, column, updatedSequence }
  ) {
    let body = {
      project: {
        id: getters.project,
      },
      table: {
        id: state.moduleData.CFFA_DHME_MODULES.id,
      },
      record: {
        [column]: updatedSequence,
      },
    };
    return updateRecord(recordId, body)
      .then((record) => {
        commit('update_wvb_record', record);
        this.commit('recolor_order_gradient');
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  updateWVBRecord({ commit }, { recordId, body }) {
    return updateRecord(recordId, body)
      .then((record) => {
        commit('update_wvb_record', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  importWvbModules({ commit, getters, dispatch }, data) {
    let book = bookOfObjects('modules', data);
    let csv = book.convert('csv', 'string');
    let parsedCsv = btoa(csv);

    return importRecords({
      project: { id: getters.project.id },
      table: { id: getters.wvbData.CFFA_DHME_MODULES.id },
      records: parsedCsv,
    }).then((response) => {
      commit('wvb_import_success', data);
    });
  },
  importWvbNonModules({ commit, getters, dispatch }, data) {
    let book = bookOfObjects('non-modules', data);
    let csv = book.convert('csv', 'string');
    let parsedCsv = btoa(csv);

    return importRecords({
      project: { id: getters.project.id },
      table: { id: getters.wvbData.CFFA_DHME_NON_MODULES.id },
      records: parsedCsv,
    }).then((response) => {
      commit('wvb_non_module_import_success', data);
    });
  },
};
const getters = {
  wvbData: (state) => state.moduleData,
  wvbModules: (state) => state.moduleData.CFFA_DHME_MODULES.records,
  wvbNonModules: (state) => state.moduleData.CFFA_DHME_NON_MODULES.records,
  wvbPhases: (state) => state.wvbPhases,
  wvbDataStatus: (state) => state.moduleStatus,
  wvbOrderColumn: (state) => state.orderColumn,
  wvbSelectedPhase: (state) => state.selectedPhase,
  wvbViewerClient: (state) => state.moduleData.CFFA_FORGE_CLIENT.records[0],
  wvbViewerModels: (state) => state.moduleData.CFFA_FORGE_MODELS.records,
  wvbHighlightedModule: (state) => state.highlightedModule,
  wvbModelMapping: (state) =>
    state.moduleData.CFFA_DHME_MODEL_MAPPING.records[0],
  wvbModelMappings: (state) =>
      state.moduleData.CFFA_DHME_MODEL_MAPPING.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
