import { getModuleData } from '@/services/api/module.api';
import {
  clearAllRecords,
  createRecord,
  getRecords,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { bookOfObjects } from '@/services/bookOf';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',

  selectedModule: undefined,
  assemblyPhase: null,

  assemblyHall: null,
  assemblyLocations: [],
  assemblyLocationTypes: [],
};
const mutations = {
  module_module_assembly_request(state) {
    state.moduleStatus = 'loading';
  },

  module_module_assembly_success(state, { hall, locations, locationTypes }) {
    state.assemblyHall = hall;
    state.assemblyLocations = locations;
    state.assemblyLocationTypes = locationTypes;
    state.moduleStatus = 'success';
  },
  module_assembly_phase_tasks_success(state, task) {
    state.assemblyPhase = task;
  },
};
const actions = {
  async fetchModuleAssemblyData({ commit, rootGetters }) {
    commit('module_module_assembly_request');
    try {
      const response = await queryTasksV2(rootGetters.project.license, [
        {
          column: 'type',
          operator: '=',
          values: ['dhme-assembly-phase'],
        },
        {
          column: 'project',
          operator: '=',
          values: [rootGetters.project.id],
        },
      ]);
      const task = response.tasks[0];
      commit('module_assembly_phase_tasks_success', task);

      const { halls } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_HALLS',
            project: rootGetters.project.master_id,
            as: 'halls',
            sortBy: 'hall',
            records: [task.task_type.custom_3],
            columns: [
              {
                name: 'hall',
              },
              {
                name: 'type',
              },
              {
                name: 'automated',
              },
            ],
          },
        ],
      });

      const hall = halls.records[0];

      const { locations } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATIONS',
            project: rootGetters.project.master_id,
            as: 'locations',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_hall',
                conditions: [
                  {
                    operator: '=',
                    value: task.task_type.custom_3,
                  },
                ],
              },
              {
                name: 'assembly_lane',
              },
              {
                name: 'assembly_location',
              },
              {
                name: 'description',
              },
              {
                name: 'order',
              },
              {
                name: 'manual_default',
                conditions: !hall.automated
                  ? [
                      {
                        operator: '=',
                        value: true,
                      },
                    ]
                  : [],
              },
              {
                name: 'hall_configuration',
                as: 'configuration',
                conditions: [
                  {
                    operator: '=',
                    value: task.task_type.custom_4,
                  },
                ],
              },
            ],
          },
        ],
      });

      const { types } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_ASSEMBLY_LOCATION_TYPES',
            project: rootGetters.project.master_id,
            as: 'types',
            sortBy: 'order',
            columns: [
              {
                name: 'assembly_location',
                conditions: [
                  {
                    operator: '=',
                    values: locations.records.map((l) => l.id),
                  },
                ],
              },
              {
                name: 'step',
              },
              {
                name: 'type',
              },
              {
                name: 'friendly_name',
              },
              {
                name: 'order',
              },
            ],
          },
        ],
      });

      commit('module_module_assembly_success', {
        hall,
        locations: locations.records,
        locationTypes: types.records,
      });

      return Promise.resolve(response.tasks);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
const getters = {
  moduleStatus: (state) => state.moduleStatus,
  locations: (state) => state.assemblyLocations,
  locationTypes: (state) => state.assemblyLocationTypes,
  assemblyHall: (state) => state.assemblyHall,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
