import {getModuleData} from "@/services/api/module.api";
import {updateRecord} from "@/services/api/record.api";

const state = {
    moduleData: {
        CFFA_DHME_ASSEMBLY_PLANNING_REQUESTS: {
            records: [],
            id: undefined
        },
        CFFA_DHME_HALLS: {
            records: [],
            id: undefined
        }
    },
    moduleStatus: '',
}
const mutations = {
    module_assembly_planning_request(state) {
        state.moduleStatus = 'loading'
    },
    module_assembly_planning_success(state, data) {
        state.moduleData = data
        state.moduleStatus = 'success'
    },
}
const actions = {
    fetchDhmeAssemblyPlanning({commit}, {projectId, moduleId, sessionId}) {
        return new Promise((resolve, reject) => {
            commit('module_assembly_planning_request')
            getModuleData(projectId, moduleId, sessionId)
                .then(data => {
                    commit('module_assembly_planning_success', data)
                    resolve(data)
                })
                .catch(error => {
                    this.commit('showNotification', {content: error.message, color: 'error'})
                    reject(error)
                })
        })
    },
}
const getters = {
    dhmeAssemblyPlanningData: state => state.moduleData,
    dhmeAssemblyPlanningStatus: state => state.moduleStatus,
    dhmeAssemblyPlanningRequests: state => state.moduleData.CFFA_DHME_ASSEMBLY_PLANNING_REQUESTS.records,
    dhmeAssemblyPlanningHalls: state => state.moduleData.CFFA_DHME_ASSEMBLY_HALLS.records
}

export default {
    state,
    mutations,
    actions,
    getters
}
