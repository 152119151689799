import {
  getTeams,
  createTeam,
  updateTeam,
  deleteTeam,
} from '@/services/api/teams.api';

const state = {
  teams: [],
  teamsStatus: '',
};
const mutations = {
  teams_request(state) {
    state.teamsStatus = 'loading';
  },
  teams_success(state, teams) {
    state.teams = teams;
    state.teamsStatus = 'success';
  },

  teams_create_success(state, team) {
    state.teams.push(team);
  },

  teams_update_success(state, updatedTeam) {
    Object.assign(
      state.teams.find((team) => team.id === updatedTeam.id),
      updatedTeam
    );
  },

  teams_delete_success(state, deletedTeamId) {
    state.teams = [...state.teams.filter((team) => team.id !== deletedTeamId)];
  },
};
const actions = {
  fetchTeams({ commit }, projectId) {
    commit('teams_request');
    return getTeams(projectId)
      .then((teams) => {
        commit('teams_success', teams);
      })
      .catch((error) => {
        commit('teams_error', error);
      });
  },

  createTeam({ commit }, { team }) {
    return createTeam(team)
      .then((response) => {
        commit('teams_create_success', response);
        this.commit('showNotification', {
          content: `Successfully created team ${response.name}`,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: `Failed to create team. ${error.message}`,
          color: 'error',
        });
      });
  },

  updateTeam({ commit }, { teamId, team }) {
    return updateTeam(teamId, team)
      .then((response) => {
        commit('teams_update_success', response);
        this.commit('showNotification', {
          content: `Successfully updated team ${response.name}`,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: `Failed to update team. ${error.message}`,
          color: 'error',
        });
      });
  },
  deleteTeam({ commit }, { teamId, body }) {
    return deleteTeam(teamId, body)
      .then((response) => {
        commit('teams_delete_success', teamId);
        this.commit('showNotification', {
          content: `Successfully deleted team`,
          color: 'success',
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: `Failed to deleted team. ${error.message}`,
          color: 'error',
        });
      });
  },
};
const getters = {
  teams: (state) => state.teams,
  teamsStatus: (state) => state.teamsStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
