import { getProject } from '@/services/api/projects.api';
import { getTeams } from '@/services/api/teams.api';
import { getSessions } from '@/services/api/project-workflow-sessions.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  project: undefined,

  projectTeams: [],

  projectSessions: [],
};
const mutations = {
  set_project(state, project) {
    state.project = project;
  },
  project_success(state, project) {
    state.project = project;
  },
  project_teams_success(state, teams) {
    state.projectTeams = teams;
  },
  project_sessions_success(state, sessions) {
    state.projectSessions = sessions;
  },
};
const actions = {
  setProject({ commit, dispatch }, project) {
    commit('set_project', project);
    dispatch('fetchProject');
  },
  fetchProject: waitForVuex('project.get', ({ commit, state }) => {
    return getProject(state.project.id)
      .then((project) => {
        if (project.image?.id) {
          commit('projects_image_add_cache', {
            id: project.image.id,
            image: project.image,
          });
        }
        commit('project_success', project);
      })
      .catch((error) => {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  }),
  fetchProjectTeams: waitForVuex('project.teams.get', ({ commit, state }) => {
    return getTeams(state.project.id)
      .then((teams) => {
        commit('project_teams_success', teams);
      })
      .catch((error) => {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  }),
  fetchProjectSessions: waitForVuex(
    'project.sessions.get',
    ({ commit, state }) => {
      return getSessions(state.project.id)
        .then((teams) => {
          commit('project_sessions_success', teams);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
};
const getters = {
  project: (state) => state.project,
  projectTeams: (state) => state.projectTeams,
  projectSessions: (state) => state.projectSessions,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
