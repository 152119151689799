import httpClient from '../httpClient';

const getTeams = (projectId, query = {}) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/teams`, {
        params: {
          'project[id]': projectId,
          ...query,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeam = (teamId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateTeam = (teamId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/team/${teamId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTeam = (teamId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/team/${teamId}`, { data: body })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTeam = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/team`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeamUsers = (teamId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}/users`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const addTeamUser = (teamId, projectId, userId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/team/${teamId}/user`, {
        project: {
          id: projectId,
        },
        user: {
          id: userId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTeamUser = (teamId, userId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/team/${teamId}/user/${userId}`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeamPermissions = (teamId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}/permissions`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeamModules = (teamId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}/modules`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTeamModuleRelation = (teamId, moduleId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/team/${teamId}/modules/${moduleId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteTeamModuleRelation = (teamId, moduleId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/team/${teamId}/modules/${moduleId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeamSbsPermissions = (teamId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}/permissions/sbs`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTeamSbsPermissions = (teamId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/team/${teamId}/permissions/sbs`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeamWorkflowPermissions = (teamId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}/permissions/workflows`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTeamWorkflowPermissions = (teamId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/team/${teamId}/permissions/workflows`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getTeamGeneralPermissions = (teamId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/team/${teamId}/permissions/general`, {
        params: {
          'project[id]': projectId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createTeamGeneralPermissions = (teamId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/team/${teamId}/permissions/general`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getTeams,
  getTeam,
  updateTeam,
  deleteTeam,
  createTeam,
  getTeamUsers,
  addTeamUser,
  deleteTeamUser,
  getTeamPermissions,
  getTeamModules,
  createTeamModuleRelation,
  deleteTeamModuleRelation,
  getTeamSbsPermissions,
  createTeamSbsPermissions,
  getTeamWorkflowPermissions,
  createTeamWorkflowPermissions,
  getTeamGeneralPermissions,
  createTeamGeneralPermissions,
};
