import {getTokens, createToken, deleteToken} from '@/services/api/tokens.api'

const state = {
    tokens: [],
    status: '',
}
const mutations = {
    tokens_request(state) {
        state.status = 'loading'
    },
    tokens_success(state, tokens) {
        state.status = 'success'
        state.tokens = tokens
    },
    tokens_add(state, token) {
        state.tokens.push(token)
    },
    tokens_delete_success(state, deletedToken) {
        state.tokens = [...state.tokens.filter(token => token.token_uuid !== deletedToken.token_uuid)]
    },
}
const actions = {
    fetchTokens({commit}) {
        commit('tokens_request')
        return getTokens()
            .then(tokens => {
                commit('tokens_success', tokens)
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },

    createToken({commit}, token) {
        return createToken(token).then(token => {
            commit('tokens_add', token)
        }).catch(error => {
            this.commit('showNotification', {content: error.message, color: 'error'})
        })

    },
    deleteToken({commit}, tokenToDelete) {
        return deleteToken(tokenToDelete).then(() => {
            commit('tokens_delete_success', tokenToDelete)
        }).catch(error => {
            this.commit('showNotification', {content: error.message, color: 'error'})
        })
    },
}
const getters = {
    tokens: state => state.tokens,
    tokenStatus: state => state.status
}

export default {
    state,
    mutations,
    actions,
    getters
}
