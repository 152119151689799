import Vue from 'vue';
import Vuex from 'vuex';

import auth from './auth';
import notification from './notification';
import notifications from './notifications';
import projects from './projects';
import projectsPlanning from '@/modules/daiwa-house-modular-europe/ProjectsDashboard/DhmeProjectsDashboard.store';
import project from './project';
import sbs from './sbs';
import sbsOverview from '@/store/sbs-overview';
import tasks from './task';
import teams from './project-settings/teams';
import team from './project-settings/team';
import global from './global-helper';
import tables from './tables';
import tablesQuery from './tables-query';
import table from './table';
import tableSettings from './table-settings';
import record from './record';
import workflows from './modules/workflows.store';
import workflowManagement from './project-settings/workflow-management';
import workflowManager from './workflow-manager';
import workflowBuilder from '@/store/builder/workflow-builder';
import news from './news.store';
import blockBuilder from '@/store/builder/block-builder';
import tokens from '@/store/tokens';
import labels from '@/store/labels.store';
import boards from '@/store/boards.store';
import modules from '@/store/admin/modules';
import downloads from '@/store/downloads.store';
import buildOrder from '@/store/modules/build-order.store';
import forge from '@/store/modules/forge.store';
import fourd from '@/store/modules/fourd.store';
import modelManager from '@/store/modules/model-manager.store';
import pointCloud from '@/store/modules/pointcloud.store';
import mki from '@/store/modules/mki.store';
import links from '@/store/project-settings/links';
import relatics from '@/store/modules/relatics.store';
import gisViewer from '@/store/modules/gis-viewer.store';
import antTables from '@/store/modules/ant-tables.store';
import stakeHolderAnalysis from '@/store/modules/stakeholder-analysis.store';
import stakeHolders from '@/modules/stakeholders/stakeholders.store';
import fitGap from '@/modules/fit-gap/fit-gap.store';
import swotScenarios from '@/store/modules/swot-scenarios.store';
import tasksGantt from '@/modules/daiwa-house-modular-europe/ProjectGantt/DhmeProjectGantt.store';

import dhmeProjectPlanning from './modules/daiwa-house-modular-europe/dhme.project-planning.store';
import dhmeMD from './modules/daiwa-house-modular-europe/dhme.module-dashboard.store';
import dhmeWVB from './modules/daiwa-house-modular-europe/dhme.wvb.store';
import dhmeModuleAssembly from '../modules/daiwa-house-modular-europe/ModuleAssembly/DhmeModuleAssembly.store';
import dhmeDataValidation from './modules/daiwa-house-modular-europe/dhme.data-validation.store';
import dhmeBillOfMaterial from '../modules/daiwa-house-modular-europe/BillOfMaterial/DhmeBillOfMaterial.store';
import dhmeProjectAssembly from '../modules/daiwa-house-modular-europe/ProjectAssemblyPlanning/DhmeProjectAssemblyPlanning.store';
import dhmeWKBQualityControl from '../modules/daiwa-house-modular-europe/WkbQualityControl/DhmeWkbQualityControl.store';
import dhmeWKBInspectionPlan from '../modules/daiwa-house-modular-europe/WkbInspectionLibrary/DhmeWkbInspectionPlan.store';
import dhmeWKBLibrary from '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbLibrary.store';
import dhmeStationDashboard from '../modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboard.store';
import dhmeModelImport from '../modules/daiwa-house-modular-europe/ModelImport/DhmeModelImport.store';
import dhmeAssemblyPreparations from '../modules/daiwa-house-modular-europe/AssemblyPreparations/DhmeAssemblyPreperations.store';

import dhmeAssemblyPlanning from './modules/daiwa-house-modular-europe/master/dhme.assembly-planning.store';
import dhmeAssemblyHallDashboardStore from '../modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboard.store';
import dhmeTrestleCheckerStore from '@/store/modules/daiwa-house-modular-europe/master/dhme.trestle-checker.store';
import dhmePickOrderManagementStore from '@/store/modules/daiwa-house-modular-europe/master/dhme.pick-order-management.store';
import dhmeYardManagementStore from '@/store/modules/daiwa-house-modular-europe/master/dhme.yard-management.store';
import dhmeManualImportStore from '@/modules/daiwa-house-modular-europe/ManualImport/dhme.manual-import.store';
import dhmeWkbRiskAnalysisStore from '@/store/modules/daiwa-house-modular-europe/dhme.wkb.risk-analysis.store';

import dhmeProductionPlanningStore from '@/modules/daiwa-house-modular-europe/ProductionPlanning/DhmeProductionPlanning.store';

import acc from '@/store/acc';
import { CaslVuex } from '@/services/casl';
import dhmeAssemblyPlanningStore from '@/modules/daiwa-house-modular-europe/AssemblyPlanning/DhmeAssemblyPlanning.store';
import dhmeProjectIntakeStore from '@/modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntake.store';

// FIXME: Looks like legacy code, can we delete it?
// ant-launcher/src/modules/Esri.vue
// ant-launcher/src/components/Modules/Esri/EsriViewer.vue
// ant-launcher/src/components/Modules/Esri/EsriTable.vue
// ant-launcher/src/store/modules/esri.store.js
// import esri from '@/store/modules/esri.store';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [CaslVuex],
  modules: {
    auth,
    notification,
    notifications,
    projects,
    project,
    sbs,
    sbsOverview,
    tasks,
    teams,
    team,
    global,
    tables,
    tablesQuery,
    table,
    tableSettings,
    record,
    link: links,
    labels,
    boards,
    modules,
    news,

    // workflows
    workflows,
    workflowManagement,
    workflowManager,
    workflowBuilder,
    blockBuilder,

    // modules
    buildOrder,
    // esri,
    forge,
    fourd,
    modelManager,
    pointCloud,
    projectsPlanning,
    mki,
    relatics,
    gisViewer,
    antTables,
    stakeHolderAnalysis,
    stakeHolders,
    swotScenarios,
    tasksGantt,
    fitGap,

    dhmeProjectPlanning,
    dhmeMD,
    dhmeWVB,
    dhmeModuleAssembly,
    dhmeDataValidation,
    dhmeBillOfMaterial,
    dhmeProjectAssembly,
    dhmeWKBQualityControl,
    dhmeWKBInspectionPlan,
    dhmeWKBLibrary,
    dhmeStationDashboard,
    dhmeModelImport,
    dhmeAssemblyPreparations,

    dhmeAssemblyPlanning,
    dhmeAssemblyHallDashboardStore,
    dhmeTrestleCheckerStore,
    dhmePickOrderManagementStore,
    dhmeYardManagementStore,
    dhmeManualImportStore,
    dhmeWkbRiskAnalysisStore,

    dhmeProductionPlanningStore,
    dhmeAssemblyPlanningStore,
    dhmeProjectIntakeStore,

    acc,
    tokens,
    downloads,
  },
});
