const WorkflowHelper = {
  calculateWorkflowFlow(nodes) {
    let flow = []
    // find start nodeId
    let startNode = nodes.find(node => {
      return node.workflow_start
    })

    if (startNode !== undefined) {
      startNode.column = 1;

      flow = this.getNextNodes(startNode, 1, nodes, flow)
    }

    return flow
  },

  getNextNodes(node, column, nodes, flow) {
    // check if node already exists
    if (flow.find(item => item.id === node.id) === undefined) {
      flow.push(node)
    } else {
      // update previous node
      let index = flow.findIndex(item => item.id === node.id)
      // check if column is higher
      if(flow[index].column < node.column) {
        flow[index] = Object.assign({}, node)
        flow = [...flow]
      }
    }

    if(node.links !== null) {
      // loop through each links
      node.links.forEach(link => {
        // find node
        let node = Object.assign({}, nodes.find(node => {
          return node.id === link.node
        }))

        // add column
        node.column = column + 1

        // get next nodes
        flow = [...this.getNextNodes(node, node.column, nodes, flow)]
      })
    }
    return flow
  },
  calculateBuilderFlow(nodes, links) {
    let flow = []
    // find start nodeId
    let startNode = nodes.find(node => {
      return node.type === 'start'
    })

    if (startNode !== undefined) {
      startNode.column = 1;

      flow = this.getBuilderNextNodes(startNode, 1, links, nodes, flow)
    }

    return flow
  },

  getBuilderNextNodes(node, column, links, nodes, flow) {
    // check if node already exists
    if (flow.find(item => item.id === node.id) === undefined) {
      flow.push(node)
    } else {
      // update previous node
      let index = flow.findIndex(item => item.id === node.id)
      // check if column is higher
      if(flow[index].column < node.column) {
        flow[index] = Object.assign({}, node)
        flow = [...flow]
      }
    }

    // get next links
    let nextLinks = links.filter(link => {
      return link.previous_node === node.id
    })

    // loop through each links
    nextLinks.forEach(link => {
      // find node
      let node = Object.assign({} ,nodes.find(node => {
        return node.id === link.node
      }))

      // add column
      node.column = column + 1

      // get next nodes
      flow = [...this.getBuilderNextNodes(node, node.column, links, nodes, flow)]
    })
    return flow
  }
}

export default WorkflowHelper
