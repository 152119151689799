import httpClient from "../httpClient";

const executeAutomation = (automationId, body) => {
    return new Promise((resolve, reject) => {
        httpClient.post(`/automations/${automationId}/execute`, body)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
    })
}

export {
    executeAutomation,
}
