export function waitForVuex(waiter, func, forceSync = false) {
  if (typeof func !== 'function') {
    console.warn('[vue-wait] waitFor second argument must be a function');
    return func;
  }

  if (forceSync === true) {
    return function (...args) {
      const context = args[0];
      let key = waiter;
      if (typeof waiter === 'function') {
        key = waiter.apply(this, args);
      }
      try {
        context.dispatch('wait/start', key, { root: true });
        return func.apply(this, args);
      } finally {
        context.dispatch('wait/end', key, { root: true });
      }
    };
  }
  return async function (...args) {
    const context = args[0];
    let key = waiter;
    if (typeof waiter === 'function') {
      key = await waiter.apply(this, args);
    }
    try {
      context.dispatch('wait/start', key, { root: true });
      return await func.apply(this, args);
    } finally {
      context.dispatch('wait/end', key, { root: true });
    }
  };
}
