import httpClient from '../httpClient';

const getBoards = () => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/boards`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getBoard = (boardId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/boards/${boardId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createBoard = (body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/boards`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateBoard = (boardId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/boards/${boardId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteBoard = (boardId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/boards/${boardId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createBoardLane = (boardId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/boards/${boardId}/lanes`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateBoardLane = (boardId, laneId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/boards/${boardId}/lanes/${laneId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteBoardLane = (boardId, laneId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/boards/${boardId}/lanes/${laneId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getBoards,
  createBoard,
  updateBoard,
  deleteBoard,
  getBoard,
  createBoardLane,
  updateBoardLane,
  deleteBoardLane,
};
