import axios from 'axios';

const AutodeskService = {
  /**
   * Login into Autodesk Forge
   * @returns Metadata of URN
   * @param clientId
   * @param clientSecret
   * @param server_region
   */ async fetchToken(clientId, clientSecret, server_region) {
    this.region = server_region;
    const authHeader = `Basic ${Buffer.from(
      `${clientId}:${clientSecret}`
    ).toString('base64')}`;
    const url = 'https://developer.api.autodesk.com/authentication/v2/token';

    const data = {
      grant_type: 'client_credentials',
      scope:
        'data:read data:write data:create bucket:read bucket:create account:read',
    };

    try {
      const response = await axios({
        method: 'post',
        url,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
          Authorization: authHeader,
        },
        data: new URLSearchParams(data).toString(),
      });

      return response.data.access_token;
    } catch (error) {
      this.$store.commit('showNotification', {
        content: `Autodesk: ${error.message}`,
        color: 'error',
      });
    }
  },
  /**
   * Get Metadata of Model
   * @param {string} urn Autodesk model URN
   * @param {string} token Forge access forgeAccessToken
   * @param region
   * @returns Metadata of URN
   */
  getUrnMetadata(urn, token, region = 'US') {
    let url = '';
    if (region === 'EMEA') {
      url = `https://developer.api.autodesk.com/modelderivative/v2/regions/eu/designdata/${urn}/metadata`;
    } else {
      url = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/metadata`;
    }
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(url, config)
        .then((response) => {
          resolve(response.data.data.metadata);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Get Metadata of view in Model
   * @param {string} urn Autodesk model URN
   * @param {string} guid GUID of view
   * @param {string} token Forge access forgeAccessToken
   * @returns Metadata if view
   */
  getGuidMetadata(urn, guid, token) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/metadata/${guid}?forceget=true`,
          config
        )
        .then((response) => {
          resolve(response.data.data.objects);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * get properties of Modelview
   * @param {string} urn Autodesk model URN
   * @param {string} guid GUID of view
   * @param {string} token Forge access forgeAccessToken
   * @param region Autodesk server region
   * @returns Properties of view
   */
  getGuidProperties(urn, guid, token, region = 'US') {
    let url = '';
    if (region === 'EMEA') {
      url = `https://developer.api.autodesk.com/modelderivative/v2/regions/eu/designdata/${urn}/metadata/${guid}/properties`;
    } else {
      url = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/metadata/${guid}/properties`;
    }
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(`${url}?forceget=true`, config)
        .then((response) => {
          resolve(response.data.data.collection);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * get properties of Modelview
   * @param {string} urn Autodesk model URN
   * @param {string} guid GUID of view
   * @param {string} token Forge access forgeAccessToken
   * @param region Autodesk server region
   * @returns Properties of view
   */
  getGuidTree(urn, guid, token, region = 'US') {
    let url = '';
    if (region === 'EMEA') {
      url = `https://developer.api.autodesk.com/modelderivative/v2/regions/eu/designdata/${urn}/metadata/${guid}`;
    } else {
      url = `https://developer.api.autodesk.com/modelderivative/v2/designdata/${urn}/metadata/${guid}`;
    }
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(`${url}?forceget=true`, config)
        .then((response) => {
          resolve(response.data.data.objects);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * get Buckets of app
   * @param {string} token Forge access forgeAccessToken
   * @param {string} region Forge access forgeAccessToken
   * @returns List of buckets
   */
  getBuckets(token, region) {
    return new Promise((resolve, reject) => {
      let server = region === 'EMEA' ? '?region=EMEA' : '';
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `https://developer.api.autodesk.com/oss/v2/buckets` + server,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Get files from buckets
   * @param {string} bucket name of bucket
   * @param {string} token Forge access forgeAccessToken
   * @returns List of files in bucket
   */
  getFilesInBucket(bucket, token) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `https://developer.api.autodesk.com/oss/v2/buckets/${bucket}/objects?limit=100`,
          config
        )
        .then((response) => {
          resolve(response.data.items);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Create bucket
   * @param {string} token Forge access forgeAccessToken
   * @param {string} bucket name of bucket
   * @param {string} region server region EMEA | US
   * @returns The created bucket
   */
  createBucket(token, bucket, region) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'x-ads-region': region,
        },
      };
      axios
        .post(
          `https://developer.api.autodesk.com/oss/v2/buckets`,
          bucket,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Upload a model to Forge
   * @param {string} bucket name of bucket
   * @param {string} file the file to be uploaded
   * @param {string} token Forge access forgeAccessToken
   * @returns conformation of a succesfull upload
   */
  uploadForgeFile(bucket, file, token) {
    let rootFileName = file.name;
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'text/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .put(
          `https://developer.api.autodesk.com/oss/v2/buckets/${bucket}/objects/${rootFileName}`,
          new Blob([file]),
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Convert a model to .svf
   * @param {string} urn name of bucket
   * @param {string} token Forge access forgeAccessToken
   * @param region
   * @returns Conformation that the model will be converted
   */
  convertModel(urn, token, region) {
    return new Promise((resolve, reject) => {
      let server = region === 'EMEA' ? 'regions/eu/' : '';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        input: {
          urn: urn,
        },
        output: {
          formats: [
            {
              type: 'svf',
              views: ['3D', '2D'],
            },
          ],
        },
      };
      axios
        .post(
          `https://developer.api.autodesk.com/modelderivative/v2/${server}designdata/job`,
          body,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchUrnThumbnail(urn, token, region) {
    return new Promise((resolve, reject) => {
      let server = region === 'EMEA' ? 'regions/eu/' : '';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `https://developer.api.autodesk.com/modelderivative/v2/${server}designdata/${urn}/thumbnail`,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  extractGeometry(urn, token, region, objectIds, modelGuid) {
    return new Promise((resolve, reject) => {
      let server = region === 'EMEA' ? 'regions/eu/' : '';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      const body = {
        input: {
          urn: urn,
        },
        output: {
          formats: [
            {
              type: 'obj',
              advanced: {
                modelGuid: modelGuid,
                objectIds: objectIds,
              },
            },
          ],
        },
      };
      axios
        .post(
          `https://developer.api.autodesk.com/modelderivative/v2/${server}designdata/job`,
          body,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getUrnManifest(urn, token, region) {
    return new Promise((resolve, reject) => {
      let server = region === 'EMEA' ? 'regions/eu/' : '';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `https://developer.api.autodesk.com/modelderivative/v2/${server}designdata/${urn}/manifest`,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteObjectFromBucket(bucketKey, objectKey, token) {
    return new Promise((resolve, reject) => {
      const config = {
        headers: {
          'Content-Type': 'text/octet-stream',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .delete(
          `https://developer.api.autodesk.com/oss/v2/buckets/${bucketKey}/objects/${objectKey}`,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Get all properties of model
   * @param {string} urn name of bucket
   * @param {string} token Forge access forgeAccessToken
   * @param region autodesk server region
   * @returns Properties of 3D model
   */
  getModelProperties(urn, token, region) {
    return this.getUrnMetadata(urn, token, region)
      .then((data) => data.find((e) => e.role === '3d')?.guid)
      .then((guid) => this.getGuidProperties(urn, guid, token, region));
  },
  getModelTree(urn, token, region) {
    return this.getUrnMetadata(urn, token, region)
      .then((data) => data.find((e) => e.role === '3d')?.guid)
      .then((guid) => this.getGuidTree(urn, guid, token, region));
  },

  downloadObjExtraction(urn, token, region, objUrn) {
    return new Promise((resolve, reject) => {
      let server = region === 'EMEA' ? 'regions/eu/' : '';
      const config = {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      };
      axios
        .get(
          `https://developer.api.autodesk.com/modelderivative/v2/${server}designdata/${urn}/manifest/${objUrn}`,
          config
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default AutodeskService;
