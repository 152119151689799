import {
    createExternalLink,
    deleteExternalLink,
    getExternalLinks,
    updateExternalLink
} from "@/services/api/external-links.api";

const state = {
    links: [],
    linksStatus: '',
}
const mutations = {
    links_request(state) {
        state.linksStatus = 'loading'
    },
    links_success(state, links) {
        state.links = links
        state.linksStatus = 'success'
    },

    links_create_success(state, link) {
        state.links.push(link)
        state.linksStatus = 'success'
    },

    links_update_success(state, updatedLink) {
        Object.assign(state.links.find(link => link.id === updatedLink.id), updatedLink)
        state.linksStatus = 'success'
    },

    links_delete_success(state, deletedLinkId) {
        state.links = [
            ...state.links.filter(link => link.id !== deletedLinkId)
        ]
        state.linksStatus = 'success'
    },
}
const actions = {
    fetchExternalLinks({commit, getters}) {
        commit('links_request')
        return getExternalLinks(getters.project.id)
            .then(links => {
                commit('links_success', links)
            })
            .catch(error => {
                commit('links_error', error)
            })
    },

    createExternalLink({commit, getters}, {body}) {
        commit('links_request')
        return createExternalLink(getters.project.id, body)
            .then(response => {
                commit('links_create_success', response)
                this.commit('showNotification', {
                    content: `Successfully created link ${response.name}`,
                    color: 'success'
                })
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },

    updateExternalLink({commit, getters}, {linkId, body}) {
        commit('links_request')
        return updateExternalLink(getters.project.id,linkId, body)
            .then(response => {
                commit('links_update_success', response)
                this.commit('showNotification', {
                    content: `Successfully updated link ${response.name}`,
                    color: 'success'
                })
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },
    deleteExternalLink({commit, getters}, {linkId}) {
        commit('links_request')
        return deleteExternalLink(getters.project.id, linkId)
            .then(response => {
                commit('links_delete_success', linkId)
                this.commit('showNotification', {content: `Successfully deleted link`, color: 'success'})
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },
}
const getters = {
    links: state => state.links,
    linksStatus: state => state.linksStatus
}

export default {
    state,
    mutations,
    actions,
    getters
}
