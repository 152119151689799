import httpClient from '../httpClient';

const getRevisions = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/revisions`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getRevision = (revisionId, projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/revision/${revisionId}`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createRevision = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/revision`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateRevision = (revisionId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/revision/${revisionId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteRevision = (revisionId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/revision/${revisionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const restoreRevision = (revisionId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/revision/${revisionId}/restore`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  getRevisions,
  getRevision,
  createRevision,
  updateRevision,
  deleteRevision,
  restoreRevision,
};
