import httpClient from '../httpClient';

const getTableQuery = (projectId, tableId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`project/${projectId}/table/${tableId}/query`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getTableQueryGraph = ({ projectId, body }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`project/${projectId}/tables/query`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const runTableQueryGraphExport = ({ projectId, body }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`project/${projectId}/tables/query/export`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getTableQuery, getTableQueryGraph, runTableQueryGraphExport };
