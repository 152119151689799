import LocalStorageService from '@/services/local-storage';

const state = {
  notifications: [],
};

const unique = (key) => (value, index, self) => {
  return self.findIndex((o) => o[key] === value[key]) === index;
};
const mutations = {
  notifications_success(state, payload) {
    state.notifications = payload;
    LocalStorageService.setNotifications(JSON.stringify(state.notifications));
  },
  push_notifications(state, notification) {
    // To avoid duplicates in two tabs we need to filter data in LocalStorage
    let n = JSON.parse(LocalStorageService.getNotifications()) || [];
    if (Array.isArray(n)) {
      n.push(notification);
    }
    state.notifications = n.filter(unique('id'));
    LocalStorageService.setNotifications(JSON.stringify(state.notifications));
  },
};

const actions = {
  setNotifications({ state, commit }, payload) {
    commit('notifications_success', payload);
  },
  clearNotifications({ state, commit }) {
    commit('notifications_success', []);
  },
  pushNotification({ state, commit }, notification) {
    commit('push_notifications', notification);
  },
  markAsRead({ state, commit }, notification) {
    const notifications = state.notifications.map((n) => ({
      ...n,
      read_at: n.id === notification.id ? Date.now() : n.read_at,
    }));
    commit('notifications_success', notifications);
  },
  pushBroadcastNotification({ state, commit }, notification) {
    commit('push_notifications', {
      id: notification.id,
      data: notification,
      type: notification.type,
      read_at: null,
      created_at: Date.now(),
    });
  },
};

const getters = {
  notifications: (state) => state.notifications,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
