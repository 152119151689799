<template>
  <div class="loading-icon">
    {{ animationLetter }}
  </div>
</template>

<script>
export default {
  name: "AntLoading",
  data: () => {
    return {
      animationLetter: 'A'
    }
  },
  mounted() {
    setInterval(() => {
      switch (this.animationLetter) {
        case 'A': this.animationLetter = 'N';break;
        case 'N': this.animationLetter = 'T';break;
        case 'T': this.animationLetter = 'A';break;
      }
    }, 2000)
  }
}
</script>

<style scoped lang="scss">
.loading-icon {
  font-size: 40px;
  font-weight: bold;
  color: var(--v-primary-base);
  animation: example;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
}

@keyframes example {
  0% {
    color: grey;
    transform: scale(0) rotateZ(720deg);
    filter: blur(5px);
  }
  40% {
    transform: scale(0.8) rotateZ(0);
    filter: blur(0);
  }
  70% {
    color: var(--v-primary-base);
    transform: scale(1);
  }
  100% {
    color: grey;
    transform: scale(0);
  }
}

</style>
