import { waitForVuex } from '@/utils/vuex';
import { getSBSSessions } from '@/services/api/project-workflow-sessions.api';
import { getTablesBySBS } from '@/services/api/table.api';
import { recordsSearch } from '@/services/api/record.api';

const state = {
  sbsSessions: [],
  sbsTables: [],
  sbsTableRecords: {},
};

const mutations = {
  reset_sbs_state(state) {
    state.sbsSessions = []
    state.sbsTables = []
    state.sbsTableRecords = {}
  },
  project_sbs_sessions_success(state, data) {
    state.sbsSessions = data;
  },
  sbs_tables_success(state, tables) {
    state.sbsTables = tables;
  },
  sbs_records_success(state, { key, records }) {
    state.sbsTableRecords[key] = records;
  },
};

const actions = {
  fetchProjectSBSSessions: waitForVuex(
    'project.sbs.sessions',
    async ({ commit }, { projectId, code }) => {
      try {
        const sessions = await getSBSSessions(projectId, code);
        commit('project_sbs_sessions_success', sessions);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),

  fetchProjectSBSTables: waitForVuex(
    'project.sbs.tables',
    async ({ commit }, { projectId, code }) => {
      try {
        const tables = await getTablesBySBS(projectId, code);
        if (tables) {
          commit('sbs_tables_success', tables);
        }
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),

  searchSBSTableRecords: waitForVuex(
    (_, { table }) => `table.sbs.records.search.${table?.id}`,
    async ({ commit }, body) => {
      const key = body?.table?.id;
      try {
        const { records } = await recordsSearch(body);
        commit('sbs_records_success', { key, records });
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }
    }
  ),
};

const getters = {
  sbsSessions: (state) => state.sbsSessions,
  sbsTables: (state) => state.sbsTables,
  getSbsTableRecords: (state) => (id) => state.sbsTableRecords[id] || [],
};

export default {
  state,
  mutations,
  actions,
  getters,
};
