import { httpClientV2 } from '@/services/httpClient';
import { unwrapV2 } from '@/services/api/v2/utils';

export const fetchProjectImageV2 = (projectId) => {
  return httpClientV2.get(`projects/${projectId}/image`, {
    responseType: 'arraybuffer',
  });
};

export const fetchProjectBySlugV2 = (slug) =>
  unwrapV2((axios) => axios.get(`projects/slug/${slug}/find`));

export const fetchProjectV2 = (projectId) =>
  unwrapV2((axios) => axios.get(`projects/${projectId}`));

export const fetchProjectsV2 = (licenseId) =>
  unwrapV2((axios) => axios.get(`licenses/${licenseId}/projects`));

export const createProjectV2 = (data) =>
  unwrapV2((axios) => axios.post(`projects`, data));

export const updateProjectV2 = (projectId, data) =>
  unwrapV2((axios) => axios.put(`projects/${projectId}`, data));
