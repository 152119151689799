import {getModuleData} from "@/services/api/module.api";

const state = {
    moduleData: undefined,
    moduleStatus: '',
}
const mutations = {
    module_data_validation_request(state) {
        state.moduleStatus = 'loading'
    },
    module_data_validation_success(state, data) {
        state.moduleData = data
        let models = state.moduleData.CFFA_FORGE_MODELS.records;
        if (models.length > 0) {
            models[0].enabled = true
        }
        state.moduleStatus = 'success'
    },
}
const actions = {
    fetchDhmeDataValidation({commit}, {projectId, moduleId, sessionId}) {
        return new Promise((resolve, reject) => {
            commit('module_data_validation_request')
            getModuleData(projectId, moduleId, sessionId)
                .then(data => {
                    commit('module_data_validation_success', data)
                    resolve(data)
                })
                .catch(error => {
                    this.commit('showNotification', {content: error.message, color: 'error'})
                    reject(error)
                })
        })
    },
}
const getters = {
    dhmeDataValidationData: state => state.moduleData,
    dhmeDataValidationObjects: state => state.moduleData.CFFA_DHME_OBJECTS.records,
    dhmeDataValidationObjectsTable: state => state.moduleData.CFFA_DHME_OBJECTS.id,
    dhmeDataValidationClient: state => state.moduleData.CFFA_FORGE_CLIENT.records[0],
    dhmeDataValidationModels: state => state.moduleData.CFFA_FORGE_MODELS.records,
    dhmeDataValidationModelMapping: state => state.moduleData.CFFA_DHME_MODEL_MAPPING.records[0],
    dhmeDataValidationDataStatus: state => state.moduleStatus,
}

export default {
    state,
    mutations,
    actions,
    getters
}
