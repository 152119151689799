import Vue from 'vue';
import VueRouter from 'vue-router';
import LocalStorageService from '../services/local-storage';
import Home from '../views/Home';
import Overview from '@/components/Project/Overview';
import { ability } from '@/services/casl';

import {
  BUILD_ORDER,
  DHME_ASSEMBLY_PREPARATIONS,
  DHME_BILL_OF_MATERIAL,
  DHME_DATA_VALIDATION,
  DHME_DRAWING_BOARD,
  DHME_ELEMENT_VALIDATION,
  DHME_HALL_CONFIGURATOR,
  DHME_M_ASSEMBLY_HALL_DASHBOARD,
  DHME_M_ASSEMBLY_PLANNING,
  DHME_M_MODULE_DASHBOARD,
  DHME_M_PICK_ORDER_MANAGEMENT,
  DHME_M_STATION_DASHBOARD,
  DHME_M_TRESTLE_CHECKER,
  DHME_M_YARD_MANAGEMENT,
  DHME_MANUAL_IMPORT,
  DHME_MODEL_IMPORT,
  DHME_MODULE_ASSEMBLY,
  DHME_MODULE_DASHBOARD,
  DHME_PHASE_TRANSFER,
  DHME_PRODUCTION_PLANNING,
  DHME_PROJECT_ASSEMBLY_PLANNING,
  DHME_PROJECT_GANTT,
  DHME_PROJECT_PHASES_PLANNING,
  DHME_PROJECTS_DASHBOARD,
  DHME_QR2FORM,
  DHME_QUALITY_CONTROL,
  DHME_WKB_INSPECTION_PLAN,
  DHME_WKB_LIBRARY,
  DHME_WKB_RISK_ANALYSIS,
  DHME_WVB,
  FIT_GAP,
  FORGE,
  FOURD,
  GISVIEWER,
  MKI_IMPACT,
  MODEL_MANAGER,
  RELATICS,
  REUSE_POTENTIAL_INDEX,
  STAKEHOLDER_ANALYSIS,
  STAKEHOLDERS,
  STOCK_MANAGEMENT_LITE,
  SWOT_SCENARIOS,
} from '@/modules/modules';
import ResetPassword from '@/views/ResetPassword';
import { abilityMiddleware } from '@/router/middlewares/ability';
import appConfig from '@/appConfig';
import projectIntake from '@/router/projectIntake';

Vue.use(VueRouter);

const requireAuth = function (to, from, next) {
  if (!LocalStorageService.hasToken()) {
    next({ name: 'Login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
};

const routes = [
  {
    path: '/',
    beforeEnter: requireAuth,
    component: Home,
    name: 'Home',
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ '../views/Dashboard'),
        name: 'Dashboard',
      },
      {
        path: 'downloads',
        component: () =>
          import(/* webpackChunkName: "Downloads" */ '../views/Downloads.vue'),
        name: 'Downloads',
      },
      {
        path: 'knowledge-base',
        component: () =>
          import(
            /* webpackChunkName: "knowledge-base" */ '../views/KnowledgeBase.vue'
          ),
        name: 'KnowledgeBase',
        children: [
          {
            path: ':itemId',
            component: () =>
              import(
                /* webpackChunkName: "knowledge-base" */ '../views/KnowledgeBaseItem.vue'
              ),
            name: 'KnowledgeBaseItem',
          },
        ],
      },
      {
        path: 'tasks',
        component: () =>
          import(/* webpackChunkName: "tasks" */ '../views/Tasks'),
        name: 'tasks',
        children: [
          {
            path: 'gantt',
            name: 'tasks-gantt',
            component: () =>
              import(
                /* webpackChunkName: "tasks" */ '../components/Tasks/Views/TasksGantt.vue'
              ),
          },
          {
            path: 'list',
            name: 'tasks-list',
            component: () =>
              import(
                /* webpackChunkName: "tasks" */ '../components/Tasks/Views/TasksListView'
              ),
          },
          {
            path: 'board',
            name: 'tasks-board',
            component: () =>
              import(
                /* webpackChunkName: "tasks" */ '../components/Tasks/Views/TasksBoardView'
              ),
          },
          {
            path: 'calendar',
            name: 'tasks-calendar',
            component: () =>
              import(
                /* webpackChunkName: "tasks" */ '../components/Tasks/Views/TasksCalendarView'
              ),
          },
          {
            path: 'projects',
            name: 'tasks-projects',
            component: () =>
              import(
                /* webpackChunkName: "tasks" */ '../components/Tasks/Views/TasksProjectsView'
              ),
          },
          {
            path: ':taskId',
            name: 'tasks-detail',
            component: () =>
              import(
                /* webpackChunkName: "tasks" */ '../components/Tasks/Views/TasksDetail'
              ),
          },
        ],
      },
      {
        path: 'settings',
        component: () =>
          import(/* webpackChunkName: "Settings" */ '../views/Settings'),
        name: 'Settings',
      },
      {
        path: 'build',
        component: () =>
          import(/* webpackChunkName: "Build" */ '../views/Build'),
        name: 'Build',
      },
      {
        path: 'workflow-management',
        component: () =>
          import(
            /* webpackChunkName: "workflow-management" */ '../views/WorkflowManagement'
          ),
        name: 'WorkflowManagement',
        redirect: { name: 'workflow-manager' },
        children: [
          {
            path: '',
            name: 'workflow-manager',
            component: () =>
              import(
                /* webpackChunkName: "workflow-management" */ '../components/WorkflowManagement/WorkflowManager'
              ),
          },
          {
            path: 'builder/:workflowId',
            name: 'workflow-builder',
            component: () =>
              import(
                /* webpackChunkName: "workflow-management" */ '../components/WorkflowManagement/Builder/WorkflowBuilder'
              ),
          },
          {
            path: 'builder/:workflowId/:blockId',
            name: 'block-builder',
            component: () =>
              import(
                /* webpackChunkName: "workflow-management" */ '../components/WorkflowManagement/Builder/BlockBuilder'
              ),
          },
        ],
      },
      {
        path: 'admin',
        component: () =>
          import(/* webpackChunkName: "Admin" */ '../views/Admin'),
        name: 'Admin',
        beforeEnter: abilityMiddleware('access', 'admin'),
        children: [
          {
            path: 'companies',
            name: 'admin-companies',
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ '../components/Admin/ACompanies'
              ),
            beforeEnter: abilityMiddleware('access', 'admin-account-companies'),
          },
          {
            path: 'projects',
            name: 'admin-projects',
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ '../components/Admin/AProjects'
              ),
          },
          {
            path: 'users',
            name: 'admin-users',
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ '../components/Admin/AUsers'
              ),
          },
          {
            path: 'collections',
            name: 'admin-collections',
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ '../components/Admin/ACollections'
              ),
          },
          {
            path: 'labels',
            name: 'admin-labels',
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ '../components/Admin/AdminLabels'
              ),
          },
          {
            path: 'modules',
            name: 'admin-modules',
            component: () =>
              import(
                /* webpackChunkName: "Admin" */ '../components/Admin/AModules.vue'
              ),
            beforeEnter: (to, from, next) => {
              if (ability.can('access', 'admin-license-modules')) {
                next();
              } else {
                next(false);
              }
            },
          },
        ],
      },
      {
        path: 'project/:slug',
        component: () =>
          import(
            /* webpackChunkName: "Project" */ /* webpackPrefetch: true */ '../views/Project'
          ),
        name: 'Project',
        redirect: { name: 'project-overview' },
        children: [
          {
            path: '',
            name: 'project-overview',
            component: Overview,
          },
          {
            path: 'settings/teams',
            name: 'project-settings',
            component: () =>
              import(
                /* webpackChunkName: "Project-Admin" */ '../components/Project/Settings/ProjectSettings'
              ),
          },
          {
            path: 'labels',
            name: 'labels-management',
            component: () =>
              import(
                /* webpackChunkName: "project-labels" */ '../components/Project/ProjectLabels'
              ),
          },
          {
            path: 'sbs',
            redirect: { name: 'sbs-overview' },
            component: () =>
              import(
                /* webpackChunkName: "sbs-management" */ '../components/Project/SBS/SbsHome'
              ),
            children: [
              {
                path: 'overview',
                name: 'sbs-overview',
                component: () =>
                  import(
                    /* webpackChunkName: "sbs-management" */ '../components/Project/SBS/SbsOverview'
                  ),
              },
              {
                path: 'management',
                name: 'sbs-management',
                component: () =>
                  import(
                    /* webpackChunkName: "sbs-management" */ '../components/Project/SBS/SbsManagement'
                  ),
                beforeEnter: abilityMiddleware('access', 'sbs-management'),
              },
            ],
          },
          {
            path: 'workflows',
            name: 'workflows',
            redirect: { name: 'project-workflows' },
            component: () =>
              import(
                /* webpackChunkName: "workflows" */ '../components/Project/Workflows/Workflows'
              ),
            children: [
              {
                path: '',
                name: 'project-workflows',
                component: () =>
                  import(
                    /* webpackChunkName: "workflows" */ '../components/Project/Workflows/WorkflowOverview'
                  ),
              },
              {
                path: ':workflowId/sessions',
                name: 'project-workflow-sessions',
                component: () =>
                  import(
                    /* webpackChunkName: "workflows" */ '../components/Project/Workflows/WorkflowSessions'
                  ),
              },
              {
                path: ':workflowId/sessions/:sessionId',
                name: 'project-workflow-session',
                component: () =>
                  import(
                    /* webpackChunkName: "workflows" */ '../components/Project/Workflows/WorkflowSession'
                  ),
              },
              {
                path: ':workflowId/sessions/:sessionId/:nodeId',
                name: 'project-workflow-session-node',
                component: () =>
                  import(
                    /* webpackChunkName: "workflows" */ '../components/Project/Workflows/WorkflowNode'
                  ),
              },
              {
                path: ':workflowId/settings',
                name: 'project-workflow-settings',
                component: () =>
                  import(
                    /* webpackChunkName: "workflows" */ '../components/Project/Workflows/Management/WorkflowManagement'
                  ),
              },
            ],
          },
          {
            path: 'tables',
            name: 'tables',
            component: () =>
              import(
                /* webpackChunkName: "projectTables" */ '../components/Project/Tables/Tables'
              ),
            children: [
              {
                path: 'query',
                name: 'table-query-graph',
                component: () =>
                  import(
                    /* webpackChunkName: "projectTables" */ '../components/Project/Tables/TableQueryGraph'
                  ),
              },
              {
                path: ':tableId',
                name: 'table',
                component: () =>
                  import(
                    /* webpackChunkName: "projectTables" */ '../components/Project/Tables/TableView/TableView'
                  ),
              },
              {
                path: ':tableId/settings',
                name: 'table-settings',
                component: () =>
                  import(
                    /* webpackChunkName: "projectTables" */ '../components/Project/Tables/Settings/TableSettings'
                  ),
              },
              {
                path: ':tableId/revisions',
                name: 'table-revisions',
                component: () =>
                  import(
                    /* webpackChunkName: "projectTables" */ '../components/Project/Tables/TableRevisions'
                  ),
              },
              {
                path: ':tableId/query',
                name: 'table-query',
                component: () =>
                  import(
                    /* webpackChunkName: "projectTables" */ '../components/Project/Tables/TableQuery'
                  ),
              },
            ],
          },
          {
            path: `module/${STAKEHOLDERS}`,
            name: STAKEHOLDERS,
            component: () =>
              import(
                /* webpackChunkName: "stakeholders" */ '../modules/stakeholders/Stakeholders.vue'
              ),
          },
          {
            path: `module/${STAKEHOLDER_ANALYSIS}`,
            name: STAKEHOLDER_ANALYSIS,
            component: () =>
              import(
                /* webpackChunkName: "stakeholder-analysis" */ '../modules/StakeholderAnalysis.vue'
              ),
          },
          {
            path: `module/${SWOT_SCENARIOS}`,
            name: SWOT_SCENARIOS,
            component: () =>
              import(
                /* webpackChunkName: "stakeholder-analysis" */ '../modules/SwotScenarios.vue'
              ),
          },
          {
            path: `module/${FIT_GAP}`,
            name: FIT_GAP,
            component: () =>
              import(
                /* webpackChunkName: "stakeholder-analysis" */ '../modules/fit-gap/FitGap.vue'
              ),
          },
          {
            path: `module/${GISVIEWER}`,
            name: GISVIEWER,
            component: () =>
              import(
                /* webpackChunkName: "gis-viewer" */ '../modules/GisViewerModule.vue'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${FORGE}`,
            name: FORGE,
            component: () =>
              import(/* webpackChunkName: "forge" */ '../modules/Forge.vue'),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${FOURD}`,
            name: FOURD,
            component: () =>
              import(/* webpackChunkName: "fourD" */ '../modules/FourD.vue'),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${BUILD_ORDER}`,
            name: BUILD_ORDER,
            component: () =>
              import(
                /* webpackChunkName: "build-order" */ '../modules/BuildOrder'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${MODEL_MANAGER}`,
            name: MODEL_MANAGER,
            component: () =>
              import(
                /* webpackChunkName: "model-manager" */ '../modules/ModelManager'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${MKI_IMPACT}`,
            name: MKI_IMPACT,
            component: () =>
              import(/* webpackChunkName: "mki" */ '../modules/MKIImpact'),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${RELATICS}`,
            name: RELATICS,
            component: () =>
              import(/* webpackChunkName: "relatics" */ '../modules/Relatics'),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${DHME_PROJECTS_DASHBOARD}`,
            name: DHME_PROJECTS_DASHBOARD,
            component: () =>
              import(
                /* webpackChunkName: "projects-gantt-dhtmlx" */ '../modules/daiwa-house-modular-europe/ProjectsDashboard/DhmeProjectsDashboard.vue'
              ),
            meta: {
              globalToolbar: false,
            },
          },
          {
            path: `module/${DHME_PROJECT_GANTT}`,
            name: DHME_PROJECT_GANTT,
            component: () =>
              import(
                /* webpackChunkName: "dhtmlx-gantt" */ '../modules/daiwa-house-modular-europe/ProjectGantt/DhmeProjectGantt.vue'
              ),
            meta: {
              globalToolbar: false,
            },
          },
          {
            path: `module/${DHME_PROJECT_ASSEMBLY_PLANNING}`,
            name: DHME_PROJECT_ASSEMBLY_PLANNING,
            component: () =>
              import(
                /* webpackChunkName: "dhme-project-assembly-planning" */ '../modules/daiwa-house-modular-europe/ProjectAssemblyPlanning/DhmeProjectAssemblyPlanning.vue'
              ),
          },
          {
            path: `module/${DHME_WVB}`,
            name: DHME_WVB,
            component: () =>
              import(
                /* webpackChunkName: "dhme-wvb" */ '../modules/daiwa-house-modular-europe/DHME.WVB.vue'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${DHME_MODULE_DASHBOARD}`,
            name: DHME_MODULE_DASHBOARD,
            component: () =>
              import(
                /* webpackChunkName: "dhme-module-dashboard" */ '../modules/daiwa-house-modular-europe/DHME.ModuleDashboard'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${DHME_PROJECT_PHASES_PLANNING}`,
            name: DHME_PROJECT_PHASES_PLANNING,
            component: () =>
              import(
                /* webpackChunkName: "dhme-project-phases-planning" */ '../modules/daiwa-house-modular-europe/DHME.ProjectPhasePlanning'
              ),
          },
          {
            path: `module/${DHME_MODULE_ASSEMBLY}`,
            name: DHME_MODULE_ASSEMBLY,
            component: () =>
              import(
                /* webpackChunkName: "dhme-module-assembly" */ '../modules/daiwa-house-modular-europe/ModuleAssembly/DhmeModuleAssembly.vue'
              ),
          },
          {
            path: `module/${DHME_DATA_VALIDATION}`,
            name: DHME_DATA_VALIDATION,
            component: () =>
              import(
                /* webpackChunkName: "dhme-data-validation" */ '../modules/daiwa-house-modular-europe/DHME.DataValidation'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${DHME_MODEL_IMPORT}`,
            name: DHME_MODEL_IMPORT,
            component: () =>
              import(
                /* webpackChunkName: "dhme-model-import" */ '../modules/daiwa-house-modular-europe/ModelImport/DhmeModelImport.vue'
              ),
          },
          {
            path: `module/${DHME_MANUAL_IMPORT}`,
            name: DHME_MANUAL_IMPORT,
            component: () =>
              import(
                /* webpackChunkName: "dhme-manual-import" */ '../modules/daiwa-house-modular-europe/ManualImport/DhmeManualImport.vue'
              ),
            children: [
              {
                path: `modules`,
                name: `${DHME_MANUAL_IMPORT}-modules`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-manual-import" */ '../modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportModules.vue'
                  ),
              },
              {
                path: `module-contents`,
                name: `${DHME_MANUAL_IMPORT}-module-contents`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-manual-import" */ '../modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportModuleContents.vue'
                  ),
              },
              {
                path: `element-contents`,
                name: `${DHME_MANUAL_IMPORT}-element-contents`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-manual-import" */ '../modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportElementContents.vue'
                  ),
              },
              {
                path: `objects`,
                name: `${DHME_MANUAL_IMPORT}-objects`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-manual-import" */ '../modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportObjects.vue'
                  ),
              },
              {
                path: `drawings`,
                name: `${DHME_MANUAL_IMPORT}-drawings`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-manual-import" */ '../modules/daiwa-house-modular-europe/ManualImport/DhmeManualImportDrawings.vue'
                  ),
              },
            ],
          },
          {
            path: `module/${DHME_DRAWING_BOARD}`,
            name: DHME_DRAWING_BOARD,
            component: () =>
              import(
                /* webpackChunkName: "dhme-drawing-board" */ '../modules/daiwa-house-modular-europe/DrawingBoard/DrawingBoard.vue'
              ),
          },
          {
            path: `module/${DHME_BILL_OF_MATERIAL}`,
            name: DHME_BILL_OF_MATERIAL,
            component: () =>
              import(
                /* webpackChunkName: "dhme-bom" */ '../modules/daiwa-house-modular-europe/BillOfMaterial/DhmeBillOfMaterial.vue'
              ),
          },
          {
            path: `module/${DHME_QUALITY_CONTROL}`,
            name: DHME_QUALITY_CONTROL,
            component: () =>
              import(
                /* webpackChunkName: "dhme-qa" */ '../modules/daiwa-house-modular-europe/WkbQualityControl/DhmeWkbQualityControl.vue'
              ),
          },
          {
            path: `module/${DHME_WKB_RISK_ANALYSIS}`,
            name: DHME_WKB_RISK_ANALYSIS,
            component: () =>
              import(
                /* webpackChunkName: "dhme-wkb-risk-analysis" */ '../modules/daiwa-house-modular-europe/DHME.WKB.RiskAnalysis.vue'
              ),
          },
          {
            path: `module/${DHME_WKB_INSPECTION_PLAN}`,
            name: DHME_WKB_INSPECTION_PLAN,
            component: () =>
              import(
                /* webpackChunkName: "dhme-wkb-ip" */ '../modules/daiwa-house-modular-europe/WkbInspectionLibrary/DhmeWkbInspectionplan.vue'
              ),
            children: [
              {
                path: `overview`,
                name: `${DHME_WKB_INSPECTION_PLAN}-overview`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-ip" */ '../modules/daiwa-house-modular-europe/WkbInspectionLibrary/DhmeWkbInspectionPlanOverview.vue'
                  ),
              },
              {
                path: `planning`,
                name: `${DHME_WKB_INSPECTION_PLAN}-planning`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-ip" */ '../modules/daiwa-house-modular-europe/WkbInspectionLibrary/DhmeWkbInspectionPlanPlanning.vue'
                  ),
              },
            ],
          },
          {
            path: `module/${DHME_WKB_LIBRARY}`,
            name: DHME_WKB_LIBRARY,
            component: () =>
              import(
                /* webpackChunkName: "dhme-wkb-library" */ '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbLibrary.vue'
              ),
            children: [
              {
                path: 'risks',
                name: `${DHME_WKB_LIBRARY}-risks`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-library" */ '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbRisksLibrary.vue'
                  ),
              },
              {
                path: 'measurements',
                name: `${DHME_WKB_LIBRARY}-measurements`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-library" */ '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbMeasurementsLibrary.vue'
                  ),
              },
              {
                path: 'forms',
                name: `${DHME_WKB_LIBRARY}-forms`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-library" */ '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbFormsLibrary.vue'
                  ),
              },
              {
                path: 'documents',
                name: `${DHME_WKB_LIBRARY}-documents`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-library" */ '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbDocumentsLibrary.vue'
                  ),
              },
              {
                path: 'tasks',
                name: `${DHME_WKB_LIBRARY}-tasks`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-wkb-library" */ '../modules/daiwa-house-modular-europe/WkbLibrary/DhmeWkbTasksLibrary.vue'
                  ),
              },
            ],
          },
          {
            path: `module/${DHME_M_STATION_DASHBOARD}`,
            name: DHME_M_STATION_DASHBOARD,
            component: () =>
              import(
                /* webpackChunkName: "dhme-m-station-dashboard" */ '../modules/daiwa-house-modular-europe/StationDashboard/DhmeStationDashboard.vue'
              ),
          },
          {
            path: `module/${DHME_M_ASSEMBLY_PLANNING}`,
            name: DHME_M_ASSEMBLY_PLANNING,
            component: () =>
              import(
                /* webpackChunkName: "dhme-m-assembly-planning" */ '../modules/daiwa-house-modular-europe/AssemblyPlanning/DhmeAssemblyPlanning.vue'
              ),
          },
          {
            path: `module/${DHME_M_ASSEMBLY_HALL_DASHBOARD}`,
            name: DHME_M_ASSEMBLY_HALL_DASHBOARD,
            component: () =>
              import(
                /* webpackChunkName: "dhme-m-assembly-hall-dashboard" */ '../modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboard.vue'
              ),
            children: [
              {
                path: ':hall/trestles',
                name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-trestles`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-m-assembly-hall-dashboard" */ '../modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardTrestles.vue'
                  ),
              },
              {
                path: ':hall/planning',
                name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-planning`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-m-assembly-hall-dashboard" */ '../modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardPlanning.vue'
                  ),
              },
              {
                path: ':hall/mes',
                name: `${DHME_M_ASSEMBLY_HALL_DASHBOARD}-mes`,
                component: () =>
                  import(
                    /* webpackChunkName: "dhme-m-assembly-hall-dashboard" */ '../modules/daiwa-house-modular-europe/AssemblyHallDashboard/DhmeAssemblyHallDashboardMes.vue'
                  ),
              },
            ],
          },
          {
            path: `module/${DHME_PRODUCTION_PLANNING}`,
            name: DHME_PRODUCTION_PLANNING,
            component: () =>
              import(
                /* webpackChunkName: "dhme-production-planning" */ '../modules/daiwa-house-modular-europe/ProductionPlanning/DhmeProductionPlanning.vue'
              ),
          },
          {
            path: `module/${DHME_M_MODULE_DASHBOARD}`,
            name: DHME_M_MODULE_DASHBOARD,
            component: () =>
              import(
                /* webpackChunkName: "dhme-m-asc" */ '../modules/daiwa-house-modular-europe/Master/DHME.M.ModuleDashboard'
              ),
            meta: {
              globalToolbar: true,
            },
          },
          {
            path: `module/${DHME_ASSEMBLY_PREPARATIONS}`,
            name: DHME_ASSEMBLY_PREPARATIONS,
            component: () =>
              import(
                /* webpackChunkName: "dhme-assembly-preparations" */ '../modules/daiwa-house-modular-europe/AssemblyPreparations/DhmeAssemblyPreparations.vue'
              ),
          },
          {
            path: `module/${DHME_M_TRESTLE_CHECKER}`,
            name: DHME_M_TRESTLE_CHECKER,
            component: () =>
              import(
                /* webpackChunkName: "dhme-trestle-checker" */ '../modules/daiwa-house-modular-europe/Master/DHME.M.TrestleChecker.vue'
              ),
          },
          {
            path: `module/${DHME_M_PICK_ORDER_MANAGEMENT}`,
            name: DHME_M_PICK_ORDER_MANAGEMENT,
            component: () =>
              import(
                /* webpackChunkName: "dhme-pick-order-management" */ '../modules/daiwa-house-modular-europe/Master/DHME.M.PickOrderManagement.vue'
              ),
          },
          {
            path: `module/${DHME_M_YARD_MANAGEMENT}`,
            name: DHME_M_YARD_MANAGEMENT,
            component: () =>
              import(
                /* webpackChunkName: "dhme-yard-management" */ '../modules/daiwa-house-modular-europe/Master/DHME.M.YardManagement.vue'
              ),
          },
          {
            path: `module/${DHME_QR2FORM}`,
            name: DHME_QR2FORM,
            component: () =>
              import(
                /* webpackChunkName: "dhme-qr-2-form" */ '../modules/daiwa-house-modular-europe/DHME.QR2Form.vue'
              ),
          },
          {
            path: `module/${DHME_ELEMENT_VALIDATION}`,
            name: DHME_ELEMENT_VALIDATION,
            component: () =>
              import(
                /* webpackChunkName: "dhme-element-validation" */ '../modules/daiwa-house-modular-europe/DHME.ElementValidation.vue'
              ),
          },
          {
            path: `module/${DHME_HALL_CONFIGURATOR}`,
            name: DHME_HALL_CONFIGURATOR,
            component: () =>
              import(
                /* webpackChunkName: "dhme-hall-configurator" */ '../modules/daiwa-house-modular-europe/HallConfigurator/DhmeHallConfigurator.vue'
              ),
          },
          {
            path: `module/${DHME_PHASE_TRANSFER}/:phase?`,
            name: DHME_PHASE_TRANSFER,
            component: () =>
              import(
                /* webpackChunkName: "dhme-phase-transfer" */ '../modules/daiwa-house-modular-europe/PhaseTransfer/DhmePhaseTransfer.vue'
              ),
          },
          {
            path: `module/${STOCK_MANAGEMENT_LITE}`,
            name: STOCK_MANAGEMENT_LITE,
            component: () =>
              import(
                /* webpackChunkName: "stock-management-lite" */ '../modules/StockManagementLite.vue'
              ),
          },
          ...projectIntake,
          {
            path: `module/${REUSE_POTENTIAL_INDEX}`,
            name: REUSE_POTENTIAL_INDEX,
            component: () =>
              import(
                /* webpackChunkName: "reuse-potential-index" */ '../modules/ReusePotentialIndex/ReusePotentialIndex.vue'
              ),
            children: [
              {
                path: ':variant/results',
                name: `${REUSE_POTENTIAL_INDEX}-results`,
                component: () =>
                  import(
                    /* webpackChunkName: "reuse-potential-index" */ '../modules/ReusePotentialIndex/ReusePotentialIndexResult.vue'
                  ),
              },
              {
                path: ':variant/cross-sections',
                name: `${REUSE_POTENTIAL_INDEX}-cross-sections`,
                component: () =>
                  import(
                    /* webpackChunkName: "reuse-potential-index" */ '../modules/ReusePotentialIndex/ReusePotentialIndexCrossSections.vue'
                  ),
              },
              {
                path: ':variant/cross-sections/:crossSection/calculation/:component?',
                name: `${REUSE_POTENTIAL_INDEX}-calculation`,
                component: () =>
                  import(
                    /* webpackChunkName: "reuse-potential-index" */ '../modules/ReusePotentialIndex/ReusePotentialIndexCalculation.vue'
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/acc-callback',
    name: 'ACC-callback',
    component: () => import('../views/AutodeskConstructionCloudCallback'),
  },
  {
    path: '/forgot',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword'),
  },
  {
    path: '/reset/:token',
    name: 'reset-password',
    component: ResetPassword,
  },
  {
    path: '/login',
    name: 'Login',
    component: () =>
      import(/* webpackChunkName: "authenticate" */ '../views/Login'),
  },
  {
    path: '/licenses',
    name: 'Licenses',
    component: () =>
      import(/* webpackChunkName: "authenticate" */ '../views/LicenseSelect'),
  },
  {
    path: '/loginAD',
    name: 'Login-AD',
    component: () =>
      import(/* webpackChunkName: "authenticate" */ '../views/LoginAzureAD'),
  },
  {
    path: '/invite/:token',
    name: 'Invite',
    component: () => import('../views/InviteSignUp'),
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    component: () =>
      import(/* webpackChunkName: "maintenance" */ '../views/Maintenance'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: appConfig.BASE_URL,
  routes,
});

export default router;
