import httpClient from "../httpClient";

const getWorkflowLinks = (workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/builder/workflow/${workflowId}/links`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const createWorkflowLink = (workflowId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.post(`/builder/workflow/${workflowId}/link`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const updateWorkflowLink = (workflowId, linkId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.put(`/builder/workflow/${workflowId}/link/${linkId}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const deleteWorkflowLink = (workflowId, linkId) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/builder/workflow/${workflowId}/link/${linkId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const getWorkflowLink = (workflowId, linkId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/builder/workflow/${workflowId}/link/${linkId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}


export {
  getWorkflowLinks,
  createWorkflowLink,
  updateWorkflowLink,
  deleteWorkflowLink,
  getWorkflowLink,
}
