import { getModuleData } from '@/services/api/module.api';
import Vue from 'vue';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  forgeAntTables: [],
};
const mutations = {
  module_forge_request(state) {
    state.moduleStatus = 'loading';
  },
  module_forge_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_forge_ant_tables_success(state, tables) {
    state.forgeAntTables = tables.filter(
      (table) => !table.name.startsWith('CFFA_')
    );
  },
  module_forge_enable_models(state, models) {
    if (state.moduleData?.CFFA_FORGE_MODELS?.records?.length) {
      const enabledModels = state.moduleData?.CFFA_FORGE_MODELS?.records.map(
        (model) => ({
          ...model,
          enabled: models.some((id) => id === model.id),
        })
      );

      Vue.set(state.moduleData?.CFFA_FORGE_MODELS, 'records', enabledModels);
    }
  },
};
const actions = {
  loadForgeModuleData({ commit }, { projectId, moduleId, sessionId, modelId }) {
    commit('module_forge_request');
    return new Promise((resolve, reject) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_forge_success', data);

          const baseTable = data.CFFA_FORGE_CLIENT?.records[0]?.base_ant_table;
          if (baseTable) {
            commit('antTables/set_base_table', baseTable, {
              root: true,
            });
          }
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
};
const getters = {
  forgeData: (state) => state.moduleData,
  forgeStatus: (state) => state.moduleStatus,
  forgeAntTables: (state) => state.forgeAntTables,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
