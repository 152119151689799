const getEnv = (key) => {
  return config?.[key] || process.env?.[key];
};

const appConfig = {
  VUE_APP_BASE_URL: getEnv('VUE_APP_BASE_URL'),
  VUE_APP_API_URL: getEnv('VUE_APP_API_URL'),
  VUE_APP_CLIENT_ID: getEnv('VUE_APP_CLIENT_ID'),
  VUE_APP_SECRET: getEnv('VUE_APP_SECRET'),
  VUE_APP_VERSION: getEnv('VUE_APP_VERSION'),
  VUE_APP_COLLABORALL_PORTAL_URL: getEnv('VUE_APP_COLLABORALL_PORTAL_URL'),
  VUE_APP_COLLABORALL_PORTAL_PROVIDER: getEnv(
    'VUE_APP_COLLABORALL_PORTAL_PROVIDER'
  ),
  VUE_APP_ACC_CLIENT_ID: getEnv('VUE_APP_ACC_CLIENT_ID'),
  VUE_APP_ACC_CLIENT_SECRET: getEnv('VUE_APP_ACC_CLIENT_SECRET'),
  VUE_APP_ACC_CLIENT_CALLBACK_URL: getEnv('VUE_APP_ACC_CLIENT_CALLBACK_URL'),
  VUE_APP_PUSHER_APP_ENABLED: getEnv('VUE_APP_PUSHER_APP_ENABLED'),
  VUE_APP_PUSHER_HOST: getEnv('VUE_APP_PUSHER_HOST'),
  VUE_APP_PUSHER_PORT: getEnv('VUE_APP_PUSHER_PORT'),
  VUE_APP_PUSHER_APP_ID: getEnv('VUE_APP_PUSHER_APP_ID'),
  VUE_APP_PUSHER_APP_KEY: getEnv('VUE_APP_PUSHER_APP_KEY'),
  VUE_APP_PUSHER_APP_CLUSTER: getEnv('VUE_APP_PUSHER_APP_CLUSTER'),
  VUE_APP_EXAMPLE_ENV: getEnv('VUE_APP_EXAMPLE_ENV'),
};

export default appConfig;
