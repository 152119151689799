import { getRecordHistory } from '@/services/api/record.api';
import { getTableQuery } from '@/services/api/table-query.api';

const state = {
  recordHistory: {
    record: {},
    history: [],
  },
  recordHistoryStatus: '',

  queryRecords: [],
  queryRecordsStatus: '',
};

const mutations = {
  get_history_request(state, record) {
    console.log(record);
    state.recordHistoryStatus = 'loading';
    state.recordHistory.record = record;
  },
  get_history_success(state, history) {
    state.recordHistoryStatus = 'success';
    state.recordHistory.history = history;
  },
  get_query_records_request(state) {
    state.queryRecordsStatus = 'loading';
  },
  get_query_records_success(state, records) {
    state.queryRecords = records;
    state.queryRecordsStatus = 'success';
  },
  reset_state(state) {
    state.queryRecords = [];
    state.recordHistory = {
      record: {},
      history: [],
    };
    state.recordHistoryStatus = '';
    state.queryRecordsStatus = '';
  },
};

const actions = {
  loadRecordHistory({ commit }, { projectId, tableId, record }) {
    commit('get_history_request', record);
    getRecordHistory(projectId, tableId, record.id)
      .then((history) => {
        commit('get_history_success', history);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchTableQueryRecords({ commit }, { projectId, tableId, body }) {
    commit('get_query_records_request');
    getTableQuery(projectId, tableId, body)
      .then((records) => {
        commit('get_query_records_success', records);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  resetHistory({ commit }) {
    commit('reset_state');
  },
  resetTableQuery({ commit }) {
    commit('reset_state');
  },
};

const getters = {
  recordHistory: (state) => state.recordHistory,
  recordHistoryStatus: (state) => state.recordHistoryStatus,

  queryRecords: (state) => state.queryRecords,
  queryRecordsStatus: (state) => state.queryRecordsStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
