<template>
  <div
    v-if="project"
    class="project-apps-container"
    :class="{ 'active-appbar-menu': menuDisplayed }"
    @mouseleave="closeProjectMenu"
  >
    <div class="project-apps-menu pt-4">
      <v-text-field
        v-model="moduleMenuSearch"
        class="mx-4"
        flat
        filled
        autofocus
        dense
        clearable
        hide-details
        prepend-inner-icon="mdi-magnify"
        placeholder="Type here to search"
        style="flex: 0"
      ></v-text-field>
      <div v-if="accessibleModules.length > 0">
        <v-subheader>Modules</v-subheader>

        <div class="px-4 modules-grid">
          <module-tile
            v-for="module in accessibleModules"
            :key="module.id"
            :module="module"
            :project="project"
            @moduleClicked="navigateToProjectModule(module)"
          />
        </div>
      </div>
      <v-spacer />
      <div
        v-if="
          accessibleGeneralApps.length > 0 || $can('access', 'general-apps')
        "
        class="mb-4"
      >
        <v-subheader>General Apps</v-subheader>
        <div class="px-4 modules-grid">
          <module-tile
            v-for="module in accessibleGeneralApps"
            :key="module.id"
            :module="module"
            :project="project"
            @moduleClicked="navigateToAntModule(module)"
          />
        </div>
      </div>
      <div
        class="d-flex align-center px-5 py-4 ant-border-top"
        style="background-color: #f2f2f2"
      >
        <v-tooltip bottom style="z-index: 30">
          <template #activator="{ on }">
            <v-icon
              small
              class="mr-2 ant-icon"
              v-on="on"
              @click="copyProjectId"
            >
              mdi-content-copy
            </v-icon>
          </template>
          <span>Copy id</span>
        </v-tooltip>
        <div class="font-italic fs-12">
          {{ project.name }}
        </div>
        <v-spacer />
        <v-tooltip bottom style="z-index: 30">
          <template #activator="{ on }">
            <v-icon
              dense
              class="ant-icon"
              @click="navigateToProjectDashboard"
              v-on="on"
              >mdi-home-outline</v-icon
            >
          </template>
          <span>Home</span>
        </v-tooltip>
        <v-divider vertical class="mx-2" />
        <v-tooltip
          v-if="$can('access', 'project-settings')"
          bottom
          style="z-index: 30"
        >
          <template #activator="{ on }">
            <v-icon
              dense
              class="ant-icon"
              @click="navigateToProjectSettings"
              v-on="on"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Settings</span>
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ModuleTile from './Dashboard/ModuleTile';

export default {
  name: 'ProjectApps',
  components: { ModuleTile },
  props: {
    menuDisplayed: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      moduleMenuSearch: null,
    };
  },
  computed: {
    ...mapGetters(['project', 'authenticatedUser']),

    accessibleModules() {
      if (this.project) {
        let modules = this.project.modules.filter(
          (module) => module.accessible
        );
        if (this.moduleMenuSearch) {
          return modules.filter((module) =>
            module.name
              .toLowerCase()
              .includes(this.moduleMenuSearch.toLowerCase())
          );
        }
        return modules.sort((a, b) => a.name.localeCompare(b.name));
      }
      return [];
    },
    accessibleGeneralApps() {
      let modules = [];
      if (this.project.generalPermissions) {
        if (this.project.generalPermissions.workflows) {
          modules.push({
            id: '1',
            accessible: 1,
            description: 'ANT Project Workflows',
            icon: 'mdi-source-branch',
            name: 'workflows',
            route: 'workflows',
          });
        }

        if (this.project.generalPermissions.tables) {
          modules.push({
            id: '2',
            accessible: 1,
            description: 'ANT Project Tables',
            icon: 'mdi-table-multiple',
            name: 'tables',
            route: 'tables',
          });
        }

        if (this.project.generalPermissions.sbs) {
          modules.push({
            id: '3',
            accessible: 1,
            description: 'ANT Project Tables',
            icon: 'mdi-lan',
            name: 'SBS dashboard',
            route: 'sbs',
          });
        }

        if (this.project.generalPermissions.labels) {
          modules.push({
            id: '4',
            accessible: 1,
            description: 'ANT Project Labels',
            icon: 'mdi-label-multiple-outline',
            name: 'Labels management',
            route: 'labels',
          });
        }
      }

      return modules.sort((a, b) => a.name.localeCompare(b.name));
    },
  },
  methods: {
    closeProjectMenu() {
      this.$emit('closeProjectMenu');
    },
    copyProjectId() {
      navigator.clipboard.writeText(this.project.id);
      this.$store.commit('showNotification', {
        content: `Copied project id to clipboard`,
        color: 'success',
      });
    },
    navigateToProjectModule(module) {
      this.$router.push({
        path: `/project/${this.project.slug}/module/${module.route}`,
      });
    },
    navigateToAntModule(module) {
      this.$router.push({
        path: `/project/${this.project.slug}/${module.route}`,
      });
    },
    navigateToProjectDashboard() {
      this.$router.push({ path: `/project/${this.project.slug}` });
      this.$emit('closeProjectMenu');
    },
    navigateToProjectSettings() {
      this.$router.push({
        path: `/project/${this.project.slug}/settings/teams`,
      });
      this.$emit('closeProjectMenu');
    },
  },
};
</script>

<style scoped lang="scss">
.project-apps-container {
  z-index: 9;
  opacity: 0;
  position: absolute;
  transform: translateY(0) scale(0);
  transition:
    transform 400ms ease,
    opacity 400ms ease 100ms,
    box-shadow 400ms ease;
  width: 550px;
  height: 600px;
  border-radius: 15px;
  overflow: hidden;
  background-color: white;

  &.active-appbar-menu {
    opacity: 1;
    transform: translateY(calc(-100% - 65px)) scale(1);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  }

  .project-apps-menu {
    display: flex;
    height: 100%;
    flex-direction: column;

    .modules-grid {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
    }
  }
}
</style>
