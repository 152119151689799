<template>
  <div>
    Task
    <router-link
      :to="{ name: 'tasks-detail', params: { taskId: data.taskId } }"
      @click.native="emitOnAction"
    >
      {{ data.taskTitle }}
    </router-link>
    was assigned to you.
  </div>
</template>
<script>
import NotificationRendererMixin from '@/components/Notifications/NotificationMessages/NotificationRendererMixin';

export default {
  name: 'TaskWasAssigned',
  mixins: [NotificationRendererMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
