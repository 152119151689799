const state = {
  content: '',
  color: '',
};

const mutations = {
  showNotification(state, payload) {
    state.content = payload.content;
    state.color = payload.color;
  },
};

export default {
  state,
  mutations,
};
