const state = {
    recolorOrderGradientCount: 0
}
const mutations = {
    recolor_order_gradient(state) {
        state.recolorOrderGradientCount++
    },
}
const actions = {
}
const getters = {
    recolorOrderGradient: state => state.recolorOrderGradientCount
}

export default {
    state,
    mutations,
    actions,
    getters
}
