<template>
  <sidebar
    v-click-outside="onClickOutside"
    :shown="newsSidebarShown"
    @close="closeWhatsNewSidebar"
    @on-shown="onSidebarShown"
  >
    <template #header>
      <div class="ant-border-bottom">
        <v-card-title>What's new</v-card-title>
      </div>
    </template>
    <div class="my-1 pr-2 d-flex overflow-hidden flex-grow-1">
      <template v-if="true">
        <div
          v-if="news.length"
          class="d-flex flex-column overflow-hidden overflow-y-auto"
        >
          <news-item v-for="(item, i) in news" :key="i" :item="item" />
        </div>
        <div
          v-else
          class="d-flex flex-grow-1 full-width align-center justify-center"
        >
          No updates yet...
        </div>
      </template>
      <div
        v-else
        class="d-flex align-center justify-center full-width full-height"
      >
        <ant-loading />
      </div>
    </div>
  </sidebar>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import NewsItem from '@/components/News/NewsItem.vue';

export default {
  name: 'WhatsNewSidebar',
  components: { NewsItem, AntLoading, Sidebar },
  computed: {
    ...mapGetters([
      'news',
      'newsSidebarShown',
      'newsSidebarClickOutsideEnabled',
      'newsSidebarHasCriticalUpdates',
    ]),
  },
  watch: {
    newsSidebarShown: {
      handler(state) {
        if (state) {
          this.$store.dispatch('markAllNewsAsRead');
        }
      },
      immediate: true,
    },
    newsSidebarHasCriticalUpdates: {
      handler(hasUpdates) {
        if (hasUpdates) {
          this.$store.commit('open_news_sidebar');
        }
      },
      immediate: true,
    },
  },
  methods: {
    closeWhatsNewSidebar() {
      this.$store.commit('close_news_sidebar');
      this.$store.commit('disable_news_sidebar_click_outside');
    },
    onSidebarShown() {
      this.$store.commit('enable_news_sidebar_click_outside');
    },
    onClickOutside() {
      if (this.newsSidebarClickOutsideEnabled) {
        this.closeWhatsNewSidebar();
      }
    },
  },
};
</script>

<style scoped></style>
