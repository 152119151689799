import httpClient from '../httpClient';

const getSbsRecords = (projectId, filters) => {
  let params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) {
      params.append(`filter[${key}]`, filters[key]);
    }
  });

  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSbsRecord = (projectId, sbsId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs/${sbsId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSbsRecordChildren = (projectId, sbsId, filters) => {
  let params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) {
      params.append(`filter[${key}]`, filters[key]);
    }
  });

  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs/${sbsId}/children`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSbsProjectPermissions = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs-permissions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const searchSbsTree = (projectId, searchValue, filters) => {
  let params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) {
      params.append(`filter[${key}]`, filters[key]);
    }
  });
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs-tree/search?value=${searchValue}`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const searchSbsRecords = (projectId, searchValue, filters) => {
  let params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) {
      params.append(`filter[${key}]`, filters[key]);
    }
  });
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs-search?value=${searchValue}`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const importSbsBatch = (projectId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/sbs-import`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createSbsRecord = (projectId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/sbs`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateSbsRecord = (projectId, sbsId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/project/${projectId}/sbs/${sbsId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteSbsRecord = (projectId, sbsId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/sbs/${sbsId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteMultipleSbsRecords = (projectId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/sbs`, { data: body })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const clearSbsTable = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/sbs-clear`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSbsTree = (projectId, filters) => {
  let params = new URLSearchParams();
  Object.keys(filters).forEach((key) => {
    if (filters[key] !== undefined) {
      params.append(`filter[${key}]`, filters[key]);
    }
  });

  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs-tree`, {
        params: params,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSbsVersions = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sbs-versions`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createSbsVersion = (projectId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/sbs-versions`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteSbsVersion = (projectId, versionId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/sbs-versions/${versionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getSbsRecords,
  getSbsRecord,
  getSbsRecordChildren,
  deleteMultipleSbsRecords,
  clearSbsTable,
  createSbsRecord,
  updateSbsRecord,
  deleteSbsRecord,
  getSbsTree,
  importSbsBatch,
  searchSbsTree,
  searchSbsRecords,
  getSbsProjectPermissions,
  getSbsVersions,
  createSbsVersion,
  deleteSbsVersion,
};
