<template>
  <v-container fluid fill-height class="form-container">
    <div class="ant-glass-background login-card">
      <div class="login-header">ANT</div>
      <v-form ref="forgotForm" @submit.prevent="onSubmit">
        <ant-input label="email">
          <template #input-field>
            <v-text-field
              v-model="email"
              type="email"
              placeholder="Email"
              :rules="[rules.required]"
              single-line
              dense
              filled
            />
          </template>
        </ant-input>

        <ant-input :label="$t('system.profile.newPW')" top-margin="mt-0">
          <template #input-field>
            <v-text-field
              v-model="password"
              filled
              class="mb-2"
              :placeholder="$t('system.profile.enterNewPW')"
              :rules="[rules.required, rules.password]"
              single-line
              dense
              autofocus
              :type="passwordFieldType"
              :append-icon="
                passwordFieldType === 'password'
                  ? 'mdi-eye-outline'
                  : 'mdi-eye-off-outline'
              "
              @click:append="
                passwordFieldType === 'password'
                  ? (passwordFieldType = 'text')
                  : (passwordFieldType = 'password')
              "
            />
          </template>
        </ant-input>

        <ant-input :label="$t('system.profile.repeatNewPW')" top-margin="mt-0">
          <template #input-field>
            <v-text-field
              v-model="confirmPassword"
              filled
              dense
              single-line
              :type="passwordFieldType"
              :append-icon="
                passwordFieldType === 'password'
                  ? 'mdi-eye-outline'
                  : 'mdi-eye-off-outline'
              "
              class="mb-2"
              :placeholder="$t('system.profile.enterNewPW')"
              :rules="[rules.required, passwordConfirmationRule]"
              @click:append="
                passwordFieldType === 'password'
                  ? (passwordFieldType = 'text')
                  : (passwordFieldType = 'password')
              "
            />
          </template>
        </ant-input>

        <div class="d-flex">
          <v-btn
            type="submit"
            class="d-block flex-grow-1"
            color="primary"
            elevation="0"
            tile
            small
          >
            RESET PASSWORD
          </v-btn>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                class="ml-2"
                icon
                target="_blank"
                href="https://docs.antcde.io/"
                v-on="on"
              >
                <v-icon class="ant-icon"> mdi-help-circle-outline </v-icon>
              </v-btn>
            </template>
            <span>ANT Documentation</span>
          </v-tooltip>
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script>
import LocalStorageService from '@/services/local-storage';
import AntInput from '@/components/AntInput.vue';

export default {
  name: 'ResetPassword',
  components: { AntInput },
  data: () => ({
    email: null,
    password: null,
    confirmPassword: null,
    passwordFieldType: 'password',
    rules: {
      required: (value) => !!value || 'Required',
      password: (value) => {
        const pattern =
          /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_])(?!.*\s).{8,}$/;
        return (
          pattern.test(value) ||
          'Min. 8 characters with at least one capital letter, a number and a special character and no white spaces.'
        );
      },
    },
    snackbar: false,
  }),
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.confirmPassword || 'Password must match';
    },
  },
  mounted() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  methods: {
    onSubmit() {
      if (this.$refs.forgotForm.validate()) {
        this.$store
          .dispatch('resetPassword', {
            email: this.email,
            token: this.$route.params.token,
            password: this.password,
            password_confirmation: this.confirmPassword,
          })
          .then(() => {
            this.$store.commit('showNotification', {
              content: 'Your password has been reset',
              color: 'success',
            });
            LocalStorageService.removeToken();
            this.$router.push({ name: 'Login' });
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-container {
  display: flex;
  justify-content: center;
  width: 500px;

  .login-card {
    padding: 25px 100px;

    .login-header {
      text-align: center;
      font-size: 70px;
      color: var(--v-primary-base);
    }
  }
}
</style>
