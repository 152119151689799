<script>
import { defineComponent } from 'vue';
import NotificationRendererMixin from '@/components/Notifications/NotificationMessages/NotificationRendererMixin';

export default defineComponent({
  name: 'DefaultMessage',
  mixins: [NotificationRendererMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getStatusColor() {
      switch (this.data.status) {
        case 'success':
          break;
        case 'error':
          break;
        case 'info':
          break;
        case 'warning':
          break;
      }
    },
  },
});
</script>

<template>
  <div :style="{'border-left': 'black'}">{{ data.message }}</div>
</template>

<style scoped lang="scss"></style>
