import { GIS_VIEWER_WIDGETS } from '@/components/Modules/GisViewer';

const viewer = {
  map: undefined,
  viewMap: undefined,
  viewScene: undefined,
  viewActive: undefined,

  classes: {},

  groupLayers: [],
  featureLayers: [],
  imageryLayers: [],
  mapImageLayers: [],
  tileLayers: [],
  vectorTileLayers: [],
  wmsLayers: [],
  pointCloudLayers: [],
  voxelLayers: [],
  sceneLayers: [],
  buildingSceneLayers: [],
  subLayers: [],
  sketchLayers: [],

  featureTable: undefined,
  measurement: undefined,
  highlightHandle: undefined,

  widgets: {
    [GIS_VIEWER_WIDGETS.HOME]: undefined,
    [GIS_VIEWER_WIDGETS.LAYER_LIST]: undefined,
    [GIS_VIEWER_WIDGETS.SEARCH]: undefined,
    [GIS_VIEWER_WIDGETS.ZOOM]: undefined,
    [GIS_VIEWER_WIDGETS.BASEMAP_TOGGLE]: undefined,
    [GIS_VIEWER_WIDGETS.BASEMAP_GALLERY]: undefined,
    [GIS_VIEWER_WIDGETS.MEASUREMENT]: undefined,
  },

  defaults: {
    viewConfig: {
      center: [-118.805, 34.027], // Longitude, latitude
      zoom: 13, // Zoom level
    },
  },
};

export default viewer;
