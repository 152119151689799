import Vue from 'vue';
import moment from 'moment';

Vue.filter('parseDateYMD', function (date) {
  if (date) {
    return moment(String(date)).format('YYYY-MM-DD');
  }
});

Vue.filter('parseDateDMY', function (date) {
  if (date) {
    return moment(String(date)).format('DD-MM-YYYY');
  }
});

Vue.filter('parseTime', function (date) {
  if (date) {
    return moment(String(date)).format('hh:mm:ss');
  }
});

Vue.filter('capitalize', (value) => {
  if (!value) {
    return '';
  }
  value = value.toString().toLowerCase();
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('lowercase', (value) => value.toString().toLowerCase());

Vue.filter('uppercase', (value) => value.toString().toUpperCase());
