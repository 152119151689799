import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  updateRecord,
} from '@/services/api/record.api';

const state = {
  moduleData: {
    CFFA_DHME_WKB_MEASUREMENTS: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_WKB_RISKS: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_WKB_DOCUMENTS: {
      id: undefined,
      records: [],
    },
  },
  moduleStatus: '',
  wkbTableStatus: '',
};
const mutations = {
  module_wkb_library_request(state) {
    state.moduleStatus = 'loading';
  },
  module_wkb_library_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_wkb_library_table_request(state) {
    state.wkbTableStatus = 'loading';
  },
  module_wkb_library_table_error(state) {
    state.wkbTableStatus = 'error';
  },
  module_wkb_library_create_success(state, { table, record }) {
    state.moduleData[table].records.push(record);
    state.wkbTableStatus = 'success';
  },
  module_wkb_library_update_success(state, { table, updatedRecord }) {
    Object.assign(
      state.moduleData[table].records.find(
        (item) => item.id === updatedRecord.id
      ),
      updatedRecord
    );
    state.wkbTableStatus = 'success';
  },
  module_wkb_library_delete_success(state, { table, deletedRecordId }) {
    state.moduleData[table].records = state.moduleData[table].records.filter(
      (item) => item.id !== deletedRecordId
    );
    state.wkbTableStatus = 'success';
  },
  module_wkb_library_add_measurement_to_risk_success(state, record) {
    state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.records.push(record);
  },
  module_wkb_library_update_measurement_to_risk_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_wkb_library_delete_measurement_to_risk_success(state, deletedId) {
    state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.records =
      state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.records.filter(
        (record) => record.id !== deletedId
      );
  },
};
const actions = {
  fetchWkbLibraryModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_wkb_library_request');
    return new Promise((resolve, reject) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_wkb_library_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  createWkbLibraryRecord({ commit, getters, state }, { table, body }) {
    commit('module_wkb_library_table_request');
    body.project = {
      id: getters.project.id,
    };
    body.table = {
      id: state.moduleData[table].id,
    };
    return new Promise((resolve, reject) => {
      return createRecord(body)
        .then((record) => {
          commit('module_wkb_library_create_success', { table, record });
          resolve(record);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          commit('module_wkb_library_table_error');
          reject(error);
        });
    });
  },
  updateWkbLibraryRecord(
    { commit, getters, state },
    { table, recordId, body }
  ) {
    commit('module_wkb_library_table_request');
    body.project = {
      id: getters.project.id,
    };
    body.table = {
      id: state.moduleData[table].id,
    };
    return new Promise((resolve, reject) => {
      return updateRecord(recordId, body)
        .then((record) => {
          commit('module_wkb_library_update_success', {
            table,
            updatedRecord: record,
          });
          resolve(record);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          commit('module_wkb_library_table_error');
          reject(error);
        });
    });
  },
  deleteWkbLibraryRecord({ commit, getters, state }, { table, recordId }) {
    commit('module_wkb_library_table_request');
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData[table].id,
      },
    };
    return new Promise((resolve, reject) => {
      return deleteRecord(recordId, body)
        .then((response) => {
          commit('module_wkb_library_delete_success', {
            table,
            deletedRecordId: recordId,
          });
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  addRiskMeasurementRelation({ commit, getters, state }, record) {
    record.project = {
      id: getters.project.id,
    };
    record.table = {
      id: state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.id,
    };
    return createRecord(record)
      .then((record) => {
        commit('module_wkb_library_add_measurement_to_risk_success', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateRiskMeasurementRelation(
    { commit, getters, state },
    { recordId, body }
  ) {
    body.project = {
      id: getters.project.id,
    };
    body.table = {
      id: state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.id,
    };
    return updateRecord(recordId, body)
      .then((record) => {
        commit('module_wkb_library_update_measurement_to_risk_success', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteRiskMeasurementRelation({ commit, getters, state }, recordId) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.id,
      },
    };
    return deleteRecord(recordId, body)
      .then((record) => {
        commit(
          'module_wkb_library_delete_measurement_to_risk_success',
          recordId
        );
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  wkbLibraryStatus: (state) => state.moduleStatus,
  wkbLibraryChapters: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTERS.records,
  wkbLibraryMeasurements: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENTS.records,
  wkbLibraryMeasurementsForms: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_FORMS.records,
  wkbLibraryRisks: (state) => state.moduleData.CFFA_DHME_WKB_RISKS.records,
  wkbLibraryMeasurementDocuments: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS.records,
  wkbLibraryMeasurementDocumentsTableId: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS.id,
  wkbLibraryMeasurementTasks: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_TASKS.records,
  wkbLibraryMeasurementTasksTableId: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_TASKS.id,
  wkbLibraryTableStatus: (state) => state.wkbTableStatus,
  wkbLibraryMapping: (state) =>
    state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
