<template>
  <div class="d-flex flex-column" :class="topMargin">
    <v-subheader
      class="font-weight-bold pa-0 mb-1"
      :class="{ 'justify-center': centered }"
      style="font-size: 11px; height: auto"
    >
      <v-tooltip v-if="hint" right>
        <template #activator="{ on, attrs }">
          <v-icon v-bind="attrs" small class="mr-1" v-on="on">
            mdi-information-variant-circle-outline
          </v-icon>
        </template>
        <span>{{ hint }}</span>
      </v-tooltip>
      {{ label }}
      <v-tooltip v-if="!isOptional" bottom>
        <template #activator="{ on, attrs }">
          <v-icon
            color="var(--v-error-darken1)"
            style="font-size: 8px"
            dense
            v-bind="attrs"
            v-on="on"
          >
            mdi-asterisk
          </v-icon>
        </template>
        <span>Required field</span>
      </v-tooltip>
    </v-subheader>
    <slot name="input-field" />
  </div>
</template>

<script>
export default {
  name: 'AntInput',
  props: {
    label: {
      type: String,
      required: true,
    },
    isOptional: {
      type: Boolean,
      default: false,
    },
    topMargin: {
      type: String,
      default: 'mt-5',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped></style>
