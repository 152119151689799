import { unwrapV2 } from '@/services/api/v2/utils';

export const createColumnV2 = (data) =>
  unwrapV2((axios) => axios.post(`/columns`, data));

export const updateColumnV2 = (columnId, data) =>
  unwrapV2((axios) => axios.put(`/columns/${columnId}`, data));

export const deleteColumnV2 = (column) =>
  unwrapV2((axios) => axios.delete(`/columns/${column.id ?? column}`));

export const getColumnsV2 = (table) =>
  unwrapV2((axios) => axios.get(`/tables/${table.id ?? table}/columns`));
