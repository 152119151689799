import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  getRecords,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { bookOfObjects } from '@/services/bookOf';
import { getTasks } from '@/services/api/task.api';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';
import {
  deleteRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';

const state = {
  moduleData: {},
  moduleStatus: '',

  bomTasks: [],
  assemblyPhase: null,
};
const mutations = {
  module_bill_of_material_request(state) {
    state.moduleStatus = 'loading';
  },
  module_bill_of_material_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_bill_of_material_group_create_success(state, group) {
    state.moduleData.CFFA_DHME_RESOURCEGROUPS.records.push(group);
  },
  module_bill_of_material_group_update_success(state, group) {
    Object.assign(
      state.moduleData.CFFA_DHME_RESOURCEGROUPS.records.find(
        (x) => x.id === group.id
      ),
      group
    );
  },
  module_bill_of_material_group_delete_success(state, deletedGroupId) {
    state.moduleData.CFFA_DHME_RESOURCEGROUPS.records =
      state.moduleData.CFFA_DHME_RESOURCEGROUPS.records.filter(
        (group) => group.id !== deletedGroupId
      );
  },
  module_bill_of_material_group_object_create_success(
    state,
    groupObjectRelation
  ) {
    state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.records.push(
      groupObjectRelation
    );
  },
  module_bill_of_material_group_element_create_success(
    state,
    groupElementRelation
  ) {
    state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.records.push(
      groupElementRelation
    );
  },
  module_bill_of_material_group_object_update_success(
    state,
    updatedGroupGroupObjectRelation
  ) {
    Object.assign(
      state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.records.find(
        (relation) => relation.id === updatedGroupGroupObjectRelation.id
      ),
      updatedGroupGroupObjectRelation
    );
  },
  module_bill_of_material_group_element_update_success(
    state,
    updatedGroupGroupElementRelation
  ) {
    Object.assign(
      state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.records.find(
        (relation) => relation.id === updatedGroupGroupElementRelation.id
      ),
      updatedGroupGroupElementRelation
    );
  },
  module_bill_of_material_group_object_delete_success(
    state,
    deletedGroupGroupObjectRelationId
  ) {
    state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.records =
      state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.records.filter(
        (relation) => relation.id !== deletedGroupGroupObjectRelationId
      );
  },
  module_bill_of_material_group_element_delete_success(
    state,
    deletedGroupGroupElementRelationId
  ) {
    state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.records =
      state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.records.filter(
        (relation) => relation.id !== deletedGroupGroupElementRelationId
      );
  },
  module_bill_of_material_group_object_import_success(state, records) {
    state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.records = records;
  },
  module_bill_of_material_group_element_import_success(state, records) {
    state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.records = records;
  },
  module_bill_of_material_tasks_request(state) {
    state.moduleStatus = 'loading';
  },
  module_bill_of_material_work_package_request_success(state, tasks) {
    state.bomTasks = state.bomTasks.concat(tasks);
    state.moduleStatus = 'success';
  },
  module_bill_of_material_tasks_success(state, tasks) {
    state.bomTasks = tasks;
    state.moduleStatus = 'success';
  },
};
const actions = {
  async fetchBillOfMaterialTasks({ commit, getters }) {
    commit('module_bill_of_material_tasks_request');
    const response = await queryTasksV2(getters.selectedLicense.id, [
      {
        column: 'project',
        operator: '=',
        values: [getters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: [
          'dhme-module-assembly',
          'dhme-production-phase',
          'dhme-placement-phase',
          'dhme-work-package',
          'dhme-assembly-phase',
        ],
      },
    ]);

    commit('module_bill_of_material_tasks_success', response.tasks);
  },
  fetchBillOfMaterialData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_bill_of_material_request');
    return new Promise((resolve, reject) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_bill_of_material_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  createResourceGroup({ commit, state, getters }, { record }) {
    return createRecord({
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_RESOURCEGROUPS.id,
      },
      record: record,
    })
      .then((response) => {
        commit('module_bill_of_material_group_create_success', response);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  async updateResourceGroupName(
    { commit, state, getters },
    { recordId, name }
  ) {
    try {
      const group = await updateRecordV2(
        state.moduleData.CFFA_DHME_RESOURCEGROUPS.id,
        recordId,
        { name }
      );
      commit('module_bill_of_material_group_update_success', group);
    } catch (e) {
      this.commit('showNotification', {
        content: e.message,
        color: 'error',
      });
    }
  },
  async removeResourceGroup({ commit, state, getters, rootGetters }, group) {
    try {
      let { elements, objects } = await queryTablesV2({
        tables: [
          {
            name: 'CFFA_DHME_RESOURCEGROUP_ELEMENTS',
            project: rootGetters.project.id,
            as: 'elements',
            columns: [
              {
                name: 'group_code',
                conditions: [
                  {
                    operator: '=',
                    value: group.group_code,
                  },
                ],
              },
            ],
          },
          {
            name: 'CFFA_DHME_RESOURCEGROUP_OBJECTS',
            project: rootGetters.project.id,
            as: 'objects',
            columns: [
              {
                name: 'group_code',
                conditions: [
                  {
                    operator: '=',
                    value: group.group_code,
                  },
                ],
              },
            ],
          },
        ],
      });
      await deleteRecordV2(elements.id, ...elements.records.map((x) => x.id));
      await deleteRecordV2(objects.id, ...objects.records.map((x) => x.id));
      await deleteRecordV2(
        state.moduleData.CFFA_DHME_RESOURCEGROUPS.id,
        group.id
      );

      commit('module_bill_of_material_group_delete_success', group.id);
    } catch (error) {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  },
  createResourceGroupObjectRelation(
    { commit, state, getters },
    { record, tableState }
  ) {
    const tableId =
      tableState === 'Elements'
        ? state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.id
        : state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.id;

    return createRecord({
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
      record: record,
    })
      .then((response) => {
        switch (tableState) {
          case 'Elements':
            commit(
              'module_bill_of_material_group_element_create_success',
              response
            );
            break;
          case 'Objects':
            commit(
              'module_bill_of_material_group_object_create_success',
              response
            );
            break;
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateResourceGroupObjectRelation(
    { commit, state, getters },
    { recordId, record, tableState }
  ) {
    const tableId =
      tableState === 'Elements'
        ? state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.id
        : state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.id;

    return updateRecord(recordId, {
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
      record: record,
    })
      .then((response) => {
        switch (tableState) {
          case 'Elements':
            commit(
              'module_bill_of_material_group_element_update_success',
              response
            );
            break;
          case 'Objects':
            commit(
              'module_bill_of_material_group_object_update_success',
              response
            );
            break;
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  removeResourceGroupObjectRelation(
    { commit, state, getters },
    { recordId, tableState }
  ) {
    const tableId =
      tableState === 'Elements'
        ? state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.id
        : state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.id;

    return deleteRecord(recordId, {
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
    })
      .then((response) => {
        switch (tableState) {
          case 'Elements':
            commit(
              'module_bill_of_material_group_element_delete_success',
              recordId
            );
            break;
          case 'Objects':
            commit(
              'module_bill_of_material_group_object_delete_success',
              recordId
            );
            break;
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  importMultipleGroupObjectRelations(
    { commit, getters, state },
    { articles, tableState }
  ) {
    let book = bookOfObjects('articles', articles);
    let csv = book.convert('csv', 'string');
    let parsedCsv = btoa(csv);
    const tableId =
      tableState === 'Elements'
        ? state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.id
        : state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.id;
    return importRecords({
      project: {
        id: getters.project.id,
      },
      table: {
        id: tableId,
      },
      records: parsedCsv,
    })
      .then((response) => {
        getRecords(getters.project.id, tableId).then((records) => {
          switch (tableState) {
            case 'Elements':
              commit(
                'module_bill_of_material_group_element_import_success',
                records
              );
              break;
            case 'Objects':
              commit(
                'module_bill_of_material_group_object_import_success',
                records
              );
              break;
          }
        });
        this.commit('showNotification', {
          content: `successfully imported ${articles.length} articles`,
          color: 'success',
        });
        return response;
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  dhmeBillOfMaterialData: (state) => state.moduleData,
  dhmeBillOfMaterialStatus: (state) => state.moduleStatus,
  dhmeBillOfMaterialWorkPackagesTable: (state) =>
    state.moduleData.CFFA_DHME_WORK_PACKAGES.id,
  dhmeBillOfMaterialModelMapping: (state) =>
    state.moduleData.CFFA_DHME_MODEL_MAPPING.records[0],
  dhmeBillOfMaterialModelMappings: (state) =>
    state.moduleData.CFFA_DHME_MODEL_MAPPING.records,
  dhmeBillOfMaterialResourceGroups: (state) =>
    state.moduleData.CFFA_DHME_RESOURCEGROUPS.records,
  dhmeBillOfMaterialResourceGroupObjects: (state) =>
    state.moduleData.CFFA_DHME_RESOURCEGROUP_OBJECTS.records,
  dhmeBillOfMaterialObjects: (state) =>
    state.moduleData.CFFA_DHME_OBJECTS.records,
  dhmeBillOfMaterialElements: (state) =>
    state.moduleData.CFFA_DHME_ELEMENTS.records,
  dhmeBillOfMaterialResourceGroupElements: (state) =>
    state.moduleData.CFFA_DHME_RESOURCEGROUP_ELEMENTS.records,
  dhmeBillOfMaterialModules: (state) =>
    state.moduleData.CFFA_DHME_MODULES.records,
  dhmeBillOfMaterialNonModules: (state) =>
    state.moduleData.CFFA_DHME_NON_MODULES.records,
  dhmeBillOfMaterialPhases: (state) =>
    state.moduleData.CFFA_DHME_PHASES.records,
  dhmeBillOfMaterialClient: (state) =>
    state.moduleData.CFFA_FORGE_CLIENT.records[0],
  dhmeBillOfMaterialModels: (state) =>
    state.moduleData.CFFA_FORGE_MODELS.records,
  dhmeBillOfMaterialPlacementTasks: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-placement-phase'
    ),
  dhmeBillOfMaterialAssemblyTasks: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-module-assembly'
    ),
  dhmeBillOfMaterialProductionTask: (state) =>
    state.bomTasks.find(
      (task) => task?.task_type?.type === 'dhme-production-phase'
    ),
  dhmeBillOfMaterialWorkPackagesTasks: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-work-package'
    ),
  dhmeBillOfMaterialAssemblyPhase: (state) =>
    state.bomTasks.filter(
      (task) => task?.task_type?.type === 'dhme-assembly-phase'
    ),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
