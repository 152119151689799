<template>
  <div>
    New comment added to task
    <router-link
      :to="{ name: 'tasks-detail', params: { taskId: data.taskId } }"
      @click.native="emitOnAction"
    >
      {{ data.taskTitle }}</router-link
    >
  </div>
</template>
<script>
import NotificationRendererMixin from '@/components/Notifications/NotificationMessages/NotificationRendererMixin';

export default {
  name: 'TaskNewComment',
  mixins: [NotificationRendererMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
