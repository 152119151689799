import { httpClientV2 } from '@/services/httpClient';

export function unwrapV2(newPromise) {
  return new Promise((resolve, reject) => {
    Promise.resolve(httpClientV2)
      .then(newPromise)
      .then(
        (response) => resolve(response?.data ?? response),
        (error) => reject(error?.response?.data ?? error?.response ?? error)
      );
  });
}

export const also = (fn) => (value) => {
  fn(value);
  return value;
};
