const mutationNameOf = (name) =>
  `set${name.charAt(0).toUpperCase()}${name.slice(1)}`;

export const Routines = {
  newIdle: () => ({ status: 'idle', result: undefined, error: undefined }),
  newRunning: () => ({
    status: 'running',
    result: undefined,
    error: undefined,
  }),
  newFailure: (reason) => ({ status: 'failure', reason, result: undefined }),
  newSuccess: (result) => ({ status: 'success', reason: undefined, result }),

  isIdle: (routine) => routine && routine.status === 'idle',
  isRunning: (routine) => routine && routine.status === 'running',
  isFailure: (routine) => routine && routine.status === 'failure',
  isSuccess: (routine) => routine && routine.status === 'success',

  newState(name) {
    return { [name]: this.newIdle() };
  },
  newMutation: (name) => {
    return {
      [mutationNameOf(name)]: (state, payload) => {
        state[name] = payload;
      },
    };
  },
  commit(name, context, action, guard) {
    const routine = context.state[name];

    if (
      (guard && guard(routine, context)) ||
      (!guard && !this.isRunning(routine))
    ) {
      const commit = (routine) => context.commit(mutationNameOf(name), routine);

      commit(this.newRunning());
      return Promise.resolve({ context, name })
        .then(action)
        .then(this.newSuccess, this.newFailure)
        .then((routine) => {
          commit(routine);
          return routine;
        });
    } else {
      return Promise.resolve(this.newFailure('Guarded'));
    }
  },
  newAction(name, action, guard) {
    return (context, payload) =>
      this.commit(name, context, () => action(payload, context), guard);
  },
};
