const TaskHelper = {
  getStatusIcon(status) {
    if (status) {
      switch (status) {
        case 'open':
          return 'mdi-clipboard-outline';
        case 'closed':
          return 'mdi-clipboard-check-outline';
        case 'canceled':
          return 'mdi-clipboard-alert+';
      }
    }
    return 'mdi-clipboard-outline';
  },
  getStatusIconColor(status) {
    if (status) {
      switch (status) {
        case 'open':
          return '#616161';
        case 'closed':
          return '#66BB6A';
        case 'canceled':
          return '#ef5350';
      }
    }
    return '#616161';
  },
  getStatusIconMaterialColor(status) {
    switch (status) {
      case 'open':
        return 'grey darken-2';
      case 'closed':
        return 'green lighten-1';
      case 'canceled':
        return 'error lighten-1';
    }
    return 'grey darken-2';
  },
  getContrastColor(colorHex) {
    if (colorHex) {
      let r = parseInt(colorHex.substr(0, 2), 16);
      let g = parseInt(colorHex.substr(2, 2), 16);
      let b = parseInt(colorHex.substr(4, 2), 16);
      let yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq >= 128 ? 'black' : 'white';
    }
  },
  getPriorityIcon(priority) {
    switch (priority) {
      case 'low':
        return 'mdi-signal-cellular-1';
      case 'normal':
        return 'mdi-signal-cellular-2';
      case 'high':
        return 'mdi-signal-cellular-3';
      case 'urgent':
        return 'mdi-alert-rhombus';
    }
  },
  getPriorityColor(priority) {
    switch (priority) {
      case 'low':
        return 'info';
      case 'normal':
        return 'success';
      case 'high':
        return 'warning';
      case 'urgent':
        return 'error';
    }
  },

  mapDefaultTreeItems(items) {
    return items.map((task) => ({
      ...task,
      collapsed: true,
      children: [],
      loadChildren: false,
    }));
  },

  getMarkerSvgString(color, content, showDot) {
    return `
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 808 2220"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="xMidYMid meet"
      >
        <g>
          <g 
            id="svg_1"
            fill="${color}"
            transform="translate(0 1280) scale(0.1 -0.1)"
            stroke="#ffffff"
            stroke-width="400"
          >
            <path
              id="svg_2"
              d="m3786.36134,12552.77167c-529,-39 -994,-164 -1450,-390c-333,-164 -563,-319 -850,-570c-677,-593 -1145,-1475 -1249,-2360c-9,-73 -21,-170 -26,-214c-23,-185 -5,-611 36,-865c96,-591 311,-1125 649,-1610c28,-41 83,-124 120,-185c37,-60 96,-155 130,-210c54,-87 287,-462 449,-725c29,-47 93,-150 143,-230c49,-80 127,-206 173,-280c46,-74 134,-216 195,-315c61,-99 194,-313 295,-475c100,-162 229,-369 285,-460c56,-91 203,-327 326,-525c287,-463 413,-667 589,-950c78,-126 185,-300 238,-385c125,-203 181,-290 186,-290c2,0 25,35 51,78c26,42 58,93 70,112c12,19 52,82 87,140c36,58 107,173 158,255c51,83 158,255 237,383c122,198 256,415 618,997c93,149 1037,1671 1470,2370c208,336 410,657 450,715c115,168 183,284 271,460c215,431 336,846 390,1339c19,177 16,659 -6,816c-69,511 -206,950 -420,1345c-96,177 -110,201 -196,330c-529,795 -1342,1367 -2266,1594c-162,40 -206,48 -408,75c-279,38 -508,48 -745,30z"/>
          </g>
          <style> .text { font: bold 300px sans-serif } </style>
          <text xml:space="preserve" text-anchor="middle" id="svg_3" y="520" x="405"
            fill="#ffffff" class="text">${content || '-'}</text>
          ${
            showDot &&
            '<ellipse stroke="#000000" stroke-width="6" ry="109.45777" rx="109.45777" id="svg_4" cy="112.95413" cx="645.88813" fill="red"/>'
          }
        </g>
      </svg>
    `;
  },
};

export default TaskHelper;
