import httpClient from '../httpClient';

const getSessions = (projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sessions`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getWorkflowSessions = (projectId, workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sessions`, {
        params: {
          'filter[workflow]': workflowId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSBSSessions = (projectId, SBSCode) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/sessions`, {
        params: {
          'filter[sbs_code]': SBSCode,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const createSession = (projectId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${projectId}/session`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateSession = (projectId, sessionId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/project/${projectId}/session/${sessionId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteSession = (projectId, sessionId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/session/${sessionId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getSessionFlow = (projectId, sessionId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/project/${projectId}/session/${sessionId}/flow`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getSessions,
  getWorkflowSessions,
  getSBSSessions,
  createSession,
  updateSession,
  deleteSession,
  getSessionFlow,
};
