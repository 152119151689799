import { waitForVuex } from '@/utils/vuex';
import {
  createPermission,
  updatePermission,
} from '@/services/api/permission.api';
import { getTeamPermissions } from '@/services/api/teams.api';

const state = {
  teamPermissions: [],
};

const mutations = {
  team_permissions_success(state, permissions) {
    state.teamPermissions = permissions;
  },
  team_permission_create_success(state, permission) {
    state.teamPermissions.push(permission);
  },
  team_permission_update_success(state, updatedPermission) {
    Object.assign(
      state.teamPermissions.find(
        (permission) => permission.id === updatedPermission.id
      ),
      updatedPermission
    );
  },
};
const actions = {
  getTeamPermissions: waitForVuex(
    'team.permissions.get',
    ({ commit }, { teamId, projectId }) => {
      return getTeamPermissions(teamId, projectId)
        .then((permissions) => {
          commit('team_permissions_success', permissions);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  createTeamPermission: waitForVuex(
    'team.table.permission.create',
    ({ commit }, { body }) => {
      return createPermission(body)
        .then((response) => {
          commit('team_permission_create_success', response);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  updateTeamPermission: waitForVuex(
    'team.table.permission.update',
    ({ commit }, { permissionId, body }) => {
      return updatePermission(permissionId, body)
        .then((response) => {
          commit('team_permission_update_success', response);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
};
const getters = {
  teamPermissions: (state) => state.teamPermissions,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
