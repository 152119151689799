import { getModuleData } from '@/services/api/module.api';
import moment from 'moment';
import { getTasks } from '@/services/api/task.api';
import {
  createRecord,
  deleteRecord,
  getRecords,
  updateRecord,
} from '@/services/api/record.api';
import {
  createRecordV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import { queryTasksV2 } from '@/services/api/v2/tasks.v2.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
  placementTasks: [],

  elementContentsFilter: null,
  objectsFilter: null,
};
const mutations = {
  module_manual_import_request(state) {
    state.moduleStatus = 'loading';
    state.moduleData = undefined;
  },
  module_manual_import_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_manual_import_placement_tasks_success(state, data) {
    state.placementTasks = data;
  },
  module_manual_import_module_create_success(state, record) {
    state.moduleData.CFFA_DHME_MODULES.records.push(record);
  },
  module_manual_import_module_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_MODULES.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_manual_import_module_delete_success(state, deletedId) {
    state.moduleData.CFFA_DHME_MODULES.records =
      state.moduleData.CFFA_DHME_MODULES.records.filter(
        (item) => item.id !== deletedId
      );
  },
  module_manual_import_module_contents_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_MODULE_CONTENTS.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_manual_import_module_contents_create_success(state, record) {
    state.moduleData.CFFA_DHME_MODULE_CONTENTS.records.push(record);
  },
  module_manual_import_element_contents_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_ELEMENT_CONTENTS.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_manual_import_element_contents_create_success(state, record) {
    state.moduleData.CFFA_DHME_ELEMENT_CONTENTS.records.push(record);
  },
  module_manual_import_object_create_success(state, record) {
    state.moduleData.CFFA_DHME_OBJECTS_REGISTER.records.push(record);
  },
  module_manual_import_object_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_OBJECTS_REGISTER.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_manual_import_object_delete_success(state, deletedId) {
    state.moduleData.CFFA_DHME_OBJECTS_REGISTER.records =
      state.moduleData.CFFA_DHME_OBJECTS_REGISTER.records.filter(
        (item) => item.id !== deletedId
      );
  },
  module_manual_import_get_generations_success(state, records) {
    state.moduleData.CFFA_DHME_MANUAL_IMPORT_GENERATIONS.records = records;
  },

  module_manual_import_update_element_contents_filter(state, category) {
    state.elementContentsFilter = category;
  },
  module_manual_import_update_objects_filter(state, category) {
    state.objectsFilter = category;
  },
  module_manual_import_set_new_generation(state, record) {
    state.moduleData.CFFA_DHME_MANUAL_IMPORT_GENERATIONS.records.push(record);
  },
};
const actions = {
  setTemporaryGenerationRecord({ commit, getters }, type) {
    commit('module_manual_import_set_new_generation', {
      id: '-1',
      user: getters.authenticatedUser.name,
      datetime: moment().unix(),
      type: type,
    });
  },
  async manualImportFetchPlacementPhases({ commit, getters }) {
    let { tasks } = await queryTasksV2(getters.selectedLicense.id, [
      {
        column: 'project',
        operator: '=',
        values: [getters.project.id],
      },
      {
        column: 'type',
        operator: '=',
        values: ['dhme-placement-phase'],
      },
    ]);
    commit('module_manual_import_placement_tasks_success', tasks);
  },
  manualImportFetchElementAndObjectGenerations({ commit, getters }) {
    return getRecords(
      getters.project.id,
      getters.manualImportData.CFFA_DHME_MANUAL_IMPORT_GENERATIONS.id
    ).then((records) => {
      commit('module_manual_import_get_generations_success', records);
    });
  },
  loadManualImportModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_manual_import_request');
    return getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_manual_import_success', data);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  async manualImportCreateRecord({ commit, getters }, recordBody) {
    let record = await createRecordV2(
      getters.manualImportData.CFFA_DHME_MODULES.id,
      recordBody
    );
    commit('module_manual_import_module_create_success', record);
  },
  async manualImportUpdateRecord(
    { commit, getters },
    { recordId, recordBody }
  ) {
    let record = await updateRecordV2(
      getters.manualImportData.CFFA_DHME_MODULES.id,
      recordId,
      recordBody
    );
    commit('module_manual_import_module_update_success', record);
  },
  async manualImportDeleteRecord({ commit, getters }, recordId) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportData.CFFA_DHME_MODULES.id,
      },
    };
    let record = await deleteRecord(recordId, body);
    commit('module_manual_import_module_delete_success', recordId);
  },
  async manualImportUpdateModuleContentsRecord(
    { commit, getters },
    { recordId, recordBody }
  ) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportModuleContentsTable,
      },
      record: recordBody,
    };
    let record = await updateRecord(recordId, body);
    commit('module_manual_import_module_contents_update_success', record);
  },
  async manualImportCreateModuleContentsRecord(
    { commit, getters },
    { recordBody }
  ) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportModuleContentsTable,
      },
      record: recordBody,
    };
    let record = await createRecord(body);
    commit('module_manual_import_module_contents_create_success', record);
  },
  async manualImportCreateElementContentsRecord(
    { commit, getters },
    { recordBody }
  ) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportElementContentsTable,
      },
      record: recordBody,
    };
    let record = await createRecord(body);
    commit('module_manual_import_element_contents_create_success', record);
  },
  async manualImportUpdateElementContentsRecord(
    { commit, getters },
    { recordId, recordBody }
  ) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportElementContentsTable,
      },
      record: recordBody,
    };
    let record = await updateRecord(recordId, body);
    commit('module_manual_import_element_contents_update_success', record);
  },
  async manualImportCreateObjectRecord({ commit, getters }, { recordBody }) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportObjectsRegisterTable,
      },
      record: recordBody,
    };
    let record = await createRecord(body);
    commit('module_manual_import_object_create_success', record);
  },
  async manualImportUpdateObjectRecord(
    { commit, getters },
    { recordId, recordBody }
  ) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportObjectsRegisterTable,
      },
      record: recordBody,
    };
    let record = await updateRecord(recordId, body);
    commit('module_manual_import_object_update_success', record);
  },
  async manualImportDeleteObjectRecord({ commit, getters }, recordId) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: getters.manualImportObjectsRegisterTable,
      },
    };
    await deleteRecord(recordId, body);
    commit('module_manual_import_object_delete_success', recordId);
  },
};
const getters = {
  manualImportData: (state) => state.moduleData,
  manualImportStatus: (state) => state.moduleStatus,
  manualImportObjectsRegister: (state) =>
    state.moduleData.CFFA_DHME_OBJECTS_REGISTER.records,
  manualImportObjectsRegisterTable: (state) =>
    state.moduleData.CFFA_DHME_OBJECTS_REGISTER.id,
  manualImportModules: (state) => state.moduleData.CFFA_DHME_MODULES.records,
  manualImportElements: (state) => state.moduleData.CFFA_DHME_ELEMENTS.records,
  manualImportModulesTable: (state) => state.moduleData.CFFA_DHME_MODULES.id,
  manualImportModuleContents: (state) =>
    state.moduleData.CFFA_DHME_MODULE_CONTENTS.records,
  manualImportModuleContentsTable: (state) =>
    state.moduleData.CFFA_DHME_MODULE_CONTENTS.id,
  manualImportElementContents: (state) =>
    state.moduleData.CFFA_DHME_ELEMENT_CONTENTS.records,
  manualImportElementContentsTable: (state) =>
    state.moduleData.CFFA_DHME_ELEMENT_CONTENTS.id,
  manualImportPlacementTasks: (state) => state.placementTasks,
  manualImportLastElementGeneration: (state) => {
    return (
      state.moduleData.CFFA_DHME_MANUAL_IMPORT_GENERATIONS.records
        .filter((record) => record.type === 'elements')
        .sort((a, b) =>
          moment.unix(b.datetime).diff(moment.unix(a.datetime))
        )[0] ?? null
    );
  },
  manualImportLastObjectGeneration: (state) => {
    return (
      state.moduleData.CFFA_DHME_MANUAL_IMPORT_GENERATIONS.records
        .filter((record) => record.type === 'objects')
        .sort((a, b) =>
          moment.unix(b.datetime).diff(moment.unix(a.datetime))
        )[0] ?? null
    );
  },
  manualImportElementContentsFilter: (state) => state.elementContentsFilter,
  manualImportObjectsFilter: (state) => state.objectsFilter,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
