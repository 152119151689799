import httpClient from '../httpClient'

const getTokens = () => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/tokens`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const deleteToken = (data) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/token/${data.token_uuid}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}

const createToken = (data) => {
  return new Promise((resolve, reject) => {
    httpClient.post(`/token`, {
      "name": data.app_name,
    })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data.message)
      })
  });
}

export {
  getTokens,
  deleteToken,
  createToken
}
