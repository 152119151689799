<template>
  <div>
    Download is ready
    <span
      class="text-decoration-underline text-primary c-pointer"
      @click="downloadFile"
      >click here</span
    >
    to download file
  </div>
</template>

<script>
import NotificationRendererMixin from '@/components/Notifications/NotificationMessages/NotificationRendererMixin';
import LocalStorageService from '@/services/local-storage';
import appConfig from '@/appConfig';

export default {
  name: 'DownloadReady',
  mixins: [NotificationRendererMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    downloadFile() {
      let url =
        appConfig.VUE_APP_BASE_URL +
        appConfig.VUE_APP_API_URL +
        `/downloads/${this.data.downloadId}`;

      fetch(url, {
        headers: {
          Authorization: 'Bearer ' + LocalStorageService.getToken(),
        },
      }).then((response) => {
        const filename = this.data.downloadFilename;
        return response.blob().then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          setTimeout(() => {
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            this.emitOnAction();
          }, 0);
        });
      });
    },
  },
};
</script>

<style scoped></style>
