import {getModuleData} from "@/services/api/module.api";
import AutodeskService from "../../../services/forge/autodesk";

const state = {
    moduleData: undefined,
    moduleStatus: '',

    forgeToken: undefined,
    modelGuids: [],
    guidProperties: []
}
const mutations = {
    module_module_dashboard_request(state) {
        state.moduleStatus = 'loading'
    },
    module_model_dashboard_success(state, data) {
        state.moduleData = data
        let models = state.moduleData.CFFA_FORGE_MODELS.records;
        if (models.length > 0) {
            models[0].enabled = true
        }
        state.moduleStatus = 'success'
    },
}
const actions = {
    fetchModelDashboardModuleData({commit}, {projectId, moduleId, sessionId}) {
        commit('module_module_dashboard_request')
        return new Promise((resolve, reject) => {
            getModuleData(projectId, moduleId, sessionId)
                .then(data => {
                    commit('module_model_dashboard_success', data)
                    resolve(data)
                })
                .catch(error => {
                    this.commit('showNotification', {content: error.message, color: 'error'})
                    reject(error)
                })
        })
    },
}
const getters = {
    moduleDashboardData: state => state.moduleData,
    moduleDashboardStatus: state => state.moduleStatus,
    moduleDashboardModules: state => state.moduleData.CFFA_DHME_MODULES.records,
    moduleDashboardPhases: state => state.moduleData.CFFA_DHME_PHASES.records,
}

export default {
    state,
    mutations,
    actions,
    getters
}
