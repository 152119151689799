import { waitForVuex } from '@/utils/vuex';
import { getUserDownloads } from '@/services/api/downloads';

const state = {
  downloads: [],
};

const mutations = {
  downloads_success(state, downloads) {
    state.downloads = downloads;
  },
};

const actions = {
  fetchUserDownloads: waitForVuex('downloads.get', ({ commit }) => {
    return getUserDownloads()
      .then((downloads) => {
        commit('downloads_success', downloads);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  }),
};

const getters = {
  userDownloads: (state) => state.downloads,
};
export default {
  state,
  mutations,
  actions,
  getters,
};
