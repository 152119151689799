const DataHelper = {
  getTableColumns(data, addActions, hasSlot = false) {
    let columns = [];
    data.forEach((element) => {
      let column = {
        text: element.name,
        value: element.name,
        type: element.type,
        required: element.required,
      };
      if (hasSlot) {
        column.hasSlot = true;
      }
      columns.push(column);
    });
    if (addActions) {
      let actionsColumn = {
        text: 'Actions',
        value: 'actions',
        align: 'right',
        sortable: false,
      };
      columns.push(actionsColumn);
    }
    return columns;
  },

  /**
   * @description filters all keys to use as labels in table component
   * @param {Object} data api response data
   * @param {Boolean} includeId true = include id, false is not.
   * @returns {Array} array of keys, to use as labels
   */
  getAllKeys(data, includeId) {
    let keys = Object.keys(data[0]);
    let keysArray = [];
    keys.forEach((element) => {
      let label = { text: element, value: element };
      keysArray.push(label);
    });
    return includeId ? keysArray : keysArray.slice(1);
  },

  parseData(type, value, required) {
    if (!required) {
      if (value === undefined || value === null) {
        return value;
      }
    }

    switch (type) {
      case 'text':
        return value.toString();
      case 'text-field':
        return value.toString();
      case 'integer':
        return parseInt(value);
      case 'boolean':
        return value ?? false;
      case 'date':
        return value.toString();
      case 'link':
        return value.toString();
      case 'email':
        return value.toString();
      case 'dropdown':
        return value.toString();
      case 'float':
        return parseFloat(value);
      case 'sbscode':
        if (typeof value === 'object') {
          return value.code.toString();
        }
        return value.toString();
      case 'table':
        return value.toString();
      case 'default':
        return value.toString();
    }
  },

  createObjectTree(records) {
    let tree = [];
    // First add all records to the tree
    records.forEach((record) => {
      let newObject = {
        name: record.code,
        label: record.label,
        children: [],
      };
      tree.push(newObject);
    });

    // loop through each record
    records.forEach((record, index) => {
      // check if record has a parent
      if (record.parent !== null) {
        // find child tree object
        let child = tree.find((object) => object.name === record.code);

        if (child !== undefined) {
          this.searchTreeParentObject(tree, tree, child, record);
        } else {
          return null;
        }
      }
    });
    return tree;
  },

  searchTreeParentObject(baseTree, tree, child, record) {
    let parent = tree.find((object) => object.name === record.parent);
    if (parent !== undefined) {
      // add child to parent
      parent.children.push(child);

      //remove child object from base tree
      let childIndex = baseTree.findIndex((x) => x.name === child.name);
      baseTree.splice(childIndex, 1);
      return true;
    } else {
      tree.some((treeObject) => {
        return this.searchTreeParentObject(
          baseTree,
          treeObject.children,
          child,
          record
        );
      });
    }
  },

  getBooleanValue(value) {
    return value === true || value === 1 || value === '1' || value === 'true';
  },
};

export default DataHelper;
