import {
  createBoard,
  createBoardLane,
  deleteBoard,
  deleteBoardLane,
  getBoard,
  getBoards,
  updateBoard,
  updateBoardLane,
} from '@/services/api/boards.api';

const state = {
  boards: [],
  boardsStatus: '',

  board: null,
  boardStatus: '',
};
const mutations = {
  boards_request(state) {
    state.boardsStatus = 'loading';
  },
  boards_success(state, boards) {
    state.boards = boards;
    state.boardsStatus = 'success';
  },
  boards_create_success(state, board) {
    state.boards.push(board);
  },
  boards_delete_success(state, deletedId) {
    state.boards = state.boards.filter((board) => board.id !== deletedId);
  },
  board_success(state, board) {
    state.board = board;
  },
};
const actions = {
  fetchBoards({ commit, state, dispatch }) {
    commit('boards_request');
    return getBoards()
      .then((boards) => {
        commit('boards_success', boards);
        if (state.boards.length > 0) {
          if (!state.board) {
            const defaultBoard = boards.find((item) => item.default);
            if (defaultBoard) {
              dispatch('fetchBoard', defaultBoard.id);
            } else {
              dispatch('fetchBoard', boards[0].id);
            }
          }
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchBoard({ commit }, boardId) {
    return getBoard(boardId)
      .then((board) => {
        commit('board_success', board);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  createBoard({ commit, dispatch }, body) {
    return new Promise((resolve, reject) => {
      return createBoard(body)
        .then((board) => {
          commit('boards_create_success', board);
          dispatch('fetchBoard', board.id);
          resolve(board);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  updateBoard({ commit, dispatch }, { boardId, body }) {
    return new Promise((resolve, reject) => {
      return updateBoard(boardId, body)
        .then((board) => {
          commit('board_success', board);
          resolve(board);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  deleteBoard({ commit, state, dispatch }, boardId) {
    return new Promise((resolve, reject) => {
      return deleteBoard(boardId)
        .then((response) => {
          commit('boards_delete_success', boardId);
          if (state.boards.length > 0) {
            dispatch('fetchBoard', state.boards[0].id);
          }
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  createBoardLane({ commit, dispatch }, { boardId, body }) {
    return new Promise((resolve, reject) => {
      return createBoardLane(boardId, body)
        .then((board) => {
          commit('board_success', board);
          resolve(board);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  updateBoardLane({ commit, dispatch }, { boardId, laneId, body }) {
    return new Promise((resolve, reject) => {
      return updateBoardLane(boardId, laneId, body)
        .then((board) => {
          commit('board_success', board);
          resolve(board);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  deleteBoardLane({ commit, dispatch }, { boardId, laneId }) {
    return new Promise((resolve, reject) => {
      return deleteBoardLane(boardId, laneId)
        .then((board) => {
          commit('board_success', board);
          resolve(board);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
};
const getters = {
  boards: (state) => state.boards,
  boardsStatus: (state) => state.boardsStatus,
  board: (state) => state.board,
  boardStatus: (state) => state.boardStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
