import { getTables } from '@/services/api/table.api';
import { getColumns } from '@/services/api/column.api';
import {
  getRecords,
  getRecordsMax,
  updateRecord,
} from '@/services/api/record.api';

const state = {
  baseAntTable: undefined,
  selectedTable: undefined,
  antTables: [],

  tableHeaders: [],
  tableColumns: [],
  tableRows: [],
  tableLoading: false,
};
const mutations = {
  set_tables(state, tables) {
    state.antTables = tables.filter((table) => !table.name.startsWith('CFFA_'));
  },
  select_table(state, table) {
    state.selectedTable = table;
  },
  set_base_table(state, table) {
    state.baseAntTable = table;
  },
};
const actions = {
  fetch({ commit, dispatch, rootGetters }) {
    getTables(rootGetters.project.id)
      .then((tables) => {
        commit('set_tables', tables);
        dispatch('selectDefaultTable');
        dispatch('getTableData');
      })
      .catch((error) => {
        commit(
          'showNotification',
          {
            content: error.message,
            color: 'error',
          },
          { root: true }
        );
      });
  },
  getTableData({ state, rootGetters }) {
    if (state.selectedTable) {
      state.tableLoading = true;
      getColumns(rootGetters.project.id, state.selectedTable.id).then(
        (columns) => {
          let keysArray = [];
          columns.forEach((column) => {
            let label = { text: column.name, value: column.name };
            if (column.relation === null && column.type !== 'document') {
              label.hasSlot = true;
            }
            keysArray.push(label);
          });
          state.tableHeaders = keysArray;
          state.tableColumns = columns;
        }
      );
      getRecordsMax(rootGetters.project.id, state.selectedTable.id).then(
        (records) => {
          state.tableRows = records;
          state.tableLoading = false;
        }
      );
    } else {
      state.tableHeaders = [];
      state.tableColumns = [];
      state.tableRows = [];
      state.tableLoading = false;
    }
  },
  updateRecord({ rootGetters, state }, { recordId, body }) {
    return updateRecord(recordId, body).catch(() => {
      getRecords(rootGetters.project.id, state.selectedTable).then(
        (records) => {
          state.tableRows = records;
        }
      );
    });
  },
  selectDefaultTable({ commit, dispatch }) {
    if (state.baseAntTable) {
      state.antTables.some((table) => {
        if (table.id === state.baseAntTable) {
          commit('select_table', table);
          return true;
        }
      });
    } else {
      commit('select_table', undefined);
    }
  },
};
const getters = {
  antTables: (state) => state.antTables,
  selectedTable: (state) => state.selectedTable,
  tableLoading: (state) => state.tableLoading,
  tableHeaders: (state) => state.tableHeaders,
  tableColumns: (state) => state.tableColumns,
  tableRows: (state) => state.tableRows,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
