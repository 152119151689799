<template>
  <v-app>
    <v-alert v-if="isPortrait" type="info" dense class="mb-0" tile
      >Portrait mode detected. ANT works best in landscape mode
      <v-icon class="ml-2">mdi-phone-rotate-landscape</v-icon></v-alert
    >
    <v-main class="app" style="overflow-y: hidden">
      <transition mode="out-in" name="scale-fade-up">
        <router-view />
      </transition>
    </v-main>
    <notification />
  </v-app>
</template>

<script>
import LocalStorageService from '@/services/local-storage';
import viewportStyleMixin from '@/Mixins/ViewportStyleMixin';
export const APP_TITLE = 'ANT CDE';
export default {
  name: 'App',
  metaInfo() {
    return { title: `${APP_TITLE}` };
  },
  components: {
    Notification: () =>
      import(/* webpackPrefetch: true */ './components/Notification'),
  },
  mixins: [viewportStyleMixin],
  mounted() {
    let expires = LocalStorageService.getTokenExpiresIn();
    if (Date.now() > expires && LocalStorageService.getToken() !== null) {
      LocalStorageService.removeToken();
      LocalStorageService.removeTokenExpiresIn();
      this.$router.push({ name: 'Login' });
    }
  },
};
</script>

<style lang="scss">
@import 'css/main.scss';
@import 'css/transitions.scss';

.app {
  background-color: #e6e9f0;
}
</style>
