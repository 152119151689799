/**
 * Returns the session completion percentage
 * @param {object} session
 * @returns {number}
 */
export function getSessionProgress(session) {
  let total = session.jobs.length;
  let completed = session.jobs.filter(
    (job) => job.status === 'completed'
  ).length;

  return Math.floor((completed / total) * 100);
}

export function compressAndConvertImageFileToBase64(file, maxSizePx = 100) {
  return new Promise((resolve) => {
    const maxSizeKB = 500 * 1024; // 500 KB in bytes
    const reader = new FileReader();
    reader.onload = function (event) {
      const img = new Image();

      img.onload = function () {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        let width = img.width;
        let height = img.height;
        let radius = img.height;

        if (width > maxSizePx || height > maxSizePx || img.size > maxSizeKB) {
          if (width > height) {
            height *= maxSizePx / width;
            width = maxSizePx;
            radius = height / 2;
          } else {
            width *= maxSizePx / height;
            height = maxSizePx;
            radius = width / 2;
          }
        }

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        const base64 = canvas.toDataURL('image/png');

        resolve(base64);
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
}
