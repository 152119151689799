import { getModuleData } from '@/services/api/module.api';
import { GIS_VIEWER_WIDGETS } from '@/components/Modules/GisViewer';
import viewer from '@/components/Modules/GisViewer/viewer';
import flatMap from 'lodash/flatMap';
import ColorHelper from '@/services/color-helper';
import TaskHelper from '@/services/task-helper';
import { GISVIEWER } from '@/modules/modules';

function filterLayersByType(layers, type) {
  return layers.filter((l) => l?.[`view_${type}`]);
}

const state = {
  moduleData: undefined,
  sketchData: undefined,
  viewer: {
    // Data that not linked to ArcGis classes
    viewType: '2d',
    searchable: [],
    featureTableConfigs: [],
    featureTableSelected: null,
    selectedFeatures: [],
    updated: null,
    showSketchPanel: false,
    showBasemapGallery: false,
    mapActionsDialog: false,
    currentPointOptions: {},
    showRfiDialog: false,
    baseWkid: 102100,
  },
};

const mutations = {
  set_data(state, data) {
    state.moduleData = data;
  },
  clear_data(state) {
    state.moduleData = undefined;
    state.viewer = {
      ...state.viewer,
      searchable: [],
      featureTableConfigs: [],
      featureTableSelected: null,
      selectedFeatures: [],
      updated: null,
      showSketchPanel: false,
      showBasemapGallery: false,
      showDefaultBasemapGallery: false,
      showRfiDialog: false,
    };

    viewer.map = undefined;
    viewer.viewMap = undefined;
    viewer.viewScene = undefined;
    viewer.viewActive = undefined;
    viewer.classes = {};
    viewer.groupLayers = [];
    viewer.featureLayers = [];
    viewer.imageryLayers = [];
    viewer.mapImageLayers = [];
    viewer.tileLayers = [];
    viewer.vectorTileLayers = [];
    viewer.wmsLayers = [];
    viewer.wfsLayers = [];
    viewer.pointCloudLayers = [];
    viewer.sceneLayers = [];
    viewer.subLayers = [];
    viewer.sketchLayers = [];
    viewer.graphicsLayers = [];
    viewer.rfiLayers = [];
    viewer.featureTable = undefined;
    viewer.measurement = undefined;
    viewer.highlightHandle = undefined;
    viewer.widgets = {
      [GIS_VIEWER_WIDGETS.HOME]: undefined,
      [GIS_VIEWER_WIDGETS.LAYER_LIST]: undefined,
      [GIS_VIEWER_WIDGETS.SEARCH]: undefined,
      [GIS_VIEWER_WIDGETS.ZOOM]: undefined,
      // [GIS_VIEWER_WIDGETS.BASEMAP_TOGGLE]: undefined,
      [GIS_VIEWER_WIDGETS.BASEMAP_GALLERY]: undefined,
      [GIS_VIEWER_WIDGETS.MEASUREMENT]: undefined,
    };
    viewer.defaults = {
      viewConfig: {
        center: [-118.805, 34.027], // Longitude, latitude
        zoom: 13, // Zoom level
      },
    };
  },
  toggle_sketch_panel(state, { value }) {
    state.viewer.showSketchPanel = value;
  },
  toggle_show_basemap_gallery(state, { value }) {
    state.viewer.showBasemapGallery = value;
  },
  toggle_map_actions_dialog(state, value) {
    state.viewer.mapActionsDialog = value;
  },
  toggle_show_rfi_dialog(state, value) {
    state.viewer.showRfiDialog = value;
  },
  current_map_point_success(state, value) {
    state.viewer.currentPointOptions = { ...value, module: 'gis' };
  },
  set_sketch_data(state, value) {
    state.sketchData = value;
  },
};

const actions = {
  async fetchData(
    { commit, dispatch, state },
    { projectId, moduleId, sessionId }
  ) {
    commit('clear_data');
    dispatch('fetchRfiTasks', {}, { root: true });
    return getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('set_data', {
          properties:
            data.CFFA_GIS_PROPERTIES.records.length &&
            data.CFFA_GIS_PROPERTIES.records[0]
              ? {
                  ...data.CFFA_GIS_PROPERTIES.records[0],
                  center: JSON.parse(
                    data.CFFA_GIS_PROPERTIES.records[0].center
                  ),
                  basemaps: JSON.parse(
                    data.CFFA_GIS_PROPERTIES.records[0].basemaps || '[]'
                  ),
                }
              : {},
          layers: {
            CFFA_GIS_GROUP_LAYERS: data.CFFA_GIS_GROUP_LAYERS,
            CFFA_GIS_SUB_LAYERS: data.CFFA_GIS_SUB_LAYERS,
            CFFA_GIS_FEATURE_LAYERS: data.CFFA_GIS_FEATURE_LAYERS,
            CFFA_GIS_IMAGERY_LAYERS: data.CFFA_GIS_IMAGERY_LAYERS,
            CFFA_GIS_MAP_IMAGE_LAYERS: data.CFFA_GIS_MAP_IMAGE_LAYERS,
            CFFA_GIS_TILE_LAYERS: data.CFFA_GIS_TILE_LAYERS,
            CFFA_GIS_VECTOR_TILE_LAYERS: data.CFFA_GIS_VECTOR_TILE_LAYERS,
            CFFA_GIS_WMS_LAYERS: data.CFFA_GIS_WMS_LAYERS,
            CFFA_GIS_SCENE_LAYERS: data.CFFA_GIS_SCENE_LAYERS,
            CFFA_GIS_BUILDING_SCENE_LAYERS: data.CFFA_GIS_BUILDING_SCENE_LAYERS,
            CFFA_GIS_PC_LAYERS: data.CFFA_GIS_PC_LAYERS,
            CFFA_GIS_VOXEL_LAYERS: data.CFFA_GIS_VOXEL_LAYERS,
            CFFA_GIS_WFS_LAYERS: data.CFFA_GIS_WFS_LAYERS,
          },
          layers2d: [
            'CFFA_GIS_GROUP_LAYERS',
            // 'CFFA_GIS_SUB_LAYERS',
            'CFFA_GIS_FEATURE_LAYERS',
            'CFFA_GIS_IMAGERY_LAYERS',
            'CFFA_GIS_MAP_IMAGE_LAYERS',
            'CFFA_GIS_TILE_LAYERS',
            'CFFA_GIS_VECTOR_TILE_LAYERS',
            'CFFA_GIS_WMS_LAYERS',
            'CFFA_GIS_WFS_LAYERS',
            // 3D
            // 'CFFA_GIS_SCENE_LAYERS',
            // 'CFFA_GIS_BUILDING_SCENE_LAYERS',
            // 'CFFA_GIS_PC_LAYERS',
            // 'CFFA_GIS_VOXEL_LAYERS',
          ],
          layers3d: [
            'CFFA_GIS_GROUP_LAYERS',
            // 'CFFA_GIS_SUB_LAYERS',
            'CFFA_GIS_FEATURE_LAYERS',
            'CFFA_GIS_IMAGERY_LAYERS',
            'CFFA_GIS_MAP_IMAGE_LAYERS',
            'CFFA_GIS_TILE_LAYERS',
            'CFFA_GIS_VECTOR_TILE_LAYERS',
            'CFFA_GIS_WMS_LAYERS',
            'CFFA_GIS_WFS_LAYERS',
            // 3D
            'CFFA_GIS_SCENE_LAYERS',
            'CFFA_GIS_BUILDING_SCENE_LAYERS',
            'CFFA_GIS_PC_LAYERS',
            'CFFA_GIS_VOXEL_LAYERS',
          ],
          classNames: {
            CFFA_GIS_GROUP_LAYERS: 'GroupLayer',
            CFFA_GIS_FEATURE_LAYERS: 'FeatureLayer',
            CFFA_GIS_IMAGERY_LAYERS: 'ImageryLayer',
            CFFA_GIS_MAP_IMAGE_LAYERS: 'MapImageLayer',
            CFFA_GIS_TILE_LAYERS: 'TileLayer',
            CFFA_GIS_VECTOR_TILE_LAYERS: 'VectorTileLayer',
            CFFA_GIS_WMS_LAYERS: 'WMSLayer',
            CFFA_GIS_SCENE_LAYERS: 'SceneLayer',
            CFFA_GIS_BUILDING_SCENE_LAYERS: 'BuildingSceneLayer',
            CFFA_GIS_PC_LAYERS: 'PointCloudLayer',
            CFFA_GIS_VOXEL_LAYERS: 'VoxelLayer',
            CFFA_GIS_WFS_LAYERS: 'WFSLayer',
          },
          cacheNames: {
            CFFA_GIS_GROUP_LAYERS: 'groupLayers',
            CFFA_GIS_FEATURE_LAYERS: 'featureLayers',
            CFFA_GIS_IMAGERY_LAYERS: 'imageryLayers',
            CFFA_GIS_MAP_IMAGE_LAYERS: 'mapImageLayers',
            CFFA_GIS_TILE_LAYERS: 'tileLayers',
            CFFA_GIS_VECTOR_TILE_LAYERS: 'vectorTileLayers',
            CFFA_GIS_WMS_LAYERS: 'wmsLayers',
            CFFA_GIS_SCENE_LAYERS: 'sceneLayers',
            CFFA_GIS_BUILDING_SCENE_LAYERS: 'buildingSceneLayers',
            CFFA_GIS_PC_LAYERS: 'pointCloudLayers',
            CFFA_GIS_VOXEL_LAYERS: 'voxelLayers',
            CFFA_GIS_WFS_LAYERS: 'wfsLayers',
          },
        });
        const baseTable = state.moduleData?.properties?.base_ant_table;
        if (baseTable) {
          commit('antTables/set_base_table', baseTable, {
            root: true,
          });
        }
      })
      .catch((error) => {
        commit(
          'showNotification',
          {
            content: error.message,
            color: 'error',
          },
          { root: true }
        );
      });
  },
  forceUpdate() {
    state.viewer.updated = Math.random();
  },
  setViewerProp({ state }, { key, value }) {
    const keys = key.split('.');
    let obj = viewer;
    while (keys.length > 1) {
      if (!obj) throw new Error('Access to not existed property');
      obj = obj[keys.shift()];
    }
    obj[keys.shift()] = value;
    state.viewer.updated = Math.random();
  },
  setViewerStoreProp({ state }, { key, value }) {
    const keys = key.split('.');
    let obj = state.viewer;
    while (keys.length > 1) {
      if (!obj) throw new Error('Access to not existed property');
      obj = obj[keys.shift()];
    }
    obj[keys.shift()] = value;
    state.viewer.updated = Math.random();
  },
  deleteViewerProp({ state }, { key }) {
    const keys = key.split('.');
    let obj = viewer;
    while (keys.length > 1) {
      if (!obj) throw new Error('Access to not existed property');
      obj = obj[keys.shift()];
    }
    delete obj[keys.shift()];
    state.viewer.updated = Math.random();
  },
  loadMap({ dispatch }, config = {}) {
    viewer.classes.esriConfig.apiKey =
      config.token || state.moduleData.properties.token;

    const map = new viewer.classes.Map({
      ...state.moduleData.properties,
      ...config,
    });
    const nc = state.moduleData.properties.navigationConstraint;
    map.ground.navigationConstraint = {
      type: nc && ['stay-above', 'none'].includes(nc) ? nc : 'none',
    };
    dispatch('setViewerProp', { key: 'map', value: map });
  },
  loadViewMap({ dispatch, commit }, sceneConfig = {}) {
    const view = new viewer.classes.MapView({
      map: viewer.map,
      container: null,
      center: state.moduleData.properties.center,
      zoom: state.moduleData.properties.zoom,
      ...sceneConfig,
    });
    view
      .when(() => {
        if (
          state.moduleData.properties.basemap_opacity !== undefined &&
          state.moduleData.properties.basemap_opacity !== null
        ) {
          viewer.map.basemap.baseLayers.forEach((layer) => {
            layer.opacity = state.moduleData.properties.basemap_opacity;
          });
        }
        if (
          state.moduleData.properties.ground_opacity !== undefined &&
          state.moduleData.properties.ground_opacity !== null
        ) {
          viewer.map.ground.opacity =
            state.moduleData.properties.ground_opacity;
        }
      })
      .then(() => {
        view.on('immediate-click', (e) => {
          dispatch('mapClickHandler', { event: e, layer: view });
        });
      });

    dispatch('setViewerProp', { key: 'viewMap', value: view });
  },
  loadViewScene({ dispatch }, options = {}) {
    const config = {
      map: viewer.map,
      container: null,
      viewingMode: 'local',
    };
    if (state.moduleData.properties.center) {
      config.center = state.moduleData.properties.center;
    }
    if (state.moduleData.properties.zoom) {
      config.zoom = state.moduleData.properties.zoom;
    }
    if (state.moduleData.properties.wkid) {
      config.spatialReference = { wkid: state.moduleData.properties.wkid };
    }
    const view = new viewer.classes.SceneView({ ...config, ...options });
    view.when().then(() => {
      view.on('immediate-click', (e) => {
        dispatch('mapClickHandler', { event: e, layer: view });
      });
    });
    dispatch('setViewerProp', { key: 'viewScene', value: view });
  },
  async switchToScene({ state, dispatch, commit }, type = '2d') {
    state.viewer.viewType = type;
    const viewpoint = viewer.viewActive?.viewpoint?.clone();
    const container = viewer.viewActive?.container;
    if (viewer.viewActive) viewer.viewActive.container = null;
    await dispatch('unloadLayers');
    if (type === '3d') {
      if (viewpoint) viewer.viewScene.viewpoint = viewpoint;
      if (container) viewer.viewScene.container = container;
      viewer.viewActive = viewer.viewScene;
      await dispatch('loadLayers', { type: '3d' });
    } else {
      if (viewpoint) viewer.viewMap.viewpoint = viewpoint;
      if (container) viewer.viewMap.container = container;
      viewer.viewActive = viewer.viewMap;
      await dispatch('loadLayers', { type: '2d' });
    }
    dispatch('removeEmptyGroups');
    dispatch('sortGroupsLayers');
    dispatch('reorderSketchLayer');
    dispatch('reloadWidgets');
    dispatch('loadSearchSources');
    viewer.map?.layers?.sort(
      (a, b) => a.index - b.index || a.title.localeCompare(b.title)
    );
    dispatch('forceUpdate');
  },
  reloadWidgets({ dispatch }) {
    Object.keys(viewer.widgets || {}).forEach((key) => {
      const widget = viewer.widgets[key];
      if (widget) {
        widget.destroy();
      }
      dispatch('loadWidget', { key });
    });
    if (viewer.featureTable) {
      viewer.featureTable.view = viewer.viewActive;
    }
  },
  addSpacer(_, { position, index, id }) {
    const view = viewer.viewActive;
    let spacer = document.getElementById(id);
    if (!spacer) {
      spacer = document.createElement('div');
      spacer.setAttribute('id', id);
      view.ui.add(spacer, position, index);
    }
  },
  removeWidget({ state, dispatch }, { key }) {
    if (!viewer.widgets[key]) {
      return;
    }
    viewer.widgets[key].destroy();
    if (key === GIS_VIEWER_WIDGETS.SKETCH) {
      viewer.sketchLayers?.[0].destroy();
      viewer.sketchLayers = [];
    }
    dispatch('deleteViewerProp', { key: `widgets.${key}` });
    dispatch('reloadWidgets');
  },
  async loadWidget({ state, dispatch }, { key }) {
    const view = viewer.viewActive;
    let widget = undefined;

    switch (key) {
      case GIS_VIEWER_WIDGETS.HOME: {
        widget = new viewer.classes.Home({
          view,
        });
        view.ui.add(widget, 'top-left');
        break;
      }

      case GIS_VIEWER_WIDGETS.ZOOM: {
        widget = new viewer.classes.Zoom({
          view,
          visible: false,
        });
        view.ui.add(widget, 'top-left');
        break;
      }

      case GIS_VIEWER_WIDGETS.SEARCH: {
        widget = new viewer.classes.Search({
          view,
          autoSelect: false,
          sources: [],
        });
        view.ui.add(widget, 'top-left');
        break;
      }

      case GIS_VIEWER_WIDGETS.LAYER_LIST: {
        widget = new viewer.classes.LayerList({
          view,
          listItemCreatedFunction: function (event) {
            const item = event.item;
            if (item.layer.type !== 'group') {
              item.panel = {
                content: 'legend',
                open: false,
              };
            }
          },
        });
        view.ui.add(widget, 'top-right');
        break;
      }

      case GIS_VIEWER_WIDGETS.BASEMAP_TOGGLE: {
        widget = new viewer.classes.BasemapToggle({
          view,
        });
        view.ui.add(widget, 'bottom-left');
        break;
      }

      case GIS_VIEWER_WIDGETS.BASEMAP_GALLERY: {
        let baseMaps = [];
        if (
          state.moduleData?.properties?.basemaps &&
          state.moduleData?.properties?.basemaps.length
        ) {
          baseMaps = state.moduleData?.properties?.basemaps?.map((key) =>
            viewer.classes.Basemap.fromId(key)
          );
        }

        if (state.moduleData?.properties?.basemaps_portal) {
          try {
            const portal = new viewer.classes.Portal({
              url: state.moduleData?.properties?.basemaps_portal,
            });
            await portal.load();
            baseMaps = [...baseMaps, ...(await portal.fetchBasemaps())];
          } catch (e) {
            console.error('portal-load-error:', e);
          }
        }

        if (!baseMaps.length) {
          baseMaps = [
            viewer.classes.Basemap.fromId('topo-vector'),
            viewer.classes.Basemap.fromId('arcgis-imagery'),
            viewer.classes.Basemap.fromId('arcgis-imagery-standard'),
          ];
        }

        dispatch('addSpacer', {
          position: 'bottom-left',
          id: 'spacer',
          index: 0,
        });

        widget = new viewer.classes.BasemapGallery({
          view: view,
          visible: state.viewer.showBasemapGallery,
          source: [...baseMaps],
        });
        view.ui.add(widget, 'bottom-left');

        if (
          state.moduleData.properties.basemap_opacity !== undefined &&
          state.moduleData.properties.basemap_opacity !== null
        ) {
          baseMaps.forEach((basemap) => {
            basemap.when(() => {
              basemap.baseLayers.forEach((layer) => {
                layer.opacity = state.moduleData.properties.basemap_opacity;
              });
            });
          });
        }
        break;
      }

      case GIS_VIEWER_WIDGETS.MEASUREMENT: {
        widget = new viewer.classes.Measurement({
          view,
        });
        view.ui.add(widget, 'bottom-right');
        break;
      }

      case GIS_VIEWER_WIDGETS.SKETCH: {
        let layer;
        if (!viewer.sketchLayers?.length) {
          const layers = await dispatch('addLayers', {
            configs: [{ title: 'Sketch', index: 99 }],
            className: 'GraphicsLayer',
            container: viewer.map,
            layersCache: viewer.sketchLayers,
          });
          layer = layers[0];
        } else {
          layer = viewer.sketchLayers[0];
        }
        widget = new viewer.classes.Sketch({
          view,
          layer,
          visible: state.viewer.showSketchPanel,
          // graphic will be selected as soon as it is created
          creationMode: 'update',
        });
        if (widget.viewModel) {
          // need to add color picker
          // need to width picker
          widget.viewModel.polylineSymbol = {
            type: 'simple-line',
            color: [0, 0, 0, 1],
            width: 2,
            style: 'solid',
          };
          widget.viewModel.polygonSymbol = {
            type: 'simple-fill',
            color: [0, 0, 0, 0.4],
            outline: {
              color: [0, 0, 0, 0.9],
              width: 2,
            },
          };
        }
        view.ui.add({
          component: widget,
          position: 'top-left',
          index: 0,
        });
        break;
      }
    }

    if (widget) {
      dispatch('setViewerProp', { key: `widgets.${key}`, value: widget });
    }
  },
  async clearCustomGraphics() {
    if (viewer.graphicsLayers.length) {
      viewer.graphicsLayers?.forEach((l) => l.destroy());
      viewer.graphicsLayers = [];
      state.viewer.updated = Math.random();
    }
  },
  async loadNewGraphics(
    { commit, dispatch, getters },
    { title = 'Drawing', json, config = {} } = {}
  ) {
    if (!json) {
      commit(
        'showNotification',
        {
          content: 'Error on graphics loading, empty data',
          color: 'error',
        },
        { root: true }
      );
      return;
    }
    await dispatch('clearCustomGraphics');
    const graphics = JSON.parse(json);
    await dispatch('addLayers', {
      configs: [{ ...config, title, index: 99, graphics }],
      className: 'GraphicsLayer',
      container: viewer.map,
      layersCache: viewer.graphicsLayers,
    });
    state.viewer.updated = Math.random();
  },
  loadSearchSources({ dispatch, getters }, { layersIds } = {}) {
    let sources = [];
    let filteredLayers = getters.viewer.searchable;
    if (layersIds && Array.isArray(layersIds) && layersIds.length) {
      filteredLayers = filteredLayers.filter((layerConfig) =>
        layersIds.some((id) => id === layerConfig.id)
      );
    }
    filteredLayers.forEach((layerConfig, index) => {
      if (layerConfig.searchable) {
        const layer = getters.viewer.map.allLayers.find((layer) => {
          return layerConfig.id === layer.id;
        });
        if (layer) {
          let source = {
            layer,
            exactMatch: false,
          };
          if (layerConfig.search_fields) {
            let searchFields = JSON.parse(layerConfig.search_fields);
            source.searchFields = searchFields;
            source.displayField = searchFields[0];
            if (layerConfig.popup) {
              source.popupTemplate = JSON.parse(atob(layerConfig.popup.file));
            }
          }
          sources.push(source);
        }
      }
    });

    dispatch('setViewerProp', {
      key: `widgets.${GIS_VIEWER_WIDGETS.SEARCH}.sources`,
      value: sources,
    });
  },
  searchByCode({}, code) {
    if (!viewer.widgets?.search) {
      return [];
    }

    return viewer.widgets.search.search(code).then((event) => {
      return flatMap(event.results, (source) => source?.results || []);
    });
  },
  highlightFeatureClear({ dispatch }) {
    if (viewer.highlightHandle) {
      viewer.highlightHandle.remove();
      dispatch('setViewerProp', {
        key: 'highlightHandle',
        value: undefined,
      });
    }
  },
  highlightFeature({ dispatch }, { item }) {
    const layer = viewer.featureLayers.find(
      (l) => l.uid === item.feature.layer.uid
    );
    if (layer) {
      viewer.viewActive.goTo(item);
      viewer.viewActive.whenLayerView(layer).then((layerView) => {
        if (viewer.highlightHandle) {
          viewer.highlightHandle.remove();
        }
        const handle = layerView.highlight(item.feature);
        dispatch('setViewerProp', {
          key: 'highlightHandle',
          value: handle,
        });
      });
    }
  },

  async addLayers(
    { dispatch, getters, state },
    {
      configs,
      className,
      container: containerBase,
      layersCache,
      additionalConfig = {},
    }
  ) {
    return Promise.all(
      configs.map(async (config) => {
        const layer = viewer.map.findLayerById(config.id);
        if (layer) {
          return layer;
        }
        if (className === 'WMSLayer') {
          additionalConfig.featureInfoFormat = 'text/html';
          additionalConfig.featureInfoUrl = config.url;
          if (config.popup) {
            additionalConfig.fetchFeatureInfoFunction = async (query) => {
              query.info_format = 'application/json';
              const { data } = await viewer.classes.request(config.url, {
                query,
              });
              return data.features.map(
                (feature) =>
                  new viewer.classes.Graphic({
                    attributes: feature.properties,
                    popupTemplate: JSON.parse(atob(config.popup.file)),
                  })
              );
            };
          }
        }
        if (className === 'FeatureLayer') {
          config.outFields = ['*'];
        }

        const object = await dispatch('createLayer', {
          config: {
            ...config,
            ...additionalConfig,
          },
          className,
          sublayers: getters.getSubLayers(config.id),
        });

        let container = containerBase;
        if (config.searchable) {
          state.viewer.searchable.push(config);
        }
        if (config.is_table) {
          state.viewer.featureTableConfigs.push(config);
        }
        if (config.group) {
          const groupLayer = viewer.groupLayers.find((gl) => {
            return gl.title === config.group;
          });
          container = groupLayer || containerBase;
        }

        container.add(object);

        layersCache.push(object);

        if (className === 'WMSLayer') {
          object.when(() => {
            const layers = object?.allSublayers;
            layers?.forEach((l) => {
              l.popupEnabled = true;
              l.queryable = true;
            });
          });
        }

        if (className === 'WFSLayer') {
          if (!viewer?.viewMap?.popup?.defaultPopupTemplateEnabled) {
            viewer.viewMap.popup.defaultPopupTemplateEnabled = true; // enable popup on the wfslayer
          }
        }
        return object;
      })
    );
  },
  removeLayers(_, { configs, container, layersCache }) {
    configs.map((config) => {
      const layer = container.findLayerById(config.id);
      if (layer) {
        layer.destroy();
      }
    });
    if (layersCache) {
      viewer[layersCache] = viewer[layersCache].filter((l) => !l.destroyed);
    }
  },
  async loadLayers({ state, dispatch }, { type }) {
    if (['2d', '3d'].indexOf(type) === -1) {
      throw new Error(`Invalid type ${type}`);
    }

    const layerKeys = state.moduleData?.[`layers${type}`] || [];

    for (let key of layerKeys) {
      await dispatch('addLayers', {
        configs: filterLayersByType(
          state.moduleData.layers?.[key].records,
          type
        ),
        className: state.moduleData.classNames?.[key],
        container: viewer.map,
        layersCache: viewer[state.moduleData.cacheNames?.[key]],
      });
    }
  },
  async unloadLayers({ state, dispatch }) {
    state.moduleData?.layers2d.map((key) => {
      return dispatch('removeLayers', {
        configs: state.moduleData.layers?.[key].records,
        container: viewer.map,
        layersCache: state.moduleData.cacheNames?.[key],
      });
    });
    state.moduleData?.layers3d.map((key) => {
      return dispatch('removeLayers', {
        configs: state.moduleData.layers?.[key].records,
        container: viewer.map,
        layersCache: state.moduleData.cacheNames?.[key],
      });
    });
  },
  removeEmptyGroups() {
    if (viewer.groupLayers?.length) {
      viewer.groupLayers.forEach((gl) => {
        if (!gl.layers.length) {
          gl.destroy();
        }
      });
      viewer.groupLayers = viewer.groupLayers.filter((l) => !l.destroyed);
    }
  },
  sortGroupsLayers() {
    if (viewer.groupLayers?.length) {
      viewer.groupLayers.forEach((gl) => {
        if (gl.layers.length) {
          gl.layers.sort(
            (a, b) => a.index - b.index || a.title.localeCompare(b.title)
          );
        }
      });
    }
  },
  reorderSketchLayer({ state }, { index } = {}) {
    if (state.viewer.showSketchPanel) {
      const layer = viewer.sketchLayers[0];
      const ind = index || viewer.map.layers.length + 1;
      viewer.map.layers.reorder(layer, ind);
    }
  },
  createLayer(_, { config, className }) {
    const object = new viewer.classes[className]({
      ...config,
      index: className === 'GroupLayer' ? 98 : config.index,
    });
    if (config.popup && config.popup.file) {
      object.popupTemplate = JSON.parse(atob(config.popup.file));
    }
    return object;
  },
  mapClickHandler({ state, commit, dispatch, rootGetters }, { event, layer }) {
    const sr = JSON.parse(JSON.stringify(event.mapPoint.spatialReference));
    const mapPoint = {
      x: event.mapPoint.x,
      y: event.mapPoint.y,
      z: event.mapPoint.z,
      spatialReference: sr,
      screen: {
        x: event.x,
        y: event.y,
      },
    };
    if (event.button === 0) {
      if (state.viewer.mapActionsDialog) {
        commit('toggle_map_actions_dialog', false);
      }
      // Search for graphics at the clicked location
      layer.hitTest(event).then(function (response) {
        if (response.results.length) {
          // SBS search
          if (rootGetters.sbsSidebarToggle) {
            let feature = response.results.filter(
              (result) => result.graphic.layer.type === 'feature'
            )[0];
            if (feature) {
              console.log(feature);
              let attributes = feature.graphic.attributes;
              console.log(attributes);
              const sbscode = attributes?.SBSCODE;
              if (sbscode) {
                dispatch(
                  'searchSbsTree',
                  { searchValue: sbscode },
                  { root: true }
                );
              }
            } else {
              commit(
                'showNotification',
                {
                  content: 'SBSCode not found in selected feature',
                  color: 'warning',
                },
                { root: true }
              );
            }
          }

          // RFI
          const graphic = response.results.filter((result) => {
            return result.graphic.layer.title === 'RFI';
          });
          const attrs = graphic[0]?.graphic?.attributes;

          if (rootGetters.task && Object.keys(rootGetters?.task)?.length) {
            commit('task_clear', {}, { root: true });
          }
          if (attrs?.id) {
            dispatch('fetchTask', attrs.id, { root: true });
          }
        }
      });
    }
    if (event.button === 2) {
      commit('toggle_map_actions_dialog', false);
      commit('current_map_point_success', mapPoint);
      commit('toggle_map_actions_dialog', true);
    }
  },
  async createMarker({ state, dispatch }, { type, payload } = {}) {
    const { task_type, status, priority, number, assigned_to } = payload;
    const { rfi_x: x, rfi_y: y, rfi_z: z } = task_type;

    if (!x && !y) return undefined;

    const markerGeometry = {
      type,
      spatialReference: {
        wkid: state.viewer.baseWkid,
      },
    };
    switch (type) {
      case 'point':
        markerGeometry.x = x;
        markerGeometry.y = y;
        markerGeometry.z = z || 20;
        break;
      case 'polyline':
        markerGeometry.paths = [
          [x, y, 0],
          [x, y, z || 20],
        ];
        break;
    }
    const markerSymbol = await dispatch('getMarkerOptions', {
      type,
      status,
      number,
      assignedUserId: assigned_to?.id,
    });
    return await new viewer.classes.Graphic({
      geometry: markerGeometry,
      symbol: markerSymbol,
      attributes: payload,
    });
  },
  async loadRfiDrawingsLayer({ state, dispatch, getters }, data) {
    return Promise.all(
      data.map((rfi) => {
        const json = rfi?.task_type?.metadata;
        if (!json) return Promise.resolve();
        const graphics = JSON.parse(json);
        const title = `Drawings RFI ${rfi.number}`;
        return dispatch('addLayers', {
          configs: [{ id: `rfi-${rfi.id}`, title, index: 99, graphics }],
          className: 'GraphicsLayer',
          container: viewer.map,
          layersCache: viewer.graphicsLayers,
        });
      })
    );
  },
  async centerRfis() {
    let graphics = viewer.graphicsLayers?.reduce((graphics, layer) => {
      console.log('viewer.graphicsLayer:', layer, layer.graphics.items);
      return [...graphics, ...(layer?.graphics || [])];
    }, []);
    graphics = viewer.rfiLayers?.reduce((graphics, layer) => {
      console.log('viewer.rfiLayer:', layer, layer.graphics.items);
      return [...graphics, ...(layer?.graphics || [])];
    }, graphics);
    console.log('viewer.graphics', graphics);
    if (graphics) return viewer.viewActive.goTo(graphics);
  },
  async loadRfiLayer({ state, dispatch, getters }, data) {
    if (viewer.rfiLayers?.length) {
      viewer.rfiLayers[0].removeAll();
    } else {
      viewer.rfiLayers = [];
    }

    let graphics = await Promise.all(
      data.map(async (item) => {
        return await dispatch('createMarker', { type: 'point', payload: item });
      })
    );
    graphics = graphics.filter(Boolean);

    if (!graphics?.length) return;

    if (state.viewer.viewType === '3d') {
      let pillars = await Promise.all(
        data.map(async (item) => {
          if (item?.task_type?.rfi_z) {
            return null;
          }
          return await dispatch('createMarker', {
            type: 'polyline',
            payload: item,
          });
        })
      );
      pillars = pillars.filter(Boolean);
      graphics = graphics.concat(pillars);
    }

    if (state.moduleData?.properties?.wkid) {
      dispatch('projectGraphic', {
        data: graphics,
        outputWkid: state.moduleData.properties.wkid,
      });
    }

    if (viewer.rfiLayers?.length) {
      viewer.rfiLayers[0].addMany(graphics);
    } else {
      await dispatch('addLayers', {
        configs: [
          {
            title: 'RFI',
            index: 99,
            graphics,
          },
        ],
        className: 'GraphicsLayer',
        container: viewer.map,
        layersCache: viewer.rfiLayers,
      });
    }
  },
  async convertPointCoordinates({ state, commit, dispatch }, point) {
    const baseWkid = state.viewer.baseWkid;
    if (point.spatialReference.wkid !== baseWkid) {
      const geometry = {
        type: 'point',
        x: point.x,
        y: point.y,
        z: point.z,
        spatialReference: point.spatialReference,
      };

      const graphic = new viewer.classes.Graphic({
        geometry,
      });
      const converted = await dispatch('projectGraphic', {
        data: graphic,
        outputWkid: baseWkid,
      });
      return {
        x: converted[0].geometry.x,
        y: converted[0].geometry.y,
        z: converted[0].geometry.z,
      };
    }
  },
  async projectGraphic(_, { data, outputWkid }) {
    let graphics = data;
    if (!Array.isArray(graphics)) {
      graphics = [graphics];
    }

    const projection = viewer.classes.projection;
    await projection.load();

    if (projection.isLoaded()) {
      const outSr = new viewer.classes.SpatialReference({ wkid: outputWkid });
      graphics.forEach((g) => {
        if (g.geometry.spatialReference.wkid !== outputWkid) {
          g.geometry = projection.project(g.geometry, outSr);
        }
      });
      return graphics;
    }
  },
  getMarkerOptions({ rootGetters }, { type, status, number, assignedUserId }) {
    const marker = {};
    const taskAssigned = assignedUserId === rootGetters.authenticatedUser?.id;
    const color = ColorHelper.getColorByStatus(status);

    switch (status) {
      case 'open':
        marker.width = 18;
        marker.height = 48;
        break;
      case 'closed':
        marker.width = 18;
        marker.height = 48;
        break;
      case 'canceled':
        marker.width = 16;
        marker.height = 44;
        break;
    }
    switch (type) {
      case 'point':
        const markerSvg = TaskHelper.getMarkerSvgString(
          color,
          number,
          taskAssigned
        );
        marker.type = 'picture-marker';
        marker.url = getSourceUrl(markerSvg);
        break;
      case 'polyline':
        marker.type = 'simple-line';
        marker.height = 0;
        marker.width = 4;
        marker.color = color;
        break;
    }

    return marker;
  },
  async goTo({ dispatch, state, rootGetters }, { x, y, center, rfiId }) {
    if (
      window?.location?.href &&
      window.location.href.indexOf(`module/${GISVIEWER}`) === -1 &&
      (x || y || center || rfiId)
    ) {
      const params = new URLSearchParams();
      if (rfiId) params.set('rfiid', rfiId);
      if (x) params.set('x', x);
      if (y) params.set('y', y);
      if (center) params.set('center', center);
      const slug = rootGetters.project.slug;
      const path = `/project/${slug}/module/${GISVIEWER}?${params.toString()}`;
      window.location.href = path;
      return;
    }

    if (rfiId) {
      const layer = viewer.graphicsLayers.find((l) => l.id === `rfi-${rfiId}`);
      if (!layer) {
        const rfi = rootGetters.tasksRfi.find((task) => task.id === rfiId);
        const json = rfi?.task_type?.metadata;
        await dispatch('loadNewGraphics', {
          json,
          config: { id: `rfi-${rfiId}` },
        });
      }
      const graphics = viewer.graphicsLayers?.find(
        (l) => l.id === `rfi-${rfiId}`
      )?.graphics;
      if (graphics) await viewer.viewActive.goTo(graphics);
    } else if (center) {
      viewer.viewActive.goTo({ center }).catch((error) => {
        if (error.name != 'AbortError') {
          console.error(error);
        }
      });
    } else if (x && y) {
      const geometry = new viewer.classes.Geometry({
        x,
        y,
        type: 'point',
        spatialReference: {
          wkid: state.viewer.baseWkid,
        },
      });
      viewer.viewActive.goTo(geometry).catch((error) => {
        if (error.name != 'AbortError') {
          console.error(error);
        }
      });
    }
  },
};
const getters = {
  data: (state) => state.moduleData,
  viewer: (state) => ({
    ...viewer,
    ...state.viewer,
  }),
  getSubLayers: (state) => (layerId) =>
    state.moduleData.layers?.CFFA_GIS_SUB_LAYERS?.records?.filter(
      (l) => l.main_layer_id === layerId
    ) || [],
  showSketchPanel: (state) => state.viewer.showSketchPanel,
  showBasemapGallery: (state) => state.viewer.showBasemapGallery,
  mapActionsDialog: (state) => state.viewer.mapActionsDialog,
  currentPointOptions: (state) => ({
    ...state.viewer.currentPointOptions,
    module: state.sketchData ? 'gis-drawing' : 'gis',
  }),
  showRfiDialog: (state) => state.viewer.showRfiDialog,
  baseWkid: (state) => state.viewer.baseWkid,
  sketchData: (state) => state.sketchData,
  hasFeatureTable: (state) =>
    state.moduleData?.layers?.CFFA_GIS_FEATURE_LAYERS?.records?.filter(
      (l) => l.is_table
    ).length > 0 ?? false,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

const getSourceUrl = (source, type = 'image/svg+xml') => {
  const blob = new Blob(Array.isArray(source) ? source : [source], { type });
  return URL.createObjectURL(blob);
};
