import { ANT_LICENSE } from './cookie';
const TOKEN_KEY = 'accessToken';
const PARENT_TOKEN_KEY = 'parentAccessToken';
const MAINTENANCE_TOKEN_KEY = 'maintenanceToken';
const MAINTENANCE_EXPIRE_IN_KEY = 'maintenanceExpiresIn';
const MAINTENANCE_VALID_MS = 10 * 60 * 1000; // 10 min * s * ms
const TOKEN_EXPIRES_IN = 'expires_in';
const NOTIFICATIONS = 'notifications';
const NEWS_READ_AT = 'newsReadAt';
// const TOUR_DASHBOARD_FINISHED = 'tour-dashboard-finished';

const LocalStorageService = {
  getValue(token) {
    return localStorage.getItem(token);
  },
  setValue(token, value) {
    localStorage.setItem(token, value);
  },
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },
  setToken(token) {
    localStorage.setItem(TOKEN_KEY, token);
  },
  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },
  hasToken() {
    return !!this.getToken();
  },

  // parent token for user access
  setParentToken(token) {
    localStorage.setItem(PARENT_TOKEN_KEY, token);
  },
  getParentToken() {
    return localStorage.getItem(PARENT_TOKEN_KEY);
  },
  removeParentToken() {
    localStorage.removeItem(PARENT_TOKEN_KEY);
  },

  // maintenance token
  HEADER_MAINTENANCE: 'x-ant-maintenance',
  setMaintenanceToken(token) {
    localStorage.setItem(MAINTENANCE_TOKEN_KEY, token);
    localStorage.setItem(
      MAINTENANCE_EXPIRE_IN_KEY,
      (Date.now() + MAINTENANCE_VALID_MS).toString()
    );
  },
  getMaintenanceToken() {
    return localStorage.getItem(MAINTENANCE_TOKEN_KEY);
  },
  isMaintenanceExpired() {
    const expireIn = parseInt(
      localStorage.getItem(MAINTENANCE_EXPIRE_IN_KEY) || '0'
    );
    return Date.now() > expireIn;
  },
  removeMaintenanceToken() {
    localStorage.removeItem(MAINTENANCE_TOKEN_KEY);
    localStorage.removeItem(MAINTENANCE_EXPIRE_IN_KEY);
  },
  //token expires
  setTokenExpiresIn(term) {
    localStorage.setItem(TOKEN_EXPIRES_IN, term);
  },
  getTokenExpiresIn() {
    return localStorage.getItem(TOKEN_EXPIRES_IN);
  },
  removeTokenExpiresIn() {
    localStorage.removeItem(TOKEN_EXPIRES_IN);
  },

  setNotifications(value) {
    return localStorage.setItem(NOTIFICATIONS, value);
  },
  getNotifications() {
    return localStorage.getItem(NOTIFICATIONS);
  },
  //News
  setNewsReadAt(value = Date.now()) {
    return localStorage.setItem(NEWS_READ_AT, value);
  },
  getNewsReadAt() {
    return localStorage.getItem(NEWS_READ_AT);
  },
};

export default LocalStorageService;
