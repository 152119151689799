import { render, staticRenderFns } from "./AntModuleTile.vue?vue&type=template&id=13e739a7&scoped=true"
import script from "./AntModuleTile.vue?vue&type=script&lang=js"
export * from "./AntModuleTile.vue?vue&type=script&lang=js"
import style0 from "./AntModuleTile.vue?vue&type=style&index=0&id=13e739a7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13e739a7",
  null
  
)

export default component.exports