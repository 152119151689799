<template>
  <div class="module-container pa-2" @click="openModule">
    <v-icon
      color="primary"
      large
      class="flex-1 d-flex align-end justify-center"
    >
      {{ module.icon }}
    </v-icon>
    <div class="fs-12 flex-1 d-flex align-center justify-center mb-2">
      {{ module.name }}
    </div>

    <slot name="module-tile-actions"></slot>
  </div>
</template>

<script>
export default {
  name: 'AntModuleTile',
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  methods: {
    openModule() {
      this.$emit('click', this.module);
    },
  },
};
</script>

<style scoped lang="scss">
.module-container {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 120px;
  border-radius: 8px;
  cursor: pointer;
  transition: 200ms ease-out;
  position: relative;

  &:hover {
    transform: scale(1.07);
  }
}
</style>
