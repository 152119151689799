import {
  cancelTask,
  closeTask,
  createTask,
  createTaskCheck,
  createTaskLabel,
  createTaskMessage,
  createTaskRelation,
  deleteTask,
  deleteTaskAppendix,
  deleteTaskCheck,
  deleteTaskLabel,
  deleteTaskRelation,
  deleteTasks,
  getTask,
  getTasks,
  reopenTask,
  updateTask,
  updateTaskCheck,
  uploadTaskAppendix,
} from '@/services/api/task.api';
import TaskHelper from '@/services/task-helper';
import { waitForVuex } from '@/utils/vuex';
import moment from 'moment';
import {getTaskV2} from "@/services/api/v2/tasks.v2.api";

const state = {
  sidebarTasksDisplayed: false,
  sidebarTasks: [],
  sidebarTasksStatus: '',

  task: null,
  taskStatus: '',

  tasks: [],
  tasksRfi: [],
  selectedTasks: [],
  selectedGisRfi: null,
  tasksStatus: '',

  tasksTree: [],
  tasksTreeStatus: '',

  advancedTaskFilters: [],
};
const mutations = {
  reset_rfis(state) {
    state.tasksRfi = []
  },
  toggle_tasks_sidebar(state) {
    state.sidebarTasksDisplayed = !state.sidebarTasksDisplayed;
  },
  close_tasks_sidebar(state) {
    state.sidebarTasksDisplayed = false;
  },
  sidebar_tasks_request(state) {
    state.sidebarTasksStatus = 'loading';
  },
  sidebar_tasks_success(state, tasks) {
    state.sidebarTasks = tasks;
    state.sidebarTasksStatus = 'success';
  },
  tasks_request(state) {
    state.tasksStatus = 'loading';
  },
  tasks_success(state, tasks) {
    state.tasks = tasks;
    state.tasksStatus = 'success';
  },
  tasks_rfi_success(state, tasks) {
    state.tasksRfi = tasks;
  },
  selected_tasks_success(state, tasks) {
    state.selectedTasks = tasks;
  },
  clear_selected_tasks(state) {
    state.selectedTasks = [];
  },
  tasks_tree_request(state) {
    state.tasksTreeStatus = 'loading';
  },
  tasks_tree_success(state, tasks) {
    state.tasksTree = TaskHelper.mapDefaultTreeItems(tasks);
    state.tasksTreeStatus = 'success';
  },
  tasks_tree_toggle_collapse(state, { task, parents }) {
    let item = getTreeItem(state.tasksTree, parents);
    item.collapsed = !item.collapsed;
    if (item.collapsed) {
      item.children = [];
      item.loadChildren = false;
    }
  },
  tasks_tree_children_request(state, { parents }) {
    let item = getTreeItem(state.tasksTree, parents);
    if (item) {
      item.loadChildren = true;
    }
  },
  tasks_tree_children_success(state, { tasks, parents }) {
    let item = getTreeItem(state.tasksTree, parents);
    if (item) {
      item.children = TaskHelper.mapDefaultTreeItems(tasks);
      item.loadChildren = false;
    }
  },
  task_clear(state) {
    state.task = null;
    state.tasksStatus = '';
  },
  task_request(state) {
    state.taskStatus = 'loading';
  },
  task_success(state, task) {
    state.task = task;
    state.taskStatus = 'success';

    if (state.sidebarTasks.length > 0) {
      if (state.sidebarTasks.findIndex((x) => x.id === task.id) !== -1) {
        Object.assign(
          state.sidebarTasks.find((item) => item.id === task.id),
          task
        );
      }
    }
    if (state.tasks.length > 0) {
      if (state.tasksRfi.findIndex((x) => x.id === task.id) !== -1) {
        Object.assign(
          state.tasks.find((item) => item.id === task.id),
          task
        );
      }
    }
    if (state.tasksRfi.length > 0) {
      if (state.tasksRfi.findIndex((x) => x.id === task.id) !== -1) {
        Object.assign(
          state.tasksRfi.find((item) => item.id === task.id),
          task
        );
      }
    }
  },
  task_message_create_success(state, message) {
    if (state.task) {
      state.task.activities.unshift(message);
    }
  },

  task_create_success(state, task) {
    state.sidebarTasks.unshift(task);
    if (state.task && task.parent === state.task.id) {
      state.task.children.unshift(task);
    }
    state.tasks.unshift(task);
    if (state.tasksTreeStatus === 'success') {
      // add to tree
      state.tasksTree.unshift(task);
      state.tasksTree = state.tasksTree.sort((a, b) =>
        moment(a?.planned_start).diff(moment(b?.planned_start))
      );
    }
  },
  task_update_success(state, updatedTask) {
    if (state.sidebarTasks.length > 0) {
      if (state.sidebarTasks.findIndex((x) => x.id === updatedTask.id) !== -1) {
        Object.assign(
          state.sidebarTasks.find((item) => item.id === updatedTask.id),
          updatedTask
        );
      }
    }
    if (state.task && state.task.id === updatedTask.id) {
      Object.assign(state.task, updatedTask);
    }
    if (state.tasksTree.length > 0) {
      Object.assign(deepSearch(state.tasksTree, updatedTask.id), updatedTask);
    }
  },
  task_delete_success(state, deletedId) {
    state.tasks = [...state.tasks.filter((task) => task.id !== deletedId)];
  },
  reset_tasks_state(state) {
    state.sidebarTasksDisplayed = false;
    state.sidebarTasksStatus = '';
    state.sidebarTasks = [];
  },
  tasks_add_advanced_filter(state, filter) {
    state.advancedTaskFilters.push(filter);
  },
  tasks_remove_advanced_filter(state, index) {
    state.advancedTaskFilters.splice(index, 1);
  },
  tasks_reset_advanced_filters(state) {
    state.advancedTaskFilters = [];
  },
};
const actions = {
  fetchSidebarTasks({ commit, getters }) {
    commit('sidebar_tasks_request');
    return getTasks(
      {
        'filter[license]': getters.selectedLicense.id,
        'filter[today]': 1,
      },
      getters.project
        ? {
            advanced_filters: [
              {
                column: 'project',
                operator: '=',
                values: [getters.project.id],
              },
            ],
          }
        : {}
    )
      .then((tasks) => {
        commit('sidebar_tasks_success', tasks);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchTasks: waitForVuex(
    'tasks.get',
    async ({ commit, getters }, { advanced = [] } = {}) => {
      commit('tasks_request');
      return getTasks(
        {
          'filter[license]': getters.selectedLicense.id,
        },
        {
          advanced_filters: [
            ...createAdvancedFilterBody(getters.advancedTaskFilters)
              .advanced_filters,
            ...advanced,
          ],
        }
      )
        .then((tasks) => {
          commit('tasks_success', tasks);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  fetchRfiTasks: waitForVuex(
    'tasks.rfi.get',
    async ({ commit, getters }, { advanced = [] } = {}) => {
      commit('tasks_request');
      return getTasks(
        {
          'filter[license]': getters.selectedLicense.id,
        },
        getters.project
          ? {
              advanced_filters: [
                ...[
                  {
                    column: 'project',
                    operator: '=',
                    values: [getters.project.id],
                  },
                  {
                    column: 'type',
                    operator: '=',
                    values: ['rfi'],
                  },
                ],
                ...advanced,
              ],
            }
          : {}
      )
        .then((tasks) => {
          commit('tasks_rfi_success', tasks);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  fetchTaskTree({ commit, getters }, filters) {
    commit('tasks_tree_request');
    return getTasks(
      filters,
      createAdvancedFilterBody(getters.advancedTaskFilters)
    )
      .then((tasks) => {
        commit('tasks_tree_success', tasks);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchTaskChildren({ commit, getters }, { event, parents }) {
    commit('tasks_tree_children_request', { parents });
    return getTasks(
      {
        'filter[parent]': event.id,
        'filter[license]': getters.selectedLicense.id,
      },
      createAdvancedFilterBody(getters.advancedTaskFilters)
    )
      .then((tasks) => {
        commit('tasks_tree_children_success', { tasks, parents });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchTask: waitForVuex(
    (_, { taskId }) => `tasks.get.${taskId}`,
    ({ commit }, taskId) => {
      commit('task_request');
      return getTaskV2(taskId)
        .then((task) => {
          commit('task_success', task);
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    }
  ),
  reloadDetailTask({ commit }, taskId) {
    return getTask(taskId)
      .then((task) => {
        commit('task_success', task);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  cancelTask({ commit }, taskId) {
    return new Promise((resolve, reject) => {
      return cancelTask(taskId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject();
        });
    });
  },
  closeTask({ commit }, taskId) {
    return new Promise((resolve, reject) => {
      return closeTask(taskId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject();
        });
    });
  },
  reopenTask({ commit }, taskId) {
    return new Promise((resolve, reject) => {
      return reopenTask(taskId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject();
        });
    });
  },
  createTask({ commit }, { body }) {
    return new Promise((resolve, reject) => {
      return createTask(body)
        .then((response) => {
          commit('task_create_success', response);
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  updateTask({ commit }, { taskId, body }) {
    return new Promise((resolve, reject) => {
      return updateTask(taskId, body)
        .then((response) => {
          commit('task_success', response);
          this.commit('showNotification', {
            content: `Successfully updated task`,
            color: 'success',
          });
          resolve(response);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  deleteTask({ commit }, { taskId }) {
    return new Promise((resolve, reject) => {
      return deleteTask(taskId)
        .then(() => {
          commit('task_delete_success', taskId);
          resolve();
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject();
        });
    });
  },
  deleteTasks: waitForVuex('tasks.delete', ({ commit }, { tasks }) => {
    return new Promise((resolve, reject) => {
      return deleteTasks(tasks)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject();
        });
    });
  }),
  createTaskRelation({ commit }, { previousId, taskId }) {
    return new Promise((resolve, reject) => {
      return createTaskRelation(previousId, {
        task: taskId,
      })
        .then((task) => {
          commit('task_update_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  deleteTaskRelation({ commit }, { taskId, relationId }) {
    return new Promise((resolve, reject) => {
      return deleteTaskRelation(taskId, relationId)
        .then((task) => {
          commit('task_update_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },

  createTaskMessage({ commit }, { taskId, message }) {
    return new Promise((resolve, reject) => {
      return createTaskMessage(taskId, message)
        .then((message) => {
          commit('task_message_create_success', message);
          resolve(message);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  createTaskCheck({ commit }, { taskId, check }) {
    return new Promise((resolve, reject) => {
      return createTaskCheck(taskId, check)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  updateTaskCheck({ commit }, { taskId, checkId, body }) {
    return new Promise((resolve, reject) => {
      return updateTaskCheck(taskId, checkId, body)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  deleteTaskCheck({ commit }, { taskId, checkId }) {
    return new Promise((resolve, reject) => {
      return deleteTaskCheck(taskId, checkId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  createTaskLabel({ commit }, { taskId, labelId }) {
    return new Promise((resolve, reject) => {
      return createTaskLabel(taskId, labelId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  deleteTaskLabel({ commit }, { taskId, labelId }) {
    return new Promise((resolve, reject) => {
      return deleteTaskLabel(taskId, labelId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  uploadTaskAppendix({ commit }, { appendix, taskId }) {
    return new Promise((resolve, reject) => {
      return uploadTaskAppendix(taskId, appendix)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  deleteTaskAppendix({ commit }, { taskId, appendixId }) {
    return new Promise((resolve, reject) => {
      return deleteTaskAppendix(taskId, appendixId)
        .then((task) => {
          commit('task_success', task);
          resolve(task);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
};
const getters = {
  sidebarTasksDisplayed: (state) => state.sidebarTasksDisplayed,
  sidebarTasks: (state) => state.sidebarTasks,
  sidebarTasksStatus: (state) => state.sidebarTasksStatus,
  task: (state) => state.task,
  taskStatus: (state) => state.taskStatus,
  tasks: (state) => state.tasks,
  selectedTasks: (state) => state.selectedTasks,
  tasksRfi: (state) => state.tasksRfi,
  tasksStatus: (state) => state.tasksStatus,
  tasksTree: (state) => state.tasksTree,
  tasksTreeStatus: (state) => state.tasksTreeStatus,
  tasksSidebarSize: (state) => state.tasksSidebarSize,
  advancedTaskFilters: (state) => state.advancedTaskFilters,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function getTreeItem(tree, parents) {
  let counter = 0;
  let item = null;
  parents.forEach((id) => {
    let object = tree.find((task) => task.id === id);
    counter++;
    if (counter < parents.length) {
      tree = object.children;
    } else {
      item = object;
    }
  });
  return item;
}

function createAdvancedFilterBody(filters) {
  let body = {
    advanced_filters: [],
  };
  filters.forEach((filter) => {
    let values = [];
    if (
      ['assigned_to', 'project', 'label', 'created_by'].includes(
        filter.selectedOption.value
      )
    ) {
      values = filter.selectedValues.map((item) => item.id);
    } else if (['priority', 'status'].includes(filter.selectedOption.value)) {
      values = filter.selectedValues.map((item) => item.title);
    } else {
      values = filter.selectedValues;
    }
    body.advanced_filters.push({
      column: filter.selectedOption.value,
      operator: filter.selectedOperator,
      values: values,
    });
  });
  return body;
}

function deepSearch(data, value, key = 'id', sub = 'children', tempObj = {}) {
  if (value && data) {
    data.find((node) => {
      if (node[key] === value) {
        tempObj.found = node;
        return node;
      }
      return deepSearch(node[sub], value, key, sub, tempObj);
    });
    if (tempObj.found) {
      return tempObj.found;
    }
  }
  return false;
}
