import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  updateRecord,
} from '@/services/api/record.api';

const state = {
  moduleData: {
    CFFA_SA_STAKEHOLDERS: {
      id: null,
      records: [],
    },
    CFFA_SA_SWOT_INPUT: {
      id: null,
      records: [],
    },
  },
  moduleStatus: '',
  selectedStakeholder: null,
};
const mutations = {
  module_stake_holder_analysis_toggle_stakeholder(state, stakeholder) {
    if (stakeholder === state.selectedStakeholder) {
      state.selectedStakeholder = null;
    } else {
      state.selectedStakeholder = stakeholder;
    }
  },
  module_stake_holder_analysis_request(state) {
    state.moduleStatus = 'loading';
  },
  module_stake_holder_analysis_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_stake_holder_analysis_create_success(state, record) {
    state.moduleData.CFFA_SA_STAKEHOLDERS.records.push(record);
  },
  module_stake_holder_analysis_update_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_SA_STAKEHOLDERS.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_stake_holder_analysis_delete_success(state, deletedId) {
    state.moduleData.CFFA_SA_STAKEHOLDERS.records =
      state.moduleData.CFFA_SA_STAKEHOLDERS.records.filter(
        (record) => record.id !== deletedId
      );
  },
  module_stake_holder_analysis_swot_create_success(state, record) {
    state.moduleData.CFFA_SA_SWOT_INPUT.records.push(record);
  },
  module_stake_holder_analysis_swot_delete_success(state, deletedId) {
    state.moduleData.CFFA_SA_SWOT_INPUT.records =
      state.moduleData.CFFA_SA_SWOT_INPUT.records.filter(
        (record) => record.id !== deletedId
      );
  },
};
const actions = {
  loadStakeHolderAnalysisModuleData(
    { commit },
    { projectId, moduleId, sessionId }
  ) {
    commit('module_stake_holder_analysis_request');
    getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_stake_holder_analysis_success', data);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  createStakeholder({ commit, getters }, name) {
    return new Promise(async (resolve, reject) => {
      let record = await createRecord({
        project: {
          id: getters.project.id,
        },
        table: {
          id: getters.stakeholderAnalysisData.CFFA_SA_STAKEHOLDERS.id,
        },
        record: {
          name: name,
          interest: 5,
          influence: 5,
        },
      });
      commit('module_stake_holder_analysis_create_success', record);
      resolve(record);
    });
  },
  updateStakeholder({ commit, getters }, body) {
    return new Promise(async (resolve, reject) => {
      let record = await updateRecord(getters.selectedStakeholder.id, {
        project: {
          id: getters.project.id,
        },
        table: {
          id: getters.stakeholderAnalysisData.CFFA_SA_STAKEHOLDERS.id,
        },
        record: body,
      });
      commit('module_stake_holder_analysis_update_success', record);
      resolve(record);
    });
  },
  deleteStakeholder({ commit, getters }, recordId) {
    return new Promise(async (resolve, reject) => {
      let record = await deleteRecord(recordId, {
        project: {
          id: getters.project.id,
        },
        table: {
          id: getters.stakeholderAnalysisData.CFFA_SA_STAKEHOLDERS.id,
        },
      });
      commit('module_stake_holder_analysis_delete_success', recordId);
      // TODO delete all swot input??
      resolve(record);
    });
  },
  createSwotRecord({ commit, getters }, recordBody) {
    return new Promise(async (resolve, reject) => {
      let record = await createRecord({
        project: {
          id: getters.project.id,
        },
        table: {
          id: getters.stakeholderAnalysisData.CFFA_SA_SWOT_INPUT.id,
        },
        record: recordBody,
      });
      commit('module_stake_holder_analysis_swot_create_success', record);
      resolve(record);
    });
  },
  deleteSwotRecord({ commit, getters }, recordId) {
    return new Promise(async (resolve, reject) => {
      let record = await deleteRecord(recordId, {
        project: {
          id: getters.project.id,
        },
        table: {
          id: getters.stakeholderAnalysisData.CFFA_SA_SWOT_INPUT.id,
        },
      });
      commit('module_stake_holder_analysis_swot_delete_success', recordId);
      // TODO delete all swot input??
      resolve(record);
    });
  },
};
const getters = {
  stakeholderAnalysisData: (state) => state.moduleData,
  stakeholderAnalysisStatus: (state) => state.moduleStatus,

  selectedStakeholder: (state) => state.selectedStakeholder,
  stakeholderAnalysisSwotRecords: (state) =>
    state.moduleData.CFFA_SA_SWOT_INPUT.records,
  stakeholderAnalysisSwotTotalWeight: (state) =>
    state.moduleData.CFFA_SA_SWOT_INPUT.records.reduce(
      (partial_sum, a) => partial_sum + a.weight,
      0
    ),
  stakeholderAnalysisSwotInputRecords: (state) =>
    state.moduleData.CFFA_SA_SWOT_INPUT.records.filter(
      (record) => record.stakeholder_id === state.selectedStakeholder?.id
    ),
  stakeHolderAnalysisStakeholders: (state) =>
    state.moduleData.CFFA_SA_STAKEHOLDERS.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
