import { chain, isArray, isEmpty, isObject, set } from 'lodash';
import { bookOfObjects } from '../../../../services/bookOf';
import { importRecords } from '../../../../services/api/record.api';

/**
 * @param {...[name: string, icon: string]} item
 * @return {{selected: number, items: { name:string, icon: string, isSelected: boolean}[]}}
 */

export function navigationOf(...item) {
  const items = item.map(([name, icon], index) => ({
    name,
    icon,
    isSelected: index === 0,
  }));
  const selected = items.findIndex(() => true);

  return { items, selected };
}

export function select(navigation, index) {
  let selected = -1;
  const items = navigation.items.map((item, i) => {
    const isSelected = index === i;

    if (isSelected) selected = index;

    return { ...item, isSelected };
  });

  return { selected, items };
}

/**
 *
 * @param {*} model
 * @param {string[]?} path
 * @return {{name: string, path: string[]}[]}
 */

export function flattenModel(model, path) {
  if (isArray(model)) {
    return chain(model)
      .flatMap((a) => flattenModel(a, path))
      .uniqBy((r) => r.name)
      .sortBy((r) => r.name)
      .value();
  } else if (isObject(model)) {
    return chain(model)
      .toPairs(model)
      .flatMap(([key, value]) => flattenModel(value, [...(path ?? []), key]))
      .uniqBy((r) => r.name)
      .sortBy((r) => r.name)
      .value();
  } else {
    path = path ?? [];
    return [
      {
        path: path,
        name: path.reduce((l, r) => (isEmpty(l) ? r : `${l}: ${r}`), ''),
      },
    ];
  }
}

export function convertToURN(objectId) {
  return btoa(objectId).replace('==', '');
}

/**
 *
 * @param {any[]} models
 * @param {any[]} modelProperties
 * @param {{name: string, value: string}[]} data
 * @return {[]}
 */
export function mapModels(models, modelProperties, data) {
  const rows = data.mapping.map(({ name, value }) => {
    const path = modelProperties.find((p) => p.name === value)?.path;
    return { name, path };
  });

  return models.map((model) =>
    rows.reduce((object, { path, name }) => {
      const value = path?.reduce((p, s) => p?.[s], model.properties);
      return set(object, name, value);
    }, {})
  );
}

export async function saveModels(mappedModels, projectId, tableId) {
  const book = bookOfObjects('data', mappedModels);
  const records = book.convert('csv', 'string');
  const data = {
    project: {
      id: projectId,
    },
    table: {
      id: tableId,
    },
    records: btoa(records),
  };
  return await importRecords(data);
}
