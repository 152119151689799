<template>
  <div class="d-flex flex-column full-width">
    <v-subheader
      class="font-weight-bold pa-0"
      style="font-size: 11px; height: auto"
    >
      {{ title }}
      <v-spacer />
      <v-menu
        v-if="editing"
        v-model="menuShown"
        v-click-outside="(menuShown = false)"
        :close-on-content-click="false"
        :disabled="disabled"
        offset-x
      >
        <template #activator="{ on, attrs }">
          <v-icon
            v-bind="attrs"
            small
            v-on="on"
            @click="!labelOptions.length && fetchLabels()"
            >mdi-plus</v-icon
          >
        </template>

        <div class="pa-2 ant-glass-background">
          <div v-if="!isLabelLoading" class="d-flex flex-column">
            <template v-if="labelOptions.length">
              <div
                v-for="label in labelOptions"
                :key="`option${label.id}`"
                class="d-flex mb-1"
              >
                <v-simple-checkbox
                  :value="isLabelAdded(label)"
                  class="mr-2"
                  :ripple="false"
                  @click="toggleTaskLabel(label)"
                />
                <v-chip
                  small
                  :color="label.color"
                  :text-color="getTextContrastColor(label.color)"
                  >{{ label.title }}</v-chip
                >
              </div>
            </template>
            <div v-else>No options found</div>
          </div>
          <div
            v-else
            class="d-flex align-center justify-center"
            style="min-width: 110px; min-height: 90px"
          >
            <ant-loading />
          </div>
        </div>
      </v-menu>
    </v-subheader>
    <div v-if="editedLabels?.length" class="mt-1">
      <v-chip
        v-for="label in editedLabels"
        :key="`task_label${label.id}`"
        :color="label.color"
        small
        class="mr-1"
        :close="editing"
        :text-color="getTextContrastColor(label.color)"
        @click:close="removeTaskLabel(label)"
        >{{ label.title }}</v-chip
      >
    </div>
    <div v-else class="fs-14 mt-1" style="color: #a6a6a6; height: 24px">
      No {{ title }} added...
    </div>
  </div>
</template>

<script>
import ColorHelper from '@/services/color-helper';
import { getLabels } from '@/services/api/labels.api';
import { mapGetters } from 'vuex';
import AntLoading from '@/components/AntLoading.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'TaskLabel',
  components: { AntLoading },
  props: {
    labels: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: String,
      default: '',
    },
    editing: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      default: 'Labels',
    },
  },
  data() {
    return {
      menuShown: false,
      labelsList: [],
      labelOptions: [],
      isLabelLoading: false,
      editedLabels: [],
    };
  },
  computed: {
    ...mapGetters(['selectedLicense']),
  },
  watch: {
    labels: {
      handler(labels) {
        this.$nextTick(() => {
          this.editedLabels = cloneDeep(labels);
        });
      },
      immediate: true,
      deep: true,
    },
    editing() {
      this.editedLabels = cloneDeep(this.labels);
    },
    labelsList: {
      handler(labels) {
        this.getLabelOptions(labels);
      },
      deep: true,
      immediate: true,
    },
    projectId() {
      this.editedLabels = [];
      this.fetchLabels();
    },
  },
  methods: {
    async fetchLabels() {
      this.isLabelLoading = true;
      this.labelsList = await getLabels({
        'filter[license]': this.selectedLicense.id,
      });
      this.isLabelLoading = false;
    },
    getLabelOptions(labels) {
      let licenseLabels = labels.filter((label) => !label.project);
      let projectLabels = [];
      if (this.projectId) {
        projectLabels = labels.filter(
          (label) => label.project === this.projectId
        );
      }
      this.labelOptions = [...licenseLabels, ...projectLabels];
    },
    toggleTaskLabel(label) {
      if (this.isLabelAdded(label)) {
        this.removeTaskLabel(label);
      } else {
        this.editedLabels.push(label);
        this.$emit('on-change', this.editedLabels);
      }
    },
    removeTaskLabel(label) {
      this.editedLabels = this.editedLabels.filter((l) => l.id !== label.id);
      this.$emit('on-change', this.editedLabels);
    },
    isLabelAdded(label) {
      return this.editedLabels?.findIndex((l) => l.id === label.id) > -1;
    },
    getTextContrastColor(color) {
      return ColorHelper.getBlackOrWhiteBasedOnBackground(color);
    },
  },
};
</script>

<style scoped></style>
