import { getModuleData } from '@/services/api/module.api';
import {
  createRecord,
  deleteRecord,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { bookOfObjects } from '@/services/bookOf';
import { executeAutomation } from '@/services/api/automation.api';
import {
  getTasks,
  importTasks,
  uploadTaskAppendix,
} from '@/services/api/task.api';

const state = {
  moduleData: {
    CFFA_DHME_WKB_RISKS: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_WKB_ANALYSIS: {
      id: undefined,
      records: [],
    },
  },
  moduleStatus: '',
  selectedRisk: null,
  wkbAnalysisTasks: [],
};
const mutations = {
  module_wkb_inspection_plan_request(state) {
    state.moduleStatus = 'loading';
  },
  module_wkb_inspection_plan_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  module_wkb_inspection_plan_add_risk_to_project_success(state, record) {
    state.moduleData.CFFA_DHME_WKB_ANALYSIS.records.push(record);
  },
  module_wkb_inspection_plan_update_risk_to_project_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_WKB_ANALYSIS.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_wkb_inspection_plan_delete_risk_to_project_success(state, deletedId) {
    state.moduleData.CFFA_DHME_WKB_ANALYSIS.records =
      state.moduleData.CFFA_DHME_WKB_ANALYSIS.records.filter(
        (record) => record.id !== deletedId
      );
  },
  module_wkb_inspection_plan_add_measurement_to_risk_success(state, record) {
    state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.records.push(record);
  },
  module_wkb_inspection_plan_update_measurement_to_risk_success(state, record) {
    Object.assign(
      state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.records.find(
        (item) => item.id === record.id
      ),
      record
    );
  },
  module_wkb_inspection_plan_delete_measurement_to_risk_success(
    state,
    deletedId
  ) {
    state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.records =
      state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.records.filter(
        (record) => record.id !== deletedId
      );
  },
  module_wkb_inspection_plan_select_risk(state, risk) {
    state.selectedRisk = risk;
  },
  module_wkb_inspection_plan_tasks_success(state, tasks) {
    let parents = tasks.filter(
      (task) => task.task_type.type !== 'dhme-quality-assurance'
    );
    parents.forEach((parent) => {
      parent.children = tasks.filter((child) => child.parent === parent.id);
      parent.collapsed = false;
      parent.loadChildren = false;
    });

    state.wkbAnalysisTasks = parents;
  },
};
const actions = {
  fetchWKBTasks({ commit, getters }, phaseType) {
    commit('dhme_assembly_tasks_request');
    return new Promise((resolve, reject) => {
      getTasks(
        {
          'filter[license]': getters.selectedLicense.id,
        },
        {
          advanced_filters: [
            {
              column: 'project',
              operator: '=',
              values: [getters.project.id],
            },
            {
              column: 'type',
              operator: '=',
              values: ['dhme-quality-assurance', phaseType],
            },
          ],
        }
      ).then((tasks) => {
        commit('module_wkb_inspection_plan_tasks_success', tasks);
        resolve(tasks);
      });
    });
  },
  importQATasks({ commit, dispatch }, { data, taskPhase }) {
    importTasks(data)
      .then(async () => {
        let tasks = await dispatch('fetchWKBTasks', taskPhase);
        await dispatch('uploadDefaults', tasks);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  uploadDefaults({ getters }, tasks) {
    return new Promise(async (resolve) => {
      const tasksWithDefault = tasks.filter(
        (task) => task.task_type.custom_2 === 'document'
      );
      for (const task of tasksWithDefault) {
        const defaultFile = getters.wkbInspectionPlanMeasurementDocuments.find(
          (record) => record.id === task.task_type.custom_1
        )?.default;
        if (defaultFile) {
          await uploadTaskAppendix(task.id, {
            data: defaultFile.file,
            name: defaultFile.name,
            extension: defaultFile.extension,
          });
        }
      }
      resolve();
    });
  },
  fetchWKBInspectionPlanModuleData(
    { commit },
    { projectId, moduleId, sessionId }
  ) {
    commit('module_wkb_inspection_plan_request');
    return new Promise((resolve, reject) => {
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_wkb_inspection_plan_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
          reject(error);
        });
    });
  },
  addProjectRisk({ commit, dispatch, getters, state }, record) {
    record.project = {
      id: getters.project.id,
    };
    record.table = {
      id: state.moduleData.CFFA_DHME_WKB_ANALYSIS.id,
    };
    return createRecord(record)
      .then((record) => {
        commit(
          'module_wkb_inspection_plan_add_risk_to_project_success',
          record
        );
        let autoActivateMeasurements =
          getters.wkbInspectionPlanRiskMapping.filter(
            (relation) =>
              relation.risk_id === record.risk_id && relation.auto_active
          );

        autoActivateMeasurements.forEach((relation) => {
          const measurement = getters.wkbInspectionPlanMeasurements.find(
            (measurement) => measurement.id === relation.measurement_id
          );
          let body = {
            record: {
              risk_id: record.risk_id,
              measurement_id: relation.measurement_id,
              sample_size:
                measurement.default_sample_size > 0
                  ? measurement.default_sample_size
                  : 0,
            },
          };

          dispatch('addProjectRiskMeasurement', body);
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateProjectRisk({ commit, getters, state }, { recordId, body }) {
    body.project = {
      id: getters.project.id,
    };
    body.table = {
      id: state.moduleData.CFFA_DHME_WKB_ANALYSIS.id,
    };
    return updateRecord(recordId, body)
      .then((record) => {
        commit(
          'module_wkb_inspection_plan_update_risk_to_project_success',
          record
        );
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteProjectRisk({ commit, dispatch, getters, state }, recordId) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_ANALYSIS.id,
      },
    };
    let risk = getters.wkbInspectionPlanProjectRisks.find(
      (item) => item.id === recordId
    );
    return deleteRecord(recordId, body)
      .then((response) => {
        commit(
          'module_wkb_inspection_plan_delete_risk_to_project_success',
          recordId
        );

        let activeProjectMeasurements =
          getters.wkbInspectionPlanProjectRisksMeasures.filter(
            (relation) => relation.risk_id === risk.risk_id
          );
        activeProjectMeasurements.forEach((relation) => {
          dispatch('deleteProjectRiskMeasurement', relation.id);
        });
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  addProjectRiskMeasurement({ commit, getters, state }, record) {
    record.project = {
      id: getters.project.id,
    };
    record.table = {
      id: state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.id,
    };
    return createRecord(record)
      .then((record) => {
        commit(
          'module_wkb_inspection_plan_add_measurement_to_risk_success',
          record
        );
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  updateProjectRiskMeasurement({ commit, getters, state }, { recordId, body }) {
    body.project = {
      id: getters.project.id,
    };
    body.table = {
      id: state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.id,
    };
    return updateRecord(recordId, body)
      .then((record) => {
        commit(
          'module_wkb_inspection_plan_update_measurement_to_risk_success',
          record
        );
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteProjectRiskMeasurement({ commit, getters, state }, recordId) {
    let body = {
      project: {
        id: getters.project.id,
      },
      table: {
        id: state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.id,
      },
    };
    return deleteRecord(recordId, body)
      .then((record) => {
        commit(
          'module_wkb_inspection_plan_delete_measurement_to_risk_success',
          recordId
        );
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  wkbInspectionPlanStatus: (state) => state.moduleStatus,
  wkbInspectionPlanRisks: (state) =>
    state.moduleData.CFFA_DHME_WKB_RISKS.records,
  wkbInspectionPlanMeasurements: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENTS.records,
  wkbInspectionPlanProjectRisks: (state) =>
    state.moduleData.CFFA_DHME_WKB_ANALYSIS.records,
  wkbInspectionPlanChapters: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTERS.records,
  wkbInspectionPlanMeasurementDocuments: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_DOCUMENTS.records,
  wkbInspectionPlanMeasurementForms: (state) =>
    state.moduleData.CFFA_DHME_WKB_MEASUREMENT_FORMS.records,
  wkbInspectionPlanProjectRisksMeasures: (state) =>
    state.moduleData.CFFA_DHME_WKB_PROJECT_RISKS_MEASURES.records,
  wkbInspectionPlanRiskMapping: (state) =>
    state.moduleData.CFFA_DHME_WKB_RISKS_MEASURES.records,
  wkbInspectionPlanChapterSubstantiations: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATIONS.records,
  wkbInspectionPlanChapterSubstantiationDocuments: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.records,
  wkbInspectionPlanChapterSubstantiationDocumentsTableId: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_SUBSTANTIATION_DOCS.id,
  wkbInspectionPlanModules: (state) =>
    state.moduleData.CFFA_DHME_MODULES.records,
  wkbInspectionPlanRiskDetail: (state) => state.selectedRisk,
  wkbInspectionPlanRiskDetailChapter: (state, getters) => {
    return getters.wkbInspectionPlanChapters.find(
      (x) =>
        x.chapter === getters.wkbInspectionPlanRiskDetail.chapter &&
        x.sub_chapter === getters.wkbInspectionPlanRiskDetail.sub_chapter
    );
  },
  wkbInspectionPlanTasks: (state) => state.wkbAnalysisTasks,
  wkbRiskIPStatuses: (state) =>
    state.moduleData.CFFA_DHME_WKB_CHAPTER_STATUSES.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
