import { getModuleData } from '@/services/api/module.api';

const state = {
  moduleData: undefined,
  moduleStatus: '',
};
const mutations = {
  module_mki_request(state) {
    state.moduleStatus = 'loading';
  },
  module_mki_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
};
const actions = {
  fetchMkiModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_mki_request');
    getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_mki_success', data);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  mkiData: (state) => state.moduleData,
  mkiStatus: (state) => state.moduleStatus,

  mkiClient: (state) => state.moduleData?.CFFA_FORGE_CLIENT?.records[0],
  mkiModels: (state) => state.moduleData?.CFFA_FORGE_MODELS?.records,
  mkiResults: (state) => state.moduleData?.CFFA_MKI_RESULTS?.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
