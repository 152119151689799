import axios from 'axios';
import router from '@/router';
import LocalStorageService from '@/services/local-storage';
import appConfig from '@/appConfig';

const httpClient = axios.create({
  baseURL: appConfig.VUE_APP_BASE_URL + appConfig.VUE_APP_API_URL,
});

const httpClientV2 = axios.create({
  baseURL: appConfig.VUE_APP_BASE_URL + '/api/2.0',
});

function onRequestFulfilled(config) {
  let accessToken = LocalStorageService.getToken();
  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  let maintenanceToken = LocalStorageService.getMaintenanceToken();
  if (maintenanceToken) {
    if (LocalStorageService.isMaintenanceExpired()) {
      LocalStorageService.removeMaintenanceToken();
    } else {
      config.headers[LocalStorageService.HEADER_MAINTENANCE] = maintenanceToken;
    }
  }

  config.headers['Content-Type'] = 'application/json';

  return config;
}
function onRequestRejected(error) {
  return Promise.reject(error);
}

function onResponseFulfilled(response) {
  return response;
}
function onResponseRejected(error) {
  switch (error.response?.status) {
    case 401:
      if (error.request.responseURL.endsWith('/oauth/forgeAccessToken')) {
        break;
      } else {
        LocalStorageService.removeToken();
        if (!/login$/.test(window.location.pathname)) {
          const redirect = window.location.pathname + window.location.search;
          router.push({ name: 'Login', query: { redirect } });
        }
        break;
      }
    case 403:
      break;
    case 503:
      if (router.currentRoute.name !== 'Maintenance') {
        const redirect = window.location.pathname + window.location.search;
        router.push({ name: 'Maintenance', query: { redirect } });
      }
      break;
    default:
      if (error.response?.data?.code?.toString().includes('1656583722774')) {
        router.push('/settings?status=reset');
      }
      break;
  }
  if (error.response.data.message === 'Service Unavailable') {
    if (router.currentRoute.name !== 'Maintenance') {
      const redirect = window.location.pathname + window.location.search;
      router.push({ name: 'Maintenance', query: { redirect } });
    }
  }
  return Promise.reject(error);
}

httpClient.interceptors.request.use(onRequestFulfilled, onRequestRejected);
httpClient.interceptors.response.use(onResponseFulfilled, onResponseRejected);
httpClientV2.interceptors.request.use(onRequestFulfilled, onRequestRejected);
httpClientV2.interceptors.response.use(onResponseFulfilled, onResponseRejected);

export { httpClientV2 };
export default httpClient;
