import httpClient from "../httpClient";

const getExternalLinks = (projectId) => {
    return new Promise((resolve, reject) => {
        httpClient.get(`/project/${projectId}/links`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
    })
}

const getExternalLink = (projectId, linkId) => {
    return new Promise((resolve, reject) => {
        httpClient.get(`/project/${projectId}/links/${linkId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
    })
}

const createExternalLink = (projectId, body) => {
    return new Promise((resolve, reject) => {
        httpClient.post(`/project/${projectId}/links`, body)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
    })
}

const updateExternalLink = (projectId, linkId, body) => {
    return new Promise((resolve, reject) => {
        httpClient.put(`/project/${projectId}/links/${linkId}`, body)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
    })
}


const deleteExternalLink = (projectId, linkId) => {
    return new Promise((resolve, reject) => {
        httpClient.delete(`/project/${projectId}/links/${linkId}`)
            .then(response => {
                resolve(response.data)
            })
            .catch(error => {
                reject(error.response.data)
            })
    })
}

export {
    getExternalLinks,
    getExternalLink,
    createExternalLink,
    updateExternalLink,
    deleteExternalLink,
}
