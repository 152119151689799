import {
  getWorkflowBlock,
  getWorkflowBlockDocument,
} from '@/services/api/blocks.api';

const state = {
  manualConfig: {
    panels: {
      left: {
        top: {
          active: false,
          type: '{TYPE}',
          content: {},
        },
        bottom: {
          active: false,
          type: '{TYPE}',
          content: {},
        },
      },
      right: {
        top: {
          active: false,
          type: '{TYPE}',
          content: {},
        },
        bottom: {
          active: false,
          type: '{TYPE}',
          content: {},
        },
      },
    },
  },
  automatedConfig: undefined,
  builderBlock: undefined,
  blockConfigStatus: '',
  blockType: '',
  blockSaved: true,
};
const mutations = {
  set_block_type(state, value) {
    state.blockType = value;
  },
  get_block_success(state, block) {
    state.builderBlock = block;
  },
  block_config_request(state) {
    state.blockConfigStatus = 'loading';
  },
  block_config_success(state, config) {
    let json = JSON.parse(atob(config.file));

    setTimeout(() => {
      if (json.panels !== undefined) {
        state.manualConfig = json;
        state.blockType = 'manual';
      } else {
        state.automatedConfig = atob(config.file);
        state.blockType = 'automated';
      }
      state.blockConfigStatus = 'success';
    }, 1);
  },
  save_panel_config(state, { panelPosition, config }) {
    state.manualConfig.panels[panelPosition.horizontal][
      panelPosition.vertical
    ] = config;
    state.blockSaved = false;
  },
  save_automated_config(state, { config }) {
    state.automatedConfig = config;
    state.blockSaved = false;
  },
  block_saved_success(state) {
    state.blockSaved = true;
  },
  reset_panel(state, panel) {
    state.manualConfig.panels[panel.horizontal][panel.vertical] = {
      active: false,
      type: '{TYPE}',
      content: {},
    };
    state.blockSaved = false;
  },
  reset_block_builder(state) {
    state.manualConfig = {
      panels: {
        left: {
          top: {
            active: false,
            type: '{TYPE}',
            description: {},
          },
          bottom: {
            active: false,
            type: '{TYPE}',
            description: {},
          },
        },
        right: {
          top: {
            active: false,
            type: '{TYPE}',
            description: {},
          },
          bottom: {
            active: false,
            type: '{TYPE}',
            description: {},
          },
        },
      },
    };
    state.automatedConfig = undefined;
    state.builderBlock = undefined;
  },
};
const actions = {
  fetchBlockData({ commit, dispatch }, { workflowId, blockId }) {
    commit('reset_block_builder');
    return getWorkflowBlock(workflowId, blockId)
      .then((block) => {
        commit('get_block_success', block);
        if (block.config !== null) {
          dispatch('fetchBlockConfig', {
            workflowId,
            blockId,
            documentId: block.config.id,
          });
        }
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchBlockConfig({ commit }, { workflowId, blockId, documentId }) {
    commit('block_config_request');
    return getWorkflowBlockDocument(workflowId, blockId, documentId)
      .then((config) => {
        commit('block_config_success', config);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  builderBlock: (state) => state.builderBlock,
  blockConfig: (state) => {
    if (state.blockType === 'manual') {
      return state.manualConfig;
    } else if (state.blockType === 'automated') {
      return state.automatedConfig;
    }
  },
  blockConfigStatus: (state) => state.blockConfigStatus,
  blockType: (state) => state.blockType,
  isBlockSaved: (state) => state.blockSaved,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
