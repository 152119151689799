import httpClient from '../httpClient';

const getModuleData = (
  projectId,
  moduleId,
  session = undefined,
  withRecords = true
) => {
  return new Promise((resolve, reject) => {
    const params = new URLSearchParams({});
    if (session) {
      params.append('session', session);
    }
    if (!withRecords) {
      params.append('withoutRecords', 1);
    }
    let url = `/project/${projectId}/modules/${moduleId}/data?${params.toString()}`;
    httpClient
      .get(url)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const addModuleToProject = (moduleId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`modules/${moduleId}/project/${projectId}`, { pool: 20 })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const removeModuleFromProject = (moduleId, projectId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`modules/${moduleId}/project/${projectId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getModulesByLicense = (licenseId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/license/${licenseId}/modules`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const getModulesInLicense = (licenseId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`modules/license/${licenseId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const addModuleToLicense = ({ moduleId, licenseId }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`modules/${moduleId}/license/${licenseId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const deleteModuleFromLicense = ({ moduleId, licenseId }) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`modules/${moduleId}/license/${licenseId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const updateModule = (moduleId, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`modules/${moduleId}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

const executeCustomModuleCall = (project, module, customFunction, body) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/project/${project}/modules/${module}/${customFunction}`, body)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export {
  getModuleData,
  addModuleToProject,
  removeModuleFromProject,
  getModulesByLicense,
  getModulesInLicense,
  addModuleToLicense,
  deleteModuleFromLicense,
  updateModule,
  executeCustomModuleCall,
};
