import {
  createWorkflowProject,
  getProjectWorkflowPermissions,
  getProjectWorkflows,
  getWorkflowsInLicense,
} from '@/services/api/project-workflow.api';
import {
  createSession,
  deleteSession,
  getSessionFlow,
  getSessions,
  getWorkflowSessions,
  getSBSSessions,
} from '@/services/api/project-workflow-sessions.api';
import WorkflowHelper from '../../services/workflow-helper';
import { assignJob, getExecutableNodeByJob } from '@/services/api/jobs.api';
import { getWorkflows } from '@/services/api/workflows.api';
import { getColumns } from '@/services/api/column.api';
import { getRecordsMax } from '@/services/api/record.api';
import { waitForVuex } from '@/utils/vuex';

const state = {
  projectWorkflows: [],
  projectWorkflowsStatus: '',

  licenseWorkflows: [],

  selectedProjectWorkflow: undefined,

  projectWorkflowPermissions: {},

  sessions: [],
  sessionsStatus: '',

  selectedSession: undefined,

  sessionFlow: [],
  sessionFlowStatus: '',

  selectedNode: undefined,
  node: undefined,
  nodeConfig: undefined,
  nodeStatus: '',
};
const mutations = {
  project_workflows_request(state) {
    state.projectWorkflowsStatus = 'loading';
  },
  project_workflows_success(state, [workflows, permissions]) {
    state.projectWorkflows = workflows;
    state.projectWorkflowPermissions = permissions;
    state.projectWorkflowsStatus = 'success';
  },

  project_workflow_create_success(state, workflow) {
    state.projectWorkflows.push(workflow);
  },

  project_workflows_sessions_request(state, projectWorkflowId) {
    state.sessionsStatus = 'loading';
    state.selectedProjectWorkflow = state.projectWorkflows.find(
      (item) => item.id === projectWorkflowId
    );
  },
  project_workflows_sessions_success(state, data) {
    state.sessions = data;
    state.sessionsStatus = 'success';
  },

  session_create_success(state, session) {
    state.sessions.push(session);
  },

  session_delete_success(state, deletedSessionId) {
    state.sessions = state.sessions.filter(
      (session) => session.id !== deletedSessionId
    );
  },

  session_flow_request(state) {
    state.sessionFlowStatus = 'loading';
  },

  session_flow_success(state, flow) {
    state.sessionFlow = WorkflowHelper.calculateWorkflowFlow(flow);
    state.sessionFlowStatus = 'success';
  },

  session_flow_job_update_success(state, job) {
    state.sessionFlow.forEach((node) => {
      if (node.job.id === job.id) {
        Object.assign(node.job, job);
      }
    });
  },
  job_node_request(state) {
    state.nodeStatus = 'loading';
  },
  job_node_success(state, node) {
    state.node = node;
    try {
      state.nodeConfig = JSON.parse(atob(node.block.config.file));
    } catch (e) {
      this.commit('showNotification', {
        content:
          'Workflow Block Configuration incorrect format, please contact your project admin',
        color: 'error',
      });
    }
    state.nodeStatus = 'success';
  },

  license_workflows_success(state, workflows) {
    state.licenseWorkflows = workflows;
  },
};
const actions = {
  fetchWorkflowsInLicense({ commit }, { projectId }) {
    return getWorkflowsInLicense(projectId)
      .then((workflows) => {
        commit('license_workflows_success', workflows);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchProjectWorkflows({ commit }, { projectId }) {
    commit('project_workflows_request');
    return Promise.all([
      getProjectWorkflows(projectId).catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }),
      getProjectWorkflowPermissions(projectId).catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      }),
    ]).then((responses) => {
      commit('project_workflows_success', responses);
    });
  },
  fetchProjectWorkflowSessions({ commit }, { projectId, projectWorkflowId }) {
    commit('project_workflows_sessions_request', projectWorkflowId);
    return getWorkflowSessions(projectId, projectWorkflowId)
      .then((sessions) => {
        commit('project_workflows_sessions_success', sessions);
        return sessions;
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },

  fetchSessionFLow({ commit }, { projectId, sessionId }) {
    commit('session_flow_request');
    return getSessionFlow(projectId, sessionId)
      .then((flow) => {
        commit('session_flow_success', flow);
        return flow;
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  fetchNodeConfig({ commit }, { projectId, jobId }) {
    commit('job_node_request');
    return new Promise((resolve, reject) => {
      getExecutableNodeByJob(projectId, jobId)
        .then((node) => {
          commit('job_node_success', node);
          resolve(node);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  createWorkflowSession({ commit }, { projectId, body }) {
    return createSession(projectId, body)
      .then((session) => {
        commit('session_create_success', session);
        return session;
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  deleteWorkflowSession({ commit }, { projectId, sessionId }) {
    return deleteSession(projectId, sessionId)
      .then((response) => {
        commit('session_delete_success', sessionId);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  addWorkflowToProject({ commit }, { projectId, body }) {
    return createWorkflowProject(projectId, body)
      .then((projectWorkflow) => {
        commit('project_workflow_create_success', projectWorkflow);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  setProjectWorkflow({ state }, workflowId) {
    state.selectedProjectWorkflow = state.projectWorkflows.find(
      (item) => item.id === workflowId
    );
  },
  setSession({ state }, sessionId) {
    state.selectedSession = state.sessions.find(
      (item) => item.id === sessionId
    );
    state.selectedNode = undefined;
  },
  setNode({ state }, node) {
    state.selectedNode = node;
  },
  resetProjectWorkflow({ state }) {
    state.selectedProjectWorkflow = undefined;
  },
  resetSession({ state }) {
    state.selectedSession = undefined;
  },
  resetNode({ state }) {
    state.selectedNode = undefined;
  },
  assignUserToJob({ commit }, { projectId, jobId, body }) {
    return assignJob(projectId, jobId, body)
      .then((job) => {
        commit('session_flow_job_update_success', job);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  projectWorkflows: (state) => state.projectWorkflows,
  projectWorkflowPermissions: (state) => state.projectWorkflowPermissions,
  projectWorkflowsStatus: (state) => state.projectWorkflowsStatus,
  selectedProjectWorkflow: (state) => state.selectedProjectWorkflow,

  sessions: (state) => state.sessions,
  sessionsStatus: (state) => state.sessionsStatus,
  selectedSession: (state) => state.selectedSession,

  sessionFlow: (state) => state.sessionFlow,
  sessionFlowStatus: (state) => state.sessionFlowStatus,

  licenseWorkflows: (state) => state.licenseWorkflows,

  selectedNode: (state) => state.selectedNode,
  node: (state) => state.node,
  nodeStatus: (state) => state.nodeStatus,
  nodeConfig: (state) => state.nodeConfig,
  isExecutableNode: (state) => 'panels' in state.nodeConfig,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
