import { DHME_PROJECT_INTAKE } from '@/modules/modules';

export default [
  {
    path: `module/${DHME_PROJECT_INTAKE}`,
    name: 'dhme-project-intake-overview',
    component: () =>
      import(
        /* webpackChunkName: "dhme-project-intake-home" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakeHome.vue'
      ),
  },
  {
    path: `module/${DHME_PROJECT_INTAKE}/:projectId`,
    name: DHME_PROJECT_INTAKE,
    component: () =>
      import(
        /* webpackChunkName: "dhme-project-intake" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntake.vue'
      ),
    children: [
      {
        name: 'dhme-project-intake-info',
        path: 'info',
        component: () =>
          import(
            /* webpackChunkName: "dhme-project-intake-info" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakeInfo.vue'
          ),
      },
      {
        name: 'dhme-project-intake-client',
        path: 'client',
        component: () =>
          import(
            /* webpackChunkName: "dhme-project-intake-client" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakeClient.vue'
          ),
      },
      {
        name: 'dhme-project-intake-layout',
        path: 'layout',
        component: () =>
          import(
            /* webpackChunkName: "dhme-project-intake-layout" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakeLayout.vue'
          ),
      },
      {
        name: 'dhme-project-intake-planning',
        path: 'planning',
        component: () =>
          import(
            /* webpackChunkName: "dhme-project-intake-planning" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakePlanning.vue'
          ),
      },
      {
        name: 'dhme-project-intake-risks',
        path: 'risks',
        component: () =>
          import(
            /* webpackChunkName: "dhme-project-intake-risks" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakeRisks.vue'
          ),
      },
      {
        name: 'dhme-project-intake-roles',
        path: 'roles',
        component: () =>
          import(
            /* webpackChunkName: "dhme-project-intake-roles" */ '../modules/daiwa-house-modular-europe/ProjectIntake/DhmeProjectIntakeRoles.vue'
          ),
      },
    ],
  },
];
