import httpClient from '../httpClient';

export function getUserDownloads() {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/downloads`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function downloadFile(fileId) {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/downloads/${fileId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
