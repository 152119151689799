import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import './filters/filters';
import VueMarkdown from '@adapttive/vue-markdown';
import VueQrcodeReader from 'vue-qrcode-reader';
import VueTour from 'vue-tour';
import i18n from '@/lang/i18n';
import VueQrcode from '@chenfengyuan/vue-qrcode';

import VueApexCharts from 'vue-apexcharts';

import './registerServiceWorker';
Vue.use(VueApexCharts);

// Vue portals
// import PortalVue from 'portal-vue'
// Vue.use(PortalVue)

Vue.component('Apexchart', VueApexCharts);

Vue.component('QrCode', VueQrcode);
// Vue wait
import VueWait from 'vue-wait';
Vue.use(VueWait);
const wait = new VueWait({
  useVuex: true,
});

//VueTour
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

//VueCasl
import { abilitiesPlugin } from '@casl/vue';
import { ability } from './services/casl';
Vue.use(abilitiesPlugin, ability, { useGlobalProperties: true });

Vue.config.productionTip = false;

Vue.component('VueMarkdown', VueMarkdown);

Vue.use(VueQrcodeReader);

//Vue meta
import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import VueCountdown from '@chenfengyuan/vue-countdown';

Vue.component(VueCountdown.name, VueCountdown);

router.beforeEach((to, from, next) => {
  if (store.getters.authenticatedUser === undefined) {
    store.dispatch('fetchAuthenticatedUser').then(next());
  } else {
    next();
  }
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  wait,
  render: (h) => h(App),
}).$mount('#app');
