import { httpClientV2 } from '@/services/httpClient';
import { unwrapV2 } from '@/services/api/v2/utils';

export const createRecordV2 = (table, record) =>
  unwrapV2((axios) =>
    axios.post(`/tables/${table.id ?? table}/records`, { record })
  );

export const deleteRecordV2 = (table, ...recordIds) =>
  unwrapV2((axios) =>
    axios.delete(`tables/${table.id ?? table}/records`, {
      data: { records: recordIds },
    })
  );

export const updateRecordV2 = (tableId, recordId, data) =>
  unwrapV2((axios) =>
    axios.put(`/tables/${tableId}/records/${recordId}`, {
      record: data,
    })
  );

export const importRecordsV2 = (tableId, data) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .post(`/tables/${tableId}/records/import`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};

export const downloadDocumentV2 = (tableId, recordId, documentId) => {
  return new Promise((resolve, reject) => {
    httpClientV2
      .get(`/tables/${tableId}/records/${recordId}/documents/${documentId}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error.response.data);
      });
  });
};
