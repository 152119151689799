import { getRecordDocument } from '@/services/api/record.api';

const FileHandlerService = {
  handleFile(file) {
    if (typeof file === 'object') {
      const selectedFile = file;
      let apiObject = Object.assign(
        {},
        {
          name: String,
          extension: String,
          data: String,
          mimetype: String, // Add field for mimeType
        }
      );
      apiObject.name = this.getFileName(selectedFile.name);
      apiObject.extension = this.getFileExtension(selectedFile.name);
      apiObject.mimeType = selectedFile.type; // Set the mimeType
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = (e) => {
          const re = /(?:base64,(.*))?$/;
          apiObject.data = re.exec(e.target.result)[1];
          apiObject.file = re.exec(e.target.result)[1];
          resolve(apiObject);
        };
      });
    }
  },
  getFileExtension(fileName) {
    const re = /(?:\.([^.]+))?$/;

    return re.exec(fileName)[1];
  },
  getFileName(fileName) {
    const extension = fileName.substring(fileName.lastIndexOf('.'));
    return fileName.replace(extension, '');
  },

  convertBase64ToBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  },

  getFileNameAndExtensionForTableCellValue(document) {
    if (document !== undefined && document !== null) {
      return `${document.name}.${document.extension}`;
    }
    return '';
  },

  fetchRecordDocument(documentId, projectId, tableId) {
    return getRecordDocument(documentId, projectId, tableId).then(
      (response) => {
        return this.convertBase64ToBlob(response.file, response.mimetype);
      }
    );
  },

  downloadRecordDocument(documentId, projectId, tableId) {
    getRecordDocument(documentId, projectId, tableId).then((response) => {
      const element = document.createElement('a');
      element.setAttribute(
        'href',
        `data:${response.mimetype};base64,${response.file}`
      );
      element.setAttribute(
        'download',
        `${response.name}.${response.extension}`
      );

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    });
  },

  downloadCSV(filename, base64) {
    const element = document.createElement('a');
    element.setAttribute('href', `data:csv;base64,${base64}`);
    element.setAttribute('download', `${filename}.csv`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  },

  convertBase64CSVtoJSON(base64CSV) {
    const csvString = atob(base64CSV); // Decode base64 to CSV string
    const lines = csvString.split('\n');

    const headers = lines[0].split(',');

    return lines.slice(1).map((line) => {
      const values = line.split(',');
      const obj = {};
      headers.forEach((header, index) => {
        obj[header] = values[index];
      });
      return obj;
    });
  },

  convertJSONtoCSVandDownload(jsonArray, filename) {
    const headers = Object.keys(jsonArray[0]);

    const csv = jsonArray.map((obj) =>
      headers.map((header) => obj[header]).join(',')
    );

    csv.unshift(headers.join(','));

    const csvString = csv.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', `${filename}.csv`);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  },
  convertJSONtoCSV(jsonArray) {
    const headers = Object.keys(jsonArray[0]);

    const csv = jsonArray.map((obj) =>
      headers.map((header) => obj[header]).join(',')
    );

    csv.unshift(headers.join(','));

    return csv.join('\n');
  },

  convertToBase64(csvString) {
    return btoa(csvString);
  },
};

export default FileHandlerService;
