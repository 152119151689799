import httpClient from '../httpClient';

const getColumns = (projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/columns`, {
        params: {
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getColumn = (columnId, projectId, tableId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .get(`/column/${columnId}`, {
        params: {
          columnId: columnId,
          'project[id]': projectId,
          'table[id]': tableId,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const updateColumn = (columnId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .put(`/column/${columnId}`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const deleteColumn = (columnId, data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/column/${columnId}`, { data: data })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createColumn = (data) => {
  return new Promise((resolve, reject) => {
    httpClient
      .post(`/column`, data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export { getColumns, getColumn, updateColumn, deleteColumn, createColumn };
