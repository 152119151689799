import httpClient from "../httpClient";

const getWorkflowNodes = (workflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/builder/workflow/${workflowId}/nodes`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const createWorkflowNode = (workflowId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.post(`/builder/workflow/${workflowId}/node`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const updateWorkflowNode = (workflowId, nodeId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.put(`/builder/workflow/${workflowId}/node/${nodeId}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const deleteWorkflowNode = (workflowId, nodeId) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/builder/workflow/${workflowId}/node/${nodeId}`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const getWorkflowNode = (workflowId, nodeId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/builder/workflow/${workflowId}/node/${nodeId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}


export {
  getWorkflowNodes,
  createWorkflowNode,
  updateWorkflowNode,
  deleteWorkflowNode,
  getWorkflowNode,
}
