import {
  executeCustomModuleCall,
  getModuleData,
} from '@/services/api/module.api';
import { waitForVuex } from '@/utils/vuex';
import { DHME_M_YARD_MANAGEMENT } from '@/modules/modules';

const state = {
  moduleData: {},
  yardManagementTasks: [],
  yardManagementSelectedGroup: [],
  yardManagementSelectedTask: null,
};
const mutations = {
  module_yard_management_success(state, data) {
    state.moduleData = data;
  },
  module_yard_management_tasks_success(state, tasks) {
    state.yardManagementTasks = tasks;
  },
  module_yard_management_task_update_success(state, task) {
    Object.assign(
      state.yardManagementTasks.find((record) => record.id === task.id),
      task
    );
    if (state.yardManagementSelectedTask.id === task.id) {
      Object.assign(state.yardManagementSelectedTask, task);
    }
  },
  module_yard_management_select_group(state, group) {
    state.yardManagementSelectedGroup = group;
  },
  module_yard_management_select_group_task(state, task) {
    state.yardManagementSelectedTask = task;
  },
};
const actions = {
  fetchDhmeYardManagementData: waitForVuex(
    'dhme.yard-management.get',
    ({ commit, getters }, { moduleId, sessionId }) => {
      return new Promise((resolve, reject) => {
        return getModuleData(getters.project.id, moduleId, sessionId)
          .then((response) => {
            commit('module_yard_management_success', response);
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    }
  ),
  fetchDhmeYardTasks: waitForVuex(
    'dhme.yard-management.get',
    ({ commit, getters }, { moduleId }) => {
      return new Promise((resolve, reject) => {
        return executeCustomModuleCall(
          getters.project.id,
          moduleId,
          'fetchOpenPickOrderTasks'
        )
          .then((response) => {
            commit('module_yard_management_tasks_success', response);
            resolve(response);
          })
          .catch((error) => {
            this.commit('showNotification', {
              content: error.message,
              color: 'error',
            });
            reject(error);
          });
      });
    }
  ),
  pickupDhmeYardManagementGroup: async ({ commit, getters }) => {
    try {
      let tasks = await executeCustomModuleCall(
        getters.project.id,
        getters.dhmeYardManagementModuleId,
        'pickupPickOrderTask',
        {
          tasks: getters.dhmeYardManagementSelectedGroup.map((task) => task.id),
        }
      );
      tasks.forEach((task) => {
        commit('module_yard_management_task_update_success', task);
      });
    } catch (error) {
      commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    }
  },
  validateElementDhmeYardManagementTask: (
    { commit, getters },
    scannedResult
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        let task = await executeCustomModuleCall(
          getters.project.id,
          getters.dhmeYardManagementModuleId,
          'validatePickOrderElement',
          {
            task: getters.dhmeYardManagementSelectedTask.id,
            id: scannedResult,
          }
        );
        commit('module_yard_management_task_update_success', task);
        resolve(task);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
        reject(error);
      }
    });
  },
  storeElementInLocation: ({ commit, getters }, storageBoxId) => {
    return new Promise(async (resolve, reject) => {
      try {
        let task = await executeCustomModuleCall(
          getters.project.id,
          getters.dhmeYardManagementModuleId,
          'storeItemInLocation',
          {
            task: getters.dhmeYardManagementSelectedTask.id,
            storage_box: storageBoxId,
          }
        );
        commit('module_yard_management_task_update_success', task);
        resolve(task);
      } catch (error) {
        commit('showNotification', {
          content: error.message,
          color: 'error',
        });
        reject(error);
      }
    });
  },
};
const getters = {
  dhmeYardManagementModuleId: (state, getters) =>
    getters.project.modules.find(
      (module) => module.route === DHME_M_YARD_MANAGEMENT
    ).id,
  dhmeYardManagementAssemblyHalls: (state) =>
    state.moduleData.CFFA_DHME_ASSEMBLY_HALLS.records,
  dhmeYardManagementStorageLocations: (state) =>
    state.moduleData.CFFA_DHME_STORAGE_LOCATIONS.records,
  dhmeYardManagementStorageBoxes: (state) =>
    state.moduleData.CFFA_DHME_STORAGE_BOX.records,
  dhmeYardManagementTasks: (state) => state.yardManagementTasks,
  dhmeYardManagementSelectedGroup: (state) => state.yardManagementSelectedGroup,
  dhmeYardManagementSelectedTask: (state) => state.yardManagementSelectedTask,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
