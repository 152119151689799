<template>
  <div
    class="d-flex align-center mb-1 ant-border-bottom px-2"
    style="height: 40px"
  >
    <div style="font-size: 12px; color: gray; min-width: 30px">
      #{{ task.number }}
    </div>
    <v-divider vertical class="mx-2" inset />
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-icon dense :color="getPriorityColor(task.priority)" v-on="on"
          >{{ getPriorityIcon(task.priority) }}
        </v-icon>
      </template>
      <span>{{ task.priority }}</span>
    </v-tooltip>
    <v-divider vertical class="mx-2" inset />
    <div
      v-if="['workflow', 'rfi'].includes(task?.task_type?.type)"
      class="d-flex align-center"
    >
      <v-tooltip v-if="task?.task_type?.type === 'workflow'" bottom>
        <template #activator="{ on }">
          <v-icon densev-on="on">mdi-source-branch</v-icon>
        </template>
        <span>Workflow</span>
      </v-tooltip>

      <v-tooltip v-else-if="task?.task_type?.type === 'rfi'" bottom>
        <template #activator="{ on }">
          <v-icon dense v-on="on">mdi-message-text</v-icon>
        </template>
        <span>RFI</span>
      </v-tooltip>
      <v-divider vertical class="mx-2" />
    </div>
    <slot name="chips" />
    <div
      v-if="targetBlank"
      class="task-title"
      @click="$emit('openDetailView', task)"
    >
      <v-icon v-if="task.is_private" x-small class="mr-1"> mdi-lock </v-icon>
      <router-link
        :to="{ name: 'tasks-detail', params: { taskId: task.id } }"
        target="_blank"
        class="full-width"
      >
        {{ task.title }}
      </router-link>
    </div>
    <div v-else class="task-title" @click="$emit('openDetailView', task)">
      <v-icon v-if="task.is_private" x-small> mdi-lock </v-icon>
      <router-link
        :to="{ name: 'tasks-detail', params: { taskId: task.id } }"
        class="full-width"
      >
        {{ task.title }}
      </router-link>
    </div>
    <div
      v-if="!isOverdue(task.due)"
      class="font-italic"
      style="font-size: 11px"
    >
      {{ calculateRemainingTime(task.due) }}
    </div>
    <v-tooltip v-else bottom>
      <template #activator="{ on }">
        <v-icon dense color="warning" v-on="on">mdi-exclamation</v-icon>
      </template>
      <span>{{ calculateOverdueTime(task.due) }}</span>
    </v-tooltip>
    <v-divider vertical class="mx-2" inset />
    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-icon v-if="task.status === 'open'" small v-on="on">
          mdi-checkbox-blank-circle-outline
        </v-icon>
        <v-icon v-else-if="task.status === 'canceled'" small v-on="on">
          mdi-alert-rhombus-outline
        </v-icon>
        <v-icon v-else-if="task.status === 'closed'" small v-on="on"
          >mdi-checkbox-marked-circle-outline
        </v-icon>
      </template>
      <span>{{ task.status }}</span>
    </v-tooltip>
    <v-divider v-if="isGisRfi" vertical class="mx-2" inset />
    <v-tooltip v-if="isGisRfi" bottom>
      <template #activator="{ attrs, on }">
        <v-btn
          small
          icon
          :ripple="false"
          v-bind="attrs"
          v-on="on"
          @click="openGisViewer"
        >
          <v-icon> mdi-map </v-icon>
        </v-btn>
      </template>
      <span>Show on gis-viewer</span>
    </v-tooltip>
    <v-simple-checkbox
      v-if="canSelect"
      class="mt-0 ml-2 pt-0"
      color="primary"
      :value="selected"
      :ripple="false"
      hide-details
      @input="onChange"
    />
  </div>
</template>

<script>
import moment from 'moment/moment';
import TaskHelper from '@/services/task-helper';

export default {
  name: 'TaskV2',
  props: {
    task: {
      type: Object,
      required: true,
    },
    targetBlank: {
      type: Boolean,
      default: false,
    },
    canSelect: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isGisRfi() {
      return (
        this.task?.task_type?.type === 'rfi' &&
        (!!this.task.task_type.custom_4 ||
          !!this.task.task_type.rfi_x ||
          this.task.task_type.custom_2 === 'gis-drawing' ||
          this.task?.task_type?.rfi_module === 'gis-drawing')
      );
    },
    isGisDrawing() {
      return this.task?.task_type?.rfi_module === 'gis-drawing';
    },
  },
  methods: {
    calculateRemainingTime(due) {
      let duration = moment.duration(moment(due).diff(moment()));
      if (duration.weeks() > 1) {
        return `${duration.weeks()}w`;
      }
      if (duration.days() > 0) {
        return `${duration.days()}d`;
      }
      if (duration.hours() > 0) {
        return `${duration.hours()}h`;
      }
      if (duration.minutes() > 0) {
        return `${duration.minutes()}m`;
      }
    },
    isOverdue(due) {
      let duration = moment.duration(moment(due).diff(moment()));
      return duration.milliseconds() < 0;
    },
    calculateOverdueTime(due) {
      const ms = moment.duration(moment().diff(moment(due)));

      const hours = ms / (1000 * 60 * 60);
      const absoluteHours = Math.floor(hours);
      const h = absoluteHours > 9 ? absoluteHours : '0' + absoluteHours;

      //Get remainder from hours and convert to minutes
      const minutes = (hours - absoluteHours) * 60;
      const absoluteMinutes = Math.floor(minutes);
      const m = absoluteMinutes > 9 ? absoluteMinutes : '0' + absoluteMinutes;

      //Get remainder from minutes and convert to seconds
      const seconds = (minutes - absoluteMinutes) * 60;
      const absoluteSeconds = Math.floor(seconds);
      const s = absoluteSeconds > 9 ? absoluteSeconds : '0' + absoluteSeconds;

      return `${h}h ${m}m`;
    },
    getPriorityIcon(priority) {
      return TaskHelper.getPriorityIcon(priority);
    },
    getPriorityColor(priority) {
      return TaskHelper.getPriorityColor(priority);
    },
    getStatusColor(status) {
      return TaskHelper.getStatusIconColor(status);
    },
    getStatusIcon(status) {
      return TaskHelper.getStatusIcon(status);
    },
    openTaskDetail(task) {
      this.$router.push({ name: 'tasks-detail', params: { taskId: task.id } });
      this.$emit('openDetailView', task);
    },
    onChange() {
      this.$emit('on-select', this.task);
    },
    openGisViewer() {
      const rfiId = this.task.id;
      if (this.isGisDrawing) {
        return this.$store.dispatch('gisViewer/goTo', { rfiId });
      }

      const x = this.task.task_type.custom_4 || this.task.task_type.rfi_x;
      const y = this.task.task_type.custom_5 || this.task.task_type.rfi_y;
      if (x && y) {
        return this.$store.dispatch('gisViewer/goTo', { x, y });
      }

      if (rfiId) {
        return this.$store.dispatch('gisViewer/goTo', { rfiId });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.task-title {
  transition: 200ms;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex: 1;
  font-size: 14px;

  &:hover {
    color: var(--v-primary-base);
    text-decoration: underline;
  }
}
::v-deep .v-input--selection-controls__input {
  margin-right: 0;
}
</style>
