import { getModuleData } from '@/services/api/module.api';

const state = {
  moduleData: {
    CFFA_FORGE_CLIENT: {
      id: undefined,
      records: [],
    },
    CFFA_FORGE_MODELS: {
      id: undefined,
      records: [],
    },
    CFFA_REL_REQUIREMENTS: {
      id: undefined,
      records: [],
    },
    CFFA_REL_SBS_REQUIREMENTS: {
      id: undefined,
      records: [],
    },
    CFFA_REL_SETTINGS: {
      id: undefined,
      records: [],
    },
  },
  moduleStatus: '',
};
const mutations = {
  module_relatics_request(state) {
    state.moduleStatus = 'loading';
  },
  module_relatics_success(state, data) {
    state.moduleData = data;
    let models = state.moduleData.CFFA_FORGE_MODELS.records;
    if (models.length > 0) {
      models[0].enabled = true;
    }
    state.moduleStatus = 'success';
  },
};
const actions = {
  fetchRelaticsModuleData({ commit }, { projectId, moduleId, sessionId }) {
    commit('module_relatics_request');
    getModuleData(projectId, moduleId, sessionId)
      .then((data) => {
        commit('module_relatics_success', data);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};
const getters = {
  relaticsData: (state) => state.moduleData,
  relaticsStatus: (state) => state.moduleStatus,
  relaticsClient: (state) => state.moduleData.CFFA_FORGE_CLIENT.records[0],
  relaticsModels: (state) => state.moduleData.CFFA_FORGE_MODELS.records,
  relaticsSettings: (state) => state.moduleData.CFFA_REL_SETTINGS.records[0],
  relaticsRequirements: (state) =>
    state.moduleData.CFFA_REL_REQUIREMENTS.records,
  relaticsSbsRequirements: (state) =>
    state.moduleData.CFFA_REL_SBS_REQUIREMENTS.records,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
