export const GIS_VIEWER_WIDGETS = {
  HOME: 'home',
  LAYER_LIST: 'layerList',
  SEARCH: 'search',
  ZOOM: 'zoom',
  BASEMAP_TOGGLE: 'basemapToggle',
  BASEMAP_GALLERY: 'basemapGallery',
  MEASUREMENT: 'measurement',
  SKETCH: 'sketch',
  RFI: 'rfi',
};
