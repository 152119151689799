<template>
  <v-avatar
    v-if="count > 0"
    color="primary"
    :style="{ top: top, right: right }"
    :size="measures.size"
  >
    <span
      class="white--text"
      :style="{ 'font-size': `${measures.fontSize}px !important` }"
    >
      {{ count }}
    </span>
  </v-avatar>
</template>

<script>
export default {
  name: 'NotificationCount',
  props: {
    count: {
      type: Number,
      required: true,
    },
    top: {
      type: String,
      required: true,
    },
    right: {
      type: String,
      required: true,
    },
    measures: {
      type: Object,
      default: () => {
        return {
          size: 20,
          fontSize: 12,
        };
      },
    },
  },
};
</script>

<style scoped lang="scss">
.count-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--v-primary-base);
  font-size: 12px;
  color: white;
  width: 20px;
  height: 20px;
  position: absolute;
}
</style>
