import * as XLSX from "xlsx";


/**
 * @param {string} sheetName
 * @param {{}[]} objects
 * @return {{ download: (bookType?: 'xlsx' | 'csv' | 'txt' | 'html' | 'rtf') => void, convert: (bookType?: 'xlsx' | 'csv' | 'txt' | 'html' | 'rtf', type?: 'base64' | 'binary' | 'buffer' | 'file' | 'array' | 'string') => any}}
 */

export const bookOfObjects = (sheetName, objects) =>
    bookOfArrays(
        sheetName,
        [
            Object.keys(objects[0] ?? {}),
            ...objects.map(Object.values)
        ]
    )

/**
 * @param {string} sheetName
 * @param {[][]} arrays
 * @return {{ download: (bookType?: 'xlsx' | 'csv' | 'txt' | 'html' | 'rtf') => void, convert: (bookType?: 'xlsx' | 'csv' | 'txt' | 'html' | 'rtf', type?: 'base64' | 'binary' | 'buffer' | 'file' | 'array' | 'string') => void}}
 */
export function bookOfArrays(sheetName, arrays) {
    const book = XLSX.utils.book_new()
    const sheet = XLSX.utils.aoa_to_sheet(arrays)

    XLSX.utils.book_append_sheet(book, sheet, sheetName)

    return {
        ...book,
        download: (bookType) => XLSX.writeFile(book, `${sheetName}.${bookType ?? "xlsx"}`),
        convert: (bookType, type) => XLSX.write(book, { bookType, type })
    }
}
