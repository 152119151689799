import { AbilityBuilder, Ability } from '@casl/ability';

export const ability = new Ability();

export const updateAbilities = (store) => {
  const { rules, can } = new AbilityBuilder();
  if (store.getters?.authenticatedUser) {
    const { isProjectAdmin, isSystemAdmin, isUserAdmin, project } =
      store.getters;
    if (isSystemAdmin) {
      can('manage', 'all');
    }
    if (isUserAdmin) {
      can('access', 'admin');
      can('access', 'admin-users');
      can('access', 'project-settings');
      can('access', 'add-user-to-license');
      can('access', 'project-archive');
    }
    if (isProjectAdmin) {
      can('access', 'project-settings');
      can('access', 'project-settings-tables');
      can('access', 'project-settings-modules');

      can('access', 'project-links');
      can('create', 'project-links');
      can('update', 'project-links');

      can('create', 'project-table');
      can('update', 'project-table');
      can('delete', 'project-table');
      can('access', 'project-table-settings');
      can('create', 'project-table-records');
      can('delete', 'project-table-records');

      can('create', 'project-table-revisions');
      can('read', 'project-table-revisions');
      can('delete', 'project-table-revisions');

      can('access', 'sbs-overview');
      can('create', 'sbs-records');
      can('update', 'sbs-records');
      can('delete', 'sbs-records');
      can('import', 'sbs-records');

      can('create', 'project-workflow');
      can('update', 'project-workflow');
      can('delete', 'project-workflow');
      can('start', 'project-workflow-session');
    }
    if (project?.generalPermissions) {
      const permissions = project.generalPermissions;
      if (permissions.sbs === true) can('access', 'sbs-management');
    }
  }
  ability.update(rules);
};

export const CaslVuex = (store) =>
  store.watch(
    () => [
      store.getters.authenticatedUser,
      store.getters.isProjectAdmin,
      store.getters.project,
    ],
    () => updateAbilities(store),
    { immediate: true }
  );
