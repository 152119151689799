<template>
  <v-dialog
    v-model="dialog"
    width="unset"
    max-width="90%"
    @click:outside="closeDialog"
    @keydown.esc="closeDialog"
  >
    <v-card>
      <v-card-title class="justify-center text-uppercase headline">
        {{ title }}
      </v-card-title>
      <v-card-text v-if="description">
        {{ description }}
      </v-card-text>
      <v-card-actions class="ant-dialog-actions-bg ant-border-top">
        <v-spacer />
        <v-btn
          color="error"
          text
          small
          :disabled="loading"
          @click="closeDialog"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          elevation="0"
          small
          :loading="loading"
          @click="deleteAction"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DeleteDialog',
  props: {
    dialog: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: undefined,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    deleteAction() {
      this.$emit('deleteAction');
    },
  },
};
</script>

<style scoped></style>
