import {createWorkflow, deleteWorkflow, getWorkflows} from "@/services/api/workflows.api";

const state = {
    userWorkflows: [],
    userWorkflowsStatus: ''
}
const mutations = {
    user_workflows_request(state) {
        state.userWorkflowsStatus = 'loading'
    },
    user_workflows_success(state, workflows) {
        state.userWorkflows = workflows
        state.userWorkflowsStatus = 'success'
    },
    user_workflows_create_success(state, workflow) {
        workflow.collections = [];
        state.userWorkflows.push(workflow)
        state.userWorkflowsStatus = 'success'
    },
    user_workflows_delete_success(state, deletedWorkflow) {
        state.userWorkflows = [...state.userWorkflows.filter(workflow => workflow.id !== deletedWorkflow)]
        state.userWorkflowsStatus = 'success'
    }
}
const actions = {
    fetchUserWorkflows({commit}) {
        commit("user_workflows_request")
        return getWorkflows().then(workflows => {
            commit("user_workflows_success", workflows)
        }).catch(error => {
            this.commit('showNotification', {content: error.message, color: 'error'})
        })
    },
    createWorkflow({commit}, workflow) {
        console.log("test")
        commit("user_workflows_request")
        return createWorkflow(workflow)
            .then(workflow => {
                commit("user_workflows_create_success", workflow)
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },
    deleteWorkflow({commit}, {workflowId}) {
        commit("user_workflows_request")
        return deleteWorkflow(workflowId)
            .then(response => {
                commit('user_workflows_delete_success', workflowId)
            })
            .catch(error => {
                this.commit('showNotification', {content: error.message, color: 'error'})
            })
    },
}
const getters = {
    userWorkflows: state => state.userWorkflows,
    userWorkflowsStatus: state => state.userWorkflowsStatus
}

export default {
    state,
    mutations,
    actions,
    getters
}
