import httpClient from "../httpClient";

const getProjectWorkflowRoles = (projectId, projectWorkflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/project/${projectId}/workflow/${projectWorkflowId}/roles`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const createWorkflowProjectRole = (projectId, projectWorkflowId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.post(`/project/${projectId}/workflow/${projectWorkflowId}/role`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const updateProjectWorkflowRoleName = (projectId, projectWorkflowId, roleId, body) => {
  return new Promise((resolve, reject) => {
    httpClient.put(`/project/${projectId}/workflow/${projectWorkflowId}/role/${roleId}`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const deleteProjectWorkflowRole = (projectId, projectWorkflowId, roleId) => {
  return new Promise((resolve, reject) => {
    httpClient
      .delete(`/project/${projectId}/workflow/${projectWorkflowId}/role/${roleId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const getTeamsWithRoles = (projectId, projectWorkflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/project/${projectId}/workflow/${projectWorkflowId}/roles/teams`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const addRoleToTeam = (projectId, projectWorkflowId, roleId, team) => {
  let body = {
    group: team.id
  }

  return new Promise((resolve, reject) => {
    httpClient.post(`/project/${projectId}/workflow/${projectWorkflowId}/role/${roleId}/team`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const removeRoleFromTeam = (projectId, projectWorkflowId, roleId, teamId) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/project/${projectId}/workflow/${projectWorkflowId}/role/${roleId}/team/${teamId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const getNodesWithRoles = (projectId, projectWorkflowId) => {
  return new Promise((resolve, reject) => {
    httpClient.get(`/project/${projectId}/workflow/${projectWorkflowId}/roles/nodes`, {})
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const addRoleToNode = (projectId, projectWorkflowId, roleId, node) => {
  let body = {
    node: node.id
  }

  return new Promise((resolve, reject) => {
    httpClient.post(`/project/${projectId}/workflow/${projectWorkflowId}/role/${roleId}/node`, body)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

const removeRoleFromNode = (projectId, projectWorkflowId, roleId, nodeId) => {
  return new Promise((resolve, reject) => {
    httpClient.delete(`/project/${projectId}/workflow/${projectWorkflowId}/role/${roleId}/node/${nodeId}`)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject(error.response.data)
      })
  })
}

export {
  getProjectWorkflowRoles,
  createWorkflowProjectRole,
  updateProjectWorkflowRoleName,
  deleteProjectWorkflowRole,
  getTeamsWithRoles,
  addRoleToTeam,
  removeRoleFromTeam,
  getNodesWithRoles,
  addRoleToNode,
  removeRoleFromNode
}
