<template>
  <div ref="notificationMenu" class="pos-rel">
    <div class="pos-rel">
      <div v-if="hasNewNotifications" class="message-indicator" />
      <v-tooltip top>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" @click="onMenuClick" v-on="on">
            <v-icon :class="{ ring: messageReceived }">
              mdi-bell-outline
            </v-icon>
          </v-btn>
        </template>
        <span>Notifications</span>
      </v-tooltip>
    </div>
    <div
      :class="{ 'active-notification-menu': isShown }"
      class="notification-menu-container"
      @mouseleave="isShown = false"
    >
      <div class="content">
        <header>
          <span class="menu-title">Notifications</span>
        </header>
        <div ref="list" class="menu-list">
          <template v-if="notifications.length">
            <notification-item
              v-for="notification in notifications"
              :key="notification.date"
              :data="notification"
            />
          </template>
          <div v-else class="d-flex justify-center align-center">
            <span class="text-h5 mr-2">{{ emptySmile }}</span>
            <span>Nothing special yet</span>
          </div>
        </div>
        <footer v-if="notifications.length">
          <a href="#" @click="clearNotifications">Clear</a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NotificationItem from '@/components/Notifications/NotificationItem.vue';
import LocalStorageService from '@/services/local-storage';
import { LaravelEchoClient } from '@/services/laravel-echo';

export default {
  name: 'Notifications',
  components: { NotificationItem },
  data: () => {
    return {
      isShown: false,
      messageReceived: false,
    };
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
    ...mapGetters({ notifications: 'notifications/notifications' }),
    hasNewNotifications() {
      return this.notifications.some((n) => n.read_at === null);
    },
    emptySmile() {
      const smiles = ['🥳', '🤭', '☺️', '😀', '😋'];
      return smiles[Math.ceil(Math.random() * smiles.length) - 1];
    },
  },
  watch: {
    authenticatedUser: {
      immediate: true,
      handler(user) {
        if (user.id) {
          this.subscribe();
        }
      },
    },
    notifications(v) {
      if (v) {
        this.messageReceived = true;
        setTimeout(() => {
          this.messageReceived = false;
        }, 2000);
        this.$nextTick(() => {
          const node = this.$refs['list'];
          node.scrollTo({ top: -node.scrollHeight, behavior: 'smooth' });
        });
      }
    },
  },
  created() {
    if (!!LocalStorageService.getNotifications()) {
      let n = JSON.parse(LocalStorageService.getNotifications());
      if (Array.isArray(n)) {
        n = n.slice(-10);
        this.$store.dispatch('notifications/setNotifications', n);
      }
    }
  },
  methods: {
    onMenuClick() {
      this.isShown = !this.isShown;
      if (this.isShown) {
        this.$emit('on-shown');
      }
    },
    clearNotifications() {
      this.$store.dispatch('notifications/clearNotifications');
    },
    subscribe() {
      if (!LaravelEchoClient) return;
      LaravelEchoClient.private('App.User.' + this.authenticatedUser.id)
        .stopListening(
          '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
        )
        .notification((notification) => {
          this.$store.dispatch(
            'notifications/pushBroadcastNotification',
            notification
          );
        });
      LaravelEchoClient.private('App.Global')
        .stopListening(
          '.Illuminate\\Notifications\\Events\\BroadcastNotificationCreated'
        )
        .notification((notification) => {
          switch (notification.type) {
            case 'App\\Notifications\\WhatsNewsUpdated': {
              this.$store.dispatch('fetchNewsUpdates');
              break;
            }
            default:
              console.log('Unknown notification type', notification);
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.message-indicator {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 8px;
  width: 8px;
  display: flex;
  border-radius: 100%;
  z-index: 10;
  background-color: var(--v-primary-base);
}

.ring {
  animation: ring 0.8s 0.4s ease-in-out;
  transform-origin: 50% 4px;

  @keyframes ring {
    0% {
      transform: rotate(0);
    }
    20% {
      transform: rotate(30deg);
    }
    40% {
      transform: rotate(-30deg);
    }
    60%,
    100% {
      transform: rotate(0);
    }
  }
}

.notification-menu-container {
  z-index: 9;
  opacity: 0;
  transform: translateY(-60%) translateX(35%) scale(0);
  transition: all 400ms ease;
  position: absolute;
  right: 0;
  transform-origin: 60%;

  &.active-notification-menu {
    opacity: 1;
    transform: translateY(calc(-100% - 62px)) translateX(0) scale(1);
    box-shadow: rgba(17, 12, 46, 0.15) 0 48px 100px 0;
  }

  .content {
    padding: 8px 4px;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 5px;

    .menu-title {
      display: inline-block;
      color: #000;
      font-size: 1.2rem;
      font-weight: 400;
      padding: 9px 18px 6px 12px;
    }

    .menu-list {
      display: flex;
      flex-direction: column-reverse;
      padding: 8px 0;
      max-height: 462px;
      overflow-y: auto;
    }

    footer {
      padding: 9px 18px 6px 12px;
    }
  }
}
</style>
