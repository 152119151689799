import { getModuleData } from '@/services/api/module.api';
import AutodeskService from '@/services/forge/autodesk';
import {
  fetchHubProjects,
  fetchHubs,
  fetchTopFolders,
} from '@/services/forge/autodesk-construction-cloud';
import { bookOfObjects } from '@/services/bookOf';
import {
  createRecord,
  getRecords,
  getRecordsMax,
  importRecords,
  updateRecord,
} from '@/services/api/record.api';
import { getSbsRecords, importSbsBatch } from '@/services/api/sbs.api';

const state = {
  moduleStatus: '',
  moduleData: {
    CFFA_DHME_ELEMENTS: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_MODULES: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_MODEL_MAPPING: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_OBJECTS: {
      id: undefined,
      records: [],
    },
    CFFA_DHME_NON_MODULES: {
      id: undefined,
      records: [],
    },
    CFFA_FORGE_CLIENT: {
      id: undefined,
      records: [],
    },
    CFFA_FORGE_MODELS: {
      id: undefined,
      records: [],
    },
  },

  autodeskToken: '',
  modelImportModelObjectsStatus: '',
  modelImportModelObjects: [],

  accHubs: [],
  accHubProjects: [],
  accProjectTopFolders: [],

  modelSbsTreeStatus: '',
};

const mutations = {
  module_model_import_request(state) {
    state.moduleStatus = 'loading';
  },
  module_model_import_success(state, data) {
    state.moduleData = data;
    state.moduleStatus = 'success';
  },
  set_autodesk_authentication_token(state, token) {
    state.autodeskToken = token;
  },
  module_model_import_objects_request(state) {
    state.modelImportModelObjectsStatus = 'loading';
  },
  module_model_import_objects_success(state, properties) {
    state.modelImportModelObjects = properties;
    state.modelImportModelObjectsStatus = 'success';
  },
  module_model_import_acc_hub_projects_success(state, projects) {
    state.accHubProjects = projects;
  },
  module_model_import_add_model_success(state, record) {
    state.moduleData.CFFA_FORGE_MODELS.records.push(record);
  },
  module_model_import_mapping_create_success(state, record) {
    state.moduleData.CFFA_DHME_MODEL_MAPPING.records.push(record);
  },
  module_model_import_mapping_update_success(state, updatedRecord) {
    Object.assign(
      state.moduleData.CFFA_DHME_MODEL_MAPPING.records.find(
        (record) => record.id === updatedRecord.id
      ),
      updatedRecord
    );
  },
  module_model_import_fetch_tree_request(state) {
    state.modelSbsTreeStatus = 'loading';
  },
  module_model_import_fetch_tree_success(state, { modules, elements }) {
    state.moduleData.CFFA_DHME_MODULES.records = modules;
    state.moduleData.CFFA_DHME_ELEMENTS.records = elements;
    state.modelSbsTreeStatus = 'success';
  },
};

const actions = {
  loadModelImportData({ commit }, { projectId, moduleId, sessionId }) {
    return new Promise((resolve, reject) => {
      commit('module_model_import_request');
      getModuleData(projectId, moduleId, sessionId)
        .then((data) => {
          commit('module_model_import_success', data);
          resolve(data);
        })
        .catch((error) => {
          this.commit('showNotification', {
            content: error.message,
            color: 'error',
          });
        });
    });
  },
  async fetchModuleImportModelModelProperties({ commit, getters }, model) {
    commit('module_model_import_objects_request');
    const objects = await AutodeskService.getModelProperties(
      model.urn,
      model.from_acc
        ? getters.accAccessToken
        : getters.modelImportAutodeskAccessToken,
      getters.modelImportModuleClient.server_region
    );
    commit(
      'module_model_import_objects_success',
      objects.filter((obj) =>
        Object.keys(obj.properties).some((k) =>
          k.startsWith(model.property_directory)
        )
      )
    );
  },
  async fetchAccHubProjects({ commit, getters }) {
    const projects = await fetchHubProjects(
      getters.accAccessToken,
      getters.modelImportModuleClient.acc_hub
    );
    commit('module_model_import_acc_hub_projects_success', projects);
  },
  async addModelToProject({ commit, getters, dispatch }, data) {
    let record = await createRecord(data);
    commit('module_model_import_add_model_success', record);
  },
  async fetchModuleElementsObjectsTree({ commit, getters }) {
    commit('module_model_import_fetch_tree_request');
    const modules = await getRecordsMax(
      getters.project.id,
      getters.modelImportModuleModulesTable.id
    );
    const elements = await getRecordsMax(
      getters.project.id,
      getters.modelImportModuleElementsTable.id
    );
    commit('module_model_import_fetch_tree_success', { modules, elements });
  },

  async modelImportImportSbsTree({ getters }, records) {
    const book = bookOfObjects('data', records);
    const recordsCsv = book.convert('csv', 'string');
    const data = {
      records: btoa(recordsCsv),
    };
    return await importSbsBatch(getters.project.id, data).catch((error) => {
      this.commit('showNotification', {
        content: error.message,
        color: 'error',
      });
    });
  },

  async modelImportModuleImportRecords(
    { commit },
    { projectId, tableId, records }
  ) {
    const book = bookOfObjects('data', records);
    const recordsCsv = book.convert('csv', 'string');
    const data = {
      project: {
        id: projectId,
      },
      table: {
        id: tableId,
      },
      records: btoa(recordsCsv),
    };
    return await importRecords(data);
  },
  modelImportCreateMapping({ commit }, { body }) {
    return createRecord(body)
      .then((record) => {
        commit('module_model_import_mapping_create_success', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
  modelImportUpdateMapping({ commit }, { recordId, body }) {
    return updateRecord(recordId, body)
      .then((record) => {
        commit('module_model_import_mapping_update_success', record);
      })
      .catch((error) => {
        this.commit('showNotification', {
          content: error.message,
          color: 'error',
        });
      });
  },
};

const getters = {
  modelImportModuleData: (state) => state.moduleData,
  modelImportModuleStatus: (state) => state.moduleStatus,
  modelImportModuleModels: (state) =>
    state.moduleData.CFFA_FORGE_MODELS.records,
  modelImportModuleModelsTable: (state) => state.moduleData.CFFA_FORGE_MODELS,
  modelImportModuleClient: (state) =>
    state.moduleData.CFFA_FORGE_CLIENT.records[0],
  modelImportModuleModelMapping: (state) =>
    state.moduleData.CFFA_DHME_MODEL_MAPPING.records[0],
  modelImportModuleModelMappingTable: (state) =>
    state.moduleData.CFFA_DHME_MODEL_MAPPING,
  modelImportModuleModulesTable: (state) => state.moduleData.CFFA_DHME_MODULES,
  modelImportModuleNonModulesTable: (state) =>
    state.moduleData.CFFA_DHME_NON_MODULES,
  modelImportModuleElementsTable: (state) =>
    state.moduleData.CFFA_DHME_ELEMENTS,
  modelImportModuleObjectsTable: (state) => state.moduleData.CFFA_DHME_OBJECTS,
  modelImportAutodeskAccessToken: (state) => state.autodeskToken,
  modelImportModelObjects: (state) => state.modelImportModelObjects,
  modelImportModelObjectsStatus: (state) => state.modelImportModelObjectsStatus,
  modelImportModelAccHubProjects: (state) => state.accHubProjects,
  modelImportModelAccProjectTopFolders: (state) => state.accProjectTopFolders,
  modelImportSbsTreeStatus: (state) => state.modelSbsTreeStatus,
};

export default {
  state,
  mutations,
  actions,
  getters,
};

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
