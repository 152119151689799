import CookieService from '@/services/cookie';

export default {
  data() {
    return {
      viewportHeight: window.innerHeight,
      isPortrait: false,
    };
  },
  computed: {
    viewportStyle() {
      return {
        height: `${
          (this.hasNavPinned
            ? this.viewportHeight - (this.hasNavPinned ? 45 : 0)
            : this.viewportHeight) - (this.isPortrait ? 42 : 0)
        }px`,
      };
    },
    viewportPixelHeight() {
      return {
        height:
          (this.hasNavPinned
            ? this.viewportHeight - (this.hasNavPinned ? 45 : 0)
            : this.viewportHeight) - (this.isPortrait ? 42 : 0),
      };
    },
    hasNavPinned() {
      return CookieService.getNavPin();
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('resize', this.checkOrientation);
  },
  created() {
    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
  },
  methods: {
    handleResize() {
      this.viewportHeight = window.innerHeight;
    },
    checkOrientation() {
      this.isPortrait = window.matchMedia('(orientation: portrait)').matches;
    },
  },
};
